<div class="bg-card-gray h-screen flex items-center justify-center px-4 sm:px-0">
  <div class="bg-white shadow-lg rounded-lg lg:min-w-200">
    <div class="p-6 w-full flex flex-col gap-16">
      <img src="assets/image/gapp-img/logo_business_center.svg" class="w-40" />
      <div class="flex flex-col items-center justify-center gap-8">
        <img class="w-24" src="assets/image/icon-email.svg" />

        <p class="text-green-island-500 text-lg sm:text-2xl h-6">
          ระบบได้ส่งลิงก์ยืนยันตัวตนไปยังอีเมล
        </p>
        <p class="text-xs sm:text-base">โปรดตรวจสอบอีเมลของคุณเพื่อดำเนินการยืนยันตัวตน</p>
      </div>

      <p class="">ขอบคุณที่ร่วมงานกับเรา</p>
    </div>
    <div class="h-2 rounded-b-lg bg-green-island-500"></div>
  </div>
</div>
