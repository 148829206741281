<!-- <div *ngIf="companyList$ | async as company"> -->
<!-- <div style="background-color: #f4f8ff" class="h-screen px-4 sm:px-6 lg:px-8">
  <div class="">
    <img class="h-44 min-w-44 max-w-44" src="../../../../assets/image/gapp-img/gapp-logo.svg" />
  </div>
  <div class="max-w-md w-full space-y-8 flex justify-center">
    <div>

    </div>
    <div class="p-5 xl:p-10 xl:mx-10 flex justify-center" style="padding-left: 285%;">
      <div>
        <p class="mt-10 text-center text-3xl font-extrabold text-gray-700">เลือกบริษัท</p>

      </div>

      <ng-container *ngIf="companyList; else noCompany">
        <div class=" flex flex-col ">
          <div class="flex justify-center items-center gap-8 my-3 ">
            <div class="" *ngFor="let compData of companyList">
              <div [ngClass]="{
                  'ring-green-island-500 opacity-100':
                    select?.companyCode == compData.companyCode,
                  'opacity-40': dirty
                }" class="
                  w-52
                  h-52
                  bg-white
                  rounded-md
                  cursor-pointer
                  shadow-xl
                  ring-4 ring-transparent ring-offset-0
                ">
                <app-path-image [s3Key]="compData.companyLogoUrl"
                  class="h-full w-full rounded-md object-contain cursor-pointer">
                </app-path-image>
              </div>
              <p [ngClass]="
                  select && select.companyCode == compData.companyCode
                    ? 'text-green-island-500'
                    : 'text-gray-400'
                " class="
                  text-center
                  my-3
                  font-extrabold
                  tracking-wide
                  capitalize
                  text-gray-400 text-lg
                ">
                {{ compData.companyName }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noCompany>
        <div class="h-48">
          <div class="flex justify-center items-center gap-8">
            <div class="w-80 flex items-center justify-center">
              <img src="assets/images/animation_loading.gif" class="h-48" />
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div> -->
<!-- </div> -->
<div class="bg-card-gray w-full min-h-screen">
  <img src="../../../../assets/image/gapp-img/gapp-bc-logo.svg" class="w-44 h-44" />
  <div class="flex flex-col justify-center">
    <div class="w-52 h-52 bg-white rounded-md cursor-pointer shadow-xl ring-4 ring-transparent ring-offset-0">
      <!-- company-list -->
    </div>
  </div>
</div>
