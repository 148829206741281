import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AuthService } from './../../../core/services/auth.service';
import { LogComponent } from 'src/app/shared/utils/log.decorator';
import { OpenBusinessService } from '../../services/open-business.service';
import { Router } from '@angular/router';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@LogComponent()
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styles: [],
})
export class ConfirmComponent implements OnInit {
  showError: boolean = false;
  loading: boolean = false;
  hide: boolean = true;
  hide2: boolean = true;
  showForm: boolean = true;
  showIntro: boolean = false;

  faExclamationCircle = faExclamationCircle;

  @Input() control: FormControl = new FormControl('');
  loginForm!: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private openBusinessService: OpenBusinessService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-])+\.(com|org|net|co.th)/)]],
      password: ['', [Validators.required]],
      code: '',
    });
  }

  login(submittedForm: FormGroup) {
    this.loading = true;
    console.warn('Parameter function login :', submittedForm);

    this.openBusinessService.setUserAttributesEmail(
      this.loginForm.get('email')?.value
    );

    // Verify
    this.authService
      .logIn({
        email: submittedForm.value.email,
        password: submittedForm.value.password,
        code: '000000',
      })
      .subscribe(
        (a) => {
          console.warn('Login data :', a);
          // Create business
          this.openBusinessService
            .createBusiness({
              businessCategoryId: '',
              businessSize: 'none',
              businessType: [],
              businessWebsite: '',
              interest: [],
            })
            .subscribe(
              (a) => console.warn('Return data to create business :', a),
              (err) => {
                this.loading = false;
              },
              () => {
                this.loading = false;
                this.router.navigateByUrl('/welcome');
              }
            );
        },

        (err) => {
          console.warn('Login error :', err);
          this.showError = true;
        }
      );
  }

  showpass() {
    let txtpwd: any = document.getElementById('inputpassword');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide = false;
    } else {
      txtpwd.type = 'password';
      this.hide = true;
    }
  }

  showpass2() {
    let txtpwd: any = document.getElementById('inputpassword2');

    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide2 = false;
    } else {
      txtpwd.type = 'password';
      this.hide2 = true;
    }
  }

  openform() {
    this.showIntro = false;
    this.showForm = true;
  }
}
