<div
  class="bg-card-gray h-screen flex items-center justify-center px-4 md:px-0"
>
  <div class="bg-white shadow-lg rounded-lg w-full md:w-auto md:min-w-200">
    <div class="p-6">
      <ng-container *ngIf="showIntro == true">
        <div>
          <img
            class="w-56 md:w-64"
            src="assets/image/gapp-img/logo_business_center.svg"
            alt=""
          />
        </div>

        <div class="space-y-5">
          <img
            class="mt-6 w-16 lg:w-20 mx-auto mb-5"
            src="assets/image/icon-correct.svg"
            alt=""
          />
        </div>
        <div class="space-y-5">
          <p
            class="text-center font-normal text-lg md:text-xl xl:text-2xl"
            style="color: #006db7"
          >
            อีเมลของคุณได้รับการยืนยันแล้ว
            กรุณาล็อกอินเข้าสู่ระบบผ่านปุ่มด้านล่างเพื่อเปิดใช้งานบัญชี
          </p>
          <div class="flex justify-center items-center">
            <button
              (click)="openform()"
              class="bg-blue-btnr text-white px-7 py-2 rounded text-base md:text-lg border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-second-color-500"
            >
              เข้าสู่ระบบ
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showForm == true">
        <div class="flex items-center justify-center">
          <img
            class="w-60 xl:w-64"
            src="assets/image/gapp-img/logo_business_center.svg"
            alt=""
          />
        </div>

        <p class="text-2xl text-center text-gray-500 mt-10">
          ยืนยันตัวตนด้วยการล็อกอินเข้าระบบ
        </p>

        <div class="mx-auto max-w-md w-full space-y-8">
          <form
            (ngSubmit)="login(loginForm)"
            [formGroup]="loginForm"
            class=""
            novalidate
          >
            <ng-container *ngIf="showError == true">
              <div class="mt-4 text-base flex items-center text-red-400">
                <fa-icon class="mr-2" [icon]="faExclamationCircle"></fa-icon>
                ไม่มีบัญชีนี้ในระบบโปรดตรวจสอบ อีเมล รหัสผ่าน หรือ รหัสธุรกิจ
              </div>
            </ng-container>

            <div class="py-4 relative m-0 h-28">
              <label class="text-base"
                >อีเมล</label
              >
              <input
                type="text"
                placeholder="E-mail"
                autocomplete="email"
                class="border border-gray-300 rounded-md block w-full h-10 text-base placeholder-gray-400 placeholder-opacity-60 inputBorderFocus"
                formControlName="email"
                autofocus
                [ngClass]="{
                  'border-red-400 border-1': loginForm.get('email')?.hasError('pattern')
                }"
              />
              <div class="">
                <p
                  class="text-xs sm:text-sm text-red-400"
                  *ngIf="loginForm.get('email')?.hasError('pattern')"
                >
                  กรุณากรอกอีเมลให้ถูกต้อง
                </p>
              </div>
            </div>
            <div class="py-2 relative m-0">
              <div class="relative -mt-2">
                <label class="text-base"
                  >รหัสผ่าน</label
                >
                <div
                  class="flex flex-wrap items-stretch w-full relative focus:ring-btn-second-color focus:border-btn-second-color focus:ring-1"
                >
                  <input
                    id="inputpassword"
                    type="password"
                    placeholder="Password"
                    formControlName="password"
                    autocomplete="new-password"
                    class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none text-base h-10 inputBorderFocus rounded-md border border-gray-300 px-3 relative placeholder-gray-400 placeholder-opacity-60"
                    [ngClass]="{
                      'border-1':
                        (loginForm.get('password')!.touched ||
                          loginForm.get('password')!.dirty) &&
                        !loginForm.get('password')!.valid
                    }"
                  />

                  <div class="flex -mr-px">
                    <span
                      class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-txt-title text-lg"
                    >
                      <img
                        *ngIf="hide == false"
                        (click)="showpass()"
                        class="cursor-pointer opacity-50 right-3 w-5 h-5"
                        src="../../../../assets/image/hide.svg"
                        alt=""
                      />
                      <img
                        *ngIf="hide == true"
                        (click)="showpass()"
                        class="cursor-pointer opacity-50 top-0 right-3 w-5 h-5"
                        src="../../../../assets/image/view.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              [disabled]="!loginForm.valid"
              class="btn-discustom mt-5 group relative w-full flex justify-center p-3 text-lg lg:text-xl 2xl:text-xl transition border border-transparent focus:outline-none duration-500 ease-in font-normal rounded-md from-gray-btndis to-gray-btndis bg-gray-100 cursor-pointer"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <ng-container *ngIf="loading === true">
                  <svg
                    class="animate-spin h-5 w-5 fill-current"
                    style="color: #f5f9fc"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 458.186 458.186"
                    style="enable-background: new 0 0 458.186 458.186"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M445.651,201.95c-1.485-9.308-10.235-15.649-19.543-14.164c-9.308,1.485-15.649,10.235-14.164,19.543
                                           c0.016,0.102,0.033,0.203,0.051,0.304c17.38,102.311-51.47,199.339-153.781,216.719c-102.311,17.38-199.339-51.47-216.719-153.781
                                           S92.966,71.232,195.276,53.852c62.919-10.688,126.962,11.29,170.059,58.361l-75.605,25.19
                                           c-8.944,2.976-13.781,12.638-10.806,21.582c0.001,0.002,0.002,0.005,0.003,0.007c2.976,8.944,12.638,13.781,21.582,10.806
                                           c0.003-0.001,0.005-0.002,0.007-0.002l102.4-34.133c6.972-2.322,11.675-8.847,11.674-16.196v-102.4
                                           C414.59,7.641,406.949,0,397.523,0s-17.067,7.641-17.067,17.067v62.344C292.564-4.185,153.545-0.702,69.949,87.19
                                           s-80.114,226.911,7.779,310.508s226.911,80.114,310.508-7.779C435.905,339.799,457.179,270.152,445.651,201.95z"
                        />
                      </g>
                    </g>
                  </svg>
                </ng-container>
              </span>
              ลงชื่อเข้าใช้งาน
            </button>

            <!-- NOTE ยังไม่ใช้เอาออกก่อน -->
            <!-- <div class="flex items-center justify-end">
                <div class="text-sm text-right pt-3">
                  <a href="#" routerLink="/forgot-password" class="underline font-normal mr-auto text-lg"
                    style="color: #006db7">
                    ลืมรหัสผ่าน?
                  </a>
                </div>
              </div> -->
          </form>
        </div>
      </ng-container>
    </div>
    <div class="h-2 rounded-b-lg" style="background-color: #006db7"></div>
  </div>
</div>
