export interface IEnvironment {
  production: boolean;
  name: string;
  amplifyEnvName: EAmplifyEnvName
  timeStamp: string;
  s3PublicBucketName: string;
  urlGappBizPrefix: EUrlGappBizPrefix
  datadogRumApplicationId?: string;
  datadogRumClientToken?: string;
  datadogRumService?: string;
  datadogLogService?: string;
}

export const S3PublicBucketPrefix = 'gapp-pub-blob';
export enum EAmplifyEnvName {
  dev = 'beta',
  staging = 'stg',
  prod = 'prod',
  next = 'next'
}
export enum EUrlGappBizPrefix {
  dev = 'beta',
  staging = 'app',
  prod = 'app',
  next = 'next'
}

/**
 * Datadog
 */
// RUM Application ID
export const ENV_DATADOG_RUM_APPLICATION_ID_BETA = 'a997580c-7185-46fe-8a42-6b1f177800f9';
export const ENV_DATADOG_RUM_APPLICATION_ID_PROD = 'ac2b9f1d-2329-4f40-9400-ce76f242b5dc';
// RUM Client Token
export const ENV_DATADOG_RUM_CLIENT_TOKEN_BETA = 'pubbd82fef413a87c6008dbdf60cf10d039';
export const ENV_DATADOG_RUM_CLIENT_TOKEN_PROD = 'pubfc03917c08f7ba6de6d87020b2736358';
// RUM Service
export const ENV_DATADOG_RUM_SERVICE_BETA = 'rum-gapp-business-center-beta';
export const ENV_DATADOG_RUM_SERVICE_PROD = 'rum-gapp-business-center-prod';
// LOG Service
export const ENV_DATADOG_LOG_SERVICE_BETA = 'log-gapp-business-center-beta';
export const ENV_DATADOG_LOG_SERVICE_PROD = 'log-gapp-business-center-prod';
