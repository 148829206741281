import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styles: [],
})
export class LoginFooterComponent implements OnInit {
  buildTime: string | undefined
  envName = environment.name.toUpperCase()

  constructor() {}

  ngOnInit(): void {
    this.buildTime = environment.timeStamp
  }
}
