import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {FormControl} from '@angular/forms'
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs'
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators'
// @ts-ignore
import JSON_PROVINCE from '../../json/province.json'
// @ts-ignore
import JSON_LOCATION from '../../json/location.json'
// @ts-ignore
import JSON_LOCATION_DETAIL from '../../json/location_detail.json'

interface Location {
  location_id: string | number
  location_name: string
  postcode: string | number
}

@Injectable()
export class Service {
  private readonly requestFilteredProvince$ = new Subject<string>()
  private readonly requestFilteredAmphoe$ = new Subject<string>()
  private readonly requestFilteredTambon$ = new Subject<string>()
  private readonly _amphoe$ = new BehaviorSubject<any>([])
  private readonly _tambon$ = new BehaviorSubject<any>([])

  responseFilteredProvince$ = this.requestFilteredProvince$.pipe(
    startWith(''),
    distinctUntilChanged(),
    switchMap((query) =>
      of(JSON_PROVINCE).pipe(
        delay(Math.random() * 1000 + 500),
        map((provices) => provices.map((province: any) => province.location_name)),
        map((provincesName) =>
          query
            ? provincesName.filter(
                (name: string) => !name.toLowerCase().indexOf(query.toLowerCase())
              )
            : provincesName
        )
      )
    ),
    map((res) => res.map((val: any) => ({ name: val }))),
    tap((res) => console.log({ res })),
    takeUntil(this.destroy$),
    shareReplay(1)
  )

  amphoe$ = this._amphoe$
    .asObservable()
    .pipe(map((amphoe) => amphoe.map((amphoe: any) => amphoe.location_name)))
  queryAmphoe$ = this.requestFilteredAmphoe$.pipe(distinctUntilChanged(), startWith(''))
  responseFilteredAmphoe$ = combineLatest([this.amphoe$, this.queryAmphoe$]).pipe(
    delay(Math.random() * 1000 + 500),
    map(([amphoesName, query]) =>
      query
        ? amphoesName.filter((name: string) => !name.toLowerCase().indexOf(query.toLowerCase()))
        : amphoesName
    ),
    map((res) => res.map((val: any) => ({ name: val }))),
    shareReplay(1)
  )

  tambon$ = this._tambon$
    .asObservable()
    .pipe(map((tambon) => tambon.map((tambon: any) => tambon.location_name)))
  queryTambon$ = this.requestFilteredTambon$.pipe(
    distinctUntilChanged(),
    startWith(''),
    takeUntil(this.destroy$)
  )
  responseFilteredTambon$ = combineLatest([this.tambon$, this.queryTambon$]).pipe(
    delay(Math.random() * 1000 + 500),
    map(([tambonsName, query]) =>
      query
        ? tambonsName.filter((name: string) => !name.toLowerCase().indexOf(query.toLowerCase()))
        : tambonsName
    ),
    map((res) => res.map((val: any) => ({ name: val }))),
    shareReplay(1)
  )

  // @ts-ignore
  getLocation$ = (locationId: number): Observable<Location[]> =>
    of(JSON_LOCATION).pipe(
      map((locations) => locations.filter((p: any) => locationId === p.parent_id)),
      map((locations) =>
        locations.map((loc: any) => {
          return ({
            location_id: loc.location_id,
            location_name: JSON_LOCATION_DETAIL.find(
              (detail: { location_id: any }) => loc.location_id === detail.location_id
            )['name'],
            postcode: loc.postcode,
          });
        })
      )
    )

  constructor(private readonly destroy$: Observable<void>) {}

  filteredProvince(query: string | null): void {
    this.requestFilteredProvince$.next(query || '')
  }
  filteredAmphoe(query: string | null): void {
    this.requestFilteredAmphoe$.next(query || '')
  }
  filteredTambon(query: string | null): void {
    this.requestFilteredTambon$.next(query || '')
  }

  selectedProvince(province: string): void {
    const locationId$ = of(JSON_PROVINCE.find((p: { location_name: string }) => province === p.location_name)).pipe(
      pluck('location_id'),
      map((id) => Number(id))
    )

    locationId$
      .pipe(take(1), switchMap(this.getLocation$))
      .subscribe((amphoe) => this._amphoe$.next(amphoe))
  }

  selectedAmphoe(amphoe: string): void {
    const locationId$ = this._amphoe$.asObservable().pipe(
      filter((res) => !!res),
      map((a) => a.find((p: { location_name: string }) => amphoe === p.location_name)),
      pluck('location_id'),
      map((id) => Number(id))
    )

    locationId$
      .pipe(switchMap(this.getLocation$))
      .subscribe((tambon) => this._tambon$.next(tambon))
  }

  selectedTambon(tambon: string): Observable<string> {
    const tambon$ = this._tambon$.asObservable()

    return tambon$.pipe(
      filter((res) => !!res),
      map((p) => p.find((t: { location_name: string }) => tambon === t.location_name)),
      pluck('postcode')
    )
  }
}

@Component({
  selector: 'app-c-select-data-list',
  templateUrl: './c-select-data-list.component.html',
  providers: [Service],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CSelectDataListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() provinceControl = new FormControl()
  @Input() amphoeControl = new FormControl()
  @Input() tambonControl = new FormControl()
  @Input() postcodeControl = new FormControl()
  @Input() classInput: string = ''
  @Input() classGrid: string = 'grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4'
  @Input() required: boolean = false
  @Input() dataReset: boolean = false

  provinceShow: FormControl = new FormControl()
  amphoeShow: FormControl = new FormControl()
  tambonShow: FormControl = new FormControl()

  private _onDestroy = new Subject<void>()

  constructor(@Inject(Service) readonly service: Service) {}

  ngOnInit(): void {
    this.onSendDataToSearch()
  }

  // NOTE For Dialog Add More Address check province
  ngOnChanges(changes: SimpleChanges): void {
    console.log('---- dataReset -----', this.dataReset)
    if (changes['dataReset']) {
      if (!this.dataReset) {
        this.provinceShow.setValue(null)
        console.warn('log set null !!', !this.dataReset)
      } else {
        console.log('chk log provinceControl1 .............', this.provinceControl.value)
        // this.onSendDataToSearch()
      }
    }
  }

  onSendDataToSearch(): void {
    console.warn('log data comming....', this.provinceControl.value)

    this.provinceControl.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(this.provinceControl.value))

      .subscribe((res) => {
        // this.amphoeControl.setValue('')
        this.service.selectedProvince(res)

        console.log('this.provinceControl.value', res)

        const objval = {
          name: res,
        }
        if (objval.name) {
          this.provinceShow.setValue(objval)
        } else {
          this.amphoeShow.setValue(null)
          this.tambonShow.setValue(null)
          this.amphoeControl.setValue(null)
          this.tambonControl.setValue(null)
        }
      })

    this.amphoeControl.valueChanges
      .pipe(takeUntil(this._onDestroy), startWith(this.amphoeControl.value))
      .subscribe((res) => {
        // this.tambonControl.setValue('')
        this.service.selectedAmphoe(res)

        console.log('this.amphoeControl.value', res)

        const objval = {
          name: res,
        }
        if (objval.name) {
          this.amphoeShow.setValue(objval)
        } else {
          this.tambonShow.setValue(null)
          this.tambonControl.setValue(null)
        }
      })

    this.tambonControl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        startWith(this.tambonControl.value),
        switchMap((res) => this.service.selectedTambon(res))
      )
      .subscribe((res) => {
        if (res) {
          this.postcodeControl.setValue(res)
        }

        console.log('this.tambonControl.value', res)
        // console.log('this.postcodeControl.value', res)
        // else this.postcodeControl.setValue('')

        const objval = {
          name: this.tambonControl.value,
        }
        if (objval.name) {
          this.tambonShow.setValue(objval)
        }
      })
  }

  patchData(control: string | number, value: { name: any }) {
    // @ts-ignore
    const ctr = this[control] as FormControl
    console.warn('----- value -----', value)
    ctr.patchValue(value?.name)
  }

  ngOnDestroy(): void {
    this._onDestroy.next()
    this._onDestroy.complete()
  }
}
