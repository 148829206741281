<!-- <ui-header></ui-header>
<ui-subheader></ui-subheader> -->
<!-- <main>
  <div class=" mx-auto ">
    //NOTE Replace with your content
    <div>


      <router-outlet></router-outlet>
    </div>
    //NOTE End replace
  </div>
</main> -->
<main>
  <ui-header></ui-header>
</main>
