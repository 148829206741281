import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styles: [],
})
export class FormTemplateComponent implements OnInit {
  @Input() textName: string | undefined
  constructor() {}

  ngOnInit(): void {}
}
