import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'

import { AuthService } from './../../../core/services/auth.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styles: [],
})
export class FirstLoginComponent implements OnInit {
  showError: boolean = false
  loading: boolean = false
  hide: boolean = true

  @Input() control: FormControl = new FormControl('')
  loginForm!: FormGroup
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      code: ['', [Validators.required]],
    })
  }

  login(submittedForm: FormGroup) {
    this.loading = true
    console.log(submittedForm)
    this.authService
      .logIn({
        email: submittedForm.value.email,
        password: submittedForm.value.password,
        code: submittedForm.value.code,
      })
      .subscribe(
        (a) => console.log(a),
        (err) => {
          console.log(err)
          ;(this.showError = true), (this.loading = false)
        },
        () => {
          this.router.navigateByUrl('/open-business-account'), (this.loading = false)
        }
      )

    //this.router.navigateByUrl('/open-business-account')
  }

  showpass() {
    let txtpwd: any = document.getElementById('inputpassword')

    if (txtpwd.type === 'password') {
      txtpwd.type = 'text'
      this.hide = false
    } else {
      txtpwd.type = 'password'
      this.hide = true
    }
  }
}
