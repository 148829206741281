import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// Import components
import {LayoutComponent} from "./layout/layout.component";
import {HeaderComponent} from "./header/header.component";
import {SubheaderComponent} from "./header/subheader.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../shared/shared.module";


@NgModule({
    declarations: [
        HeaderComponent,
        LayoutComponent,
        SubheaderComponent,
        PageNotFoundComponent,
        SidebarComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        PageNotFoundComponent
    ]
})
export class BlocksModule {
}
