import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { faTrash, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanyService } from 'src/app/core/services/company.service';
@Component({
  selector: 'app-dialog-confirm-delete-company',
  templateUrl: './dialog-confirm-delete-company.component.html',
  styles: [],
})
export class DialogConfirmDeleteCompanyComponent implements OnInit {
  companydata: any;
  pinForm = new FormGroup({});
  companyForm!: FormGroup;

  // *
  showValidatePassword: boolean = false;
  isValidForm: boolean = false;
  loading: boolean = false;

  // *
  faUserTie = faUserTie;
  faUsers = faUsers;
  faTrash = faTrash;
  BucketName: string = '' // FIXME

  constructor(
    protected ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private fb: FormBuilder,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.companydata = this.config.data.item;
    console.log('log company data =>', this.companydata);

    this.companyForm = this.fb.group({
      companyName: ['', [Validators.required]],
      pinNumber: ['', [Validators.required, Validators.maxLength(6)]],
    });
    console.warn('log company form =>', this.companyForm.value);
  }

  companyNameValid() {
    const companyControl = this.companyForm.get('companyName');
    const companyConfirmControl = this.companydata.companyName;
    console.log('companyConfirmControl', companyConfirmControl);
    let isReturn: boolean = false;
    if (companyControl?.value === companyConfirmControl) {
      isReturn = true;
    } else if (companyControl?.value !== companyConfirmControl) {
      isReturn = false;
    }
    console.log('log isReturn=>', isReturn);
    console.log('this.createForm touched', this.companyForm.touched);
    console.log('this.companyForm valid', this.companyForm.valid);
  }

  submit() {
    this.companyService
      .deleteCompany(this.companydata.businessId, this.companydata.companyCode)
      .subscribe((res) => console.log('log res =>', res));
  }

  btnCancel() {
    this.ref.close(false);
  }
}
