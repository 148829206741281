import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StaffSubHeadService {
  private StaffTitle = new BehaviorSubject<string>('')
  private StaffCompanyName = new BehaviorSubject<string>('')
  private StaffTitlelLevelOne = new BehaviorSubject<string>('')
  private StaffTitlelLevelTwo = new BehaviorSubject<string>('')

  sendStaffTitle(data: string) {
    this.StaffTitle.next(data)
  }
  getStaffTitle(): Observable<string> {
    return this.StaffTitle.asObservable()
  }

  sendStaffCompanyName(data: string) {
    this.StaffCompanyName.next(data)
  }
  getStaffCompanyName(): Observable<string> {
    return this.StaffCompanyName.asObservable()
  }

  senderStaffTitlelLevOne(data: string) {
    this.StaffTitlelLevelOne.next(data)
  }

  getStaffTitlelLevOne(): Observable<string> {
    return this.StaffTitlelLevelOne.asObservable()
  }

  senderStaffTitlelLevTwo(data: string) {
    this.StaffTitlelLevelTwo.next(data)
  }

  getStaffTitlelLevtwo(): Observable<string> {
    return this.StaffTitlelLevelTwo.asObservable()
  }

  // sendLinkOne(data: string) {
  //   this.Link1.next(data)
  // }

  // getLinkOne(): Observable<string> {
  //   return this.Link1.asObservable()
  // }

  constructor() {}
}
