import {Component, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {Router} from '@angular/router'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {RxwebValidators} from '@rxweb/reactive-form-validators'
import {DialogService} from 'primeng/dynamicdialog'
import {DialogSetOtpComponent} from 'src/app/shared/components/dialogs/dialog-set-otp/dialog-set-otp.component'

import {AuthService} from './../../../core/services/auth.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styles: [],
})
export class NewPasswordComponent implements OnInit {
  faEye = faEye
  faEyeSlash = faEyeSlash
  changeType: string = 'password'
  changeTypeConfirm: string = 'password'

  form!: FormGroup

  emailVal: any

  get confirmPassword() {
    return this.form.get('confirmPassword')
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.Builder()

    this.openDialogSetOtp()
  }

  Builder() {
    this.emailVal = this.authService._setEmail$.getValue()
    console.warn('log email =>', this.emailVal)
    this.form = this.formBuilder.group({
      mail: [this.emailVal, Validators.required],
      code: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: [
        '',
        RxwebValidators.compare({
          fieldName: 'password',
        }),
      ],
    })
  }

  submit() {
    console.log('--- valid pang submit form ---', this.emailVal)
    console.log('error', this.confirmPassword?.dirty)
    if (this.form.valid) {
      this.authService
        .forgotPasswordSubmit(
          this.form.get('mail')?.value,
          this.form.get('code')?.value,
          this.form.get('confirmPassword')?.value
        )
        .subscribe(
          () => this.router.navigateByUrl('/login'),
          (error) => {
            console.warn(error)
          }
        )
    }
  }

  //NOTE open/colse dialog
  openDialogSetOtp() {
    console.log('--- log open dialog set otp ---')
    const dialogRef = this.dialogService.open(DialogSetOtpComponent, {})

    dialogRef.onClose.subscribe((res) => {
      console.log('come Back =>', res)
      if (res) {
        this.form.get('code')?.patchValue(res)
      }
    })
  }

  seePass() {
    this.changeType = this.changeType === 'text' ? 'password' : 'text'
  }

  seePassConfirm() {
    this.changeTypeConfirm = this.changeTypeConfirm === 'text' ? 'password' : 'text'
  }

  validatepassCharUpper(control: any) {
    const reg = new RegExp(/^(?=.*[A-Z])[a-zA-Z0-9]+$/g)
    let valuePhoneMobile = this.form.get(control)!.value
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile)
    } else {
      return false
    }
  }

  validatepassChar(control: any) {
    const reg = new RegExp(/^(?=.*[a-z])[a-zA-Z0-9]+$/g)
    let valuePhoneMobile = this.form.get(control)!.value
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile)
    } else {
        return false
    }
  }

  validatepassNumber(control: any) {
    const reg = new RegExp(/^(?=.*[0-9])[a-zA-Z0-9]+$/g)
    let valuePhoneMobile = this.form.get(control)!.value
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile)
    } else {
        return false
    }
  }
}
