import { Injectable } from '@angular/core'
import { GappBusiness } from '@geeesy/type-interfaces/'
import { API } from 'aws-amplify'
import { Observable } from 'rxjs'
import { BehaviorSubject, concat, defer, from } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { logServiceL2 } from '../../shared/utils/log.function'
import { TokenService } from './token.service'

const API_NAME: string = 'gbzApiIam'
const API_PATH: string = '/bc'

interface INewUser {
  username?: string
  identityId?: string
  phone?: string
  email?: string
}
interface IcreateAdmin {
  email: string
  phone: string
  name: string
}

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  tenantId = '' //:string
  token = '' //:string
  bizName = '' //:string

  public newAdminB$ = new BehaviorSubject<INewUser>({})

  constructor(private tokenService: TokenService) {
    logServiceL2('BusinessService')
  }

  /**
   * GET -> Read BUSINESS info
   */
  getBusinessInfo(): Observable<GappBusiness.Business> {
    const getObs = defer(() =>
      from(API.get(API_NAME, `${API_PATH}/business/${this.tenantId}`, {})).pipe(
        map((res) => res.data)
        // tap((res) => console.log('[GET] Business Info', res))
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      getObs
    )
  }

  /**
   * PUT -> UPDATE BUSINESS info
   */
  updateBusinessInfo(
    BusinessInfo: GappBusiness.Business
  ): Observable<GappBusiness.Business> {
    return from(
      API.put(API_NAME, `${API_PATH}/business/${this.tenantId}`, {
        body: {
          ...BusinessInfo,
        },
      })
    ).pipe(
      map((res) => res),
      tap((res) => console.log('updateBusinessInfo', res))
    )
  }

  // updateBusinessInfo(
  //   BusinessInfo: GappBusiness.Business
  // ): Observable<GappBusiness.Business> {
  //   return from(
  //     API.put(API_NAME, `${API_PATH}/business/${this.tenantId}`, {
  //       body: {
  //         ...BusinessInfo,
  //       },
  //     })
  //   ).pipe(
  //     map((res) => res.data),
  //     tap((res) => console.log('updateBusinessInfo', res))
  //   )
  // }

  /**
   * GET -> List all USERs
   */
  getAllUsers() {
    const listAllUsersObs = defer(() =>
      from(API.get(API_NAME, `${API_PATH}/business/users/${this.tenantId}`, {}))
    )
    return concat(
      this.tokenService.tenantId().pipe(
        tap((id) => (this.tenantId = id)),
        tap(() => console.warn(this.tenantId))
      ),
      listAllUsersObs
    )
  }

  /**
   * POST -> Create ADMIN
   */
  createAdmin({ email, phone, name }: IcreateAdmin): Observable<any> {
    // * Signup
    const adminCreateUserObs = defer(() =>
      from(
        API.post('AdminQueries', '/adminCreateUser', {
          body: {
            email: email,
            username: phone, //! FIXME: set email as username
            name: this.bizName,
            given_name: name,
            userrole: 'admin',
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      ).pipe(
        tap((res) => {
          const newAdminUsername = res.data.User.Username
          const attributes = res.data.User.Attributes as []
          const newAtt: any = attributes.find((p: any) => p.Name === 'custom:identityId')
          const phoneAtt: any = attributes.find((p: any) => p.Name === 'phone_number')
          const emailAtt: any = attributes.find((p: any) => p.Name === 'email')
          this.newAdminB$.next({
            username: newAdminUsername,
            identityId: newAtt.Value,
            phone: phoneAtt.Value,
            email: emailAtt.Value,
          })
          console.log('adminCreateUser', res)
        })
      )
    )

    // * ADMIN User -> Tenant Admin
    const addUserToAdminGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: 'tenant0',
            username: this.newAdminB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    // * ADMIN User -> Biz
    const addUserToBizGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: this.tenantId,
            username: this.newAdminB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    // * POST -> DB
    const createBusinessUserAdminObs = defer(() => {
      const userObj: GappBusiness.CreateBusinessUser = {
        roleId: '',
        businessId: this.tenantId,
        businessDept: '',
        email: this.newAdminB$.getValue().email as string,
        fullName: name,
        identityId: this.newAdminB$.getValue().identityId as string,
        mobile: this.newAdminB$.getValue().phone as string,
        penName: name,
        userRole: 'admin',
        username: this.newAdminB$.getValue().username as string,
        businessPosition: '',
        hasArchived: false,
      }
      return from(
        API.post(API_NAME, `${API_PATH}/user/admin`, {
          body: {
            tenantId: this.tenantId,
            username: this.newAdminB$.getValue().username,
            dataIdentity: userObj,
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.bizName = obj.bizName
          this.tenantId = obj.tenantId
        })
      ),
      adminCreateUserObs,
      addUserToAdminGroupObs,
      addUserToBizGroupObs,
      createBusinessUserAdminObs
    ).pipe(tap((res) => console.log('concat', res)))
  }

  /**
   * POST -> Set password on user
   */
  setUserPassword(p: {
    username: string
    password: string
    isPermanent: boolean
  }): Observable<any> {
    console.log('setUserPassword')

    const adminSetUserPasswordObs = defer(() =>
      from(
        API.post('AdminQueries', '/adminSetUserPassword', {
          body: {
            username: p.username,
            password: p.password,
            isPermanent: p.isPermanent,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.bizName = obj.bizName
          this.tenantId = obj.tenantId
        })
      ),
      adminSetUserPasswordObs
    )
  }
}
