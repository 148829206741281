<div class="{{classGrid}}  gap-4">
  <div class="flex flex-col">
    <p class="mb-1 text-gray-400 text-xl">จังหวัด
      <span *ngIf="required" class="text-red-500">*</span>
    </p>
    <p-dropdown
      [formControl]="provinceShow"
      [style]="{ width: '100%'}"
      [options]="service.responseFilteredProvince$ | async"
      optionLabel="name"
      [filter]="true"
      [showClear]="true"
      (onChange)="patchData('provinceControl', $event.value)"
      placeholder="เลือก"
      emptyFilterMessage="ไม่พบผลลัพธ์"
    >
    </p-dropdown>
  </div>
  <div class="flex flex-col">
    <p class="mb-1 text-gray-400 text-xl">เขต/อำเภอ
      <span *ngIf="required" class="text-red-500">*</span>
    </p>
    <p-dropdown
      [formControl]="amphoeShow"
      [style]="{ width: '100%' }"
      [options]="service.responseFilteredAmphoe$ | async"
      optionLabel="name"
      [filter]="true"
      [showClear]="true"
      (onChange)="patchData('amphoeControl', $event.value)"
      placeholder="เลือก"
      emptyFilterMessage="ไม่พบผลลัพธ์"
    >
    </p-dropdown>
  </div>

  <div class="flex flex-col">
    <p class="mb-1 text-gray-400 text-xl">แขวง/ตำบล
      <span *ngIf="required" class="text-red-500">*</span>
    </p>
    <p-dropdown
      [formControl]="tambonShow"
      [style]="{ width: '100%' }"
      styleClass="border border-gray-300 rounded-md w-full h-10 text-xl text-gray-400 placeholder-gray-300r"
      [options]="service.responseFilteredTambon$ | async"
      optionLabel="name"
      [filter]="true"
      [showClear]="true"
      (onChange)="patchData('tambonControl', $event.value)"
      placeholder="เลือก"
      emptyFilterMessage="ไม่พบผลลัพธ์"
    >
    </p-dropdown>
  </div>
  <div class="flex flex-col">
    <p class="mb-1 text-gray-400 text-xl">รหัสไปรษณีย์
      <span *ngIf="required" class="text-red-500">*</span>
    </p>

    <input
      id="postcode"
      name="postcode"
      type="text"
      placeholder="รหัสไปรษณีย์"
      [formControl]="postcodeControl"
      class="inputForm"
    />

  </div>

</div>
