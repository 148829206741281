import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Import components
import {RegisterComponent} from "./components/register/register.component";
import {FirstLoginComponent} from "./components/first-login/first-login.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {NewPasswordComponent} from "./components/new-password/new-password.component";
import {CheckmailComponent} from "./components/checkmail/checkmail.component";
import {ConfirmComponent} from "./components/confirm/confirm.component";
import {OpenBusinessComponent} from "./components/open-business/open-business.component";
import {ChooseCompanyComponent} from "./components/choose-company/choose-company.component";

const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  {
    // ? /register
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'first-login',
    component: FirstLoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'new-password',
    component: NewPasswordComponent,
  },
  {
    path: 'check-mail',
    component: CheckmailComponent,
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
  },
  {
    path: 'open-business-account',
    component: OpenBusinessComponent,
  },
  {
    path: 'choose-company',
    component: ChooseCompanyComponent,
  },
  {
    path: '**',
    redirectTo: '/register',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [],
})
export class AuthRoutingModule {}
