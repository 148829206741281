import { Injectable } from '@angular/core'
import { GappBusiness } from '@geeesy/type-interfaces'
import { TBusinessSize, TBusinessTypes } from '@geeesy/type-interfaces/lib/type-business'
import { API, Auth } from 'aws-amplify'
import { BehaviorSubject, Observable, concat, defer, from } from 'rxjs'
import {catchError, tap} from 'rxjs/operators'

import { UserOwner } from '../../core/models/user.model'
import {TokenService} from "../../core/services/token.service";

const API_NAME: string = 'gbzApiIam'
const API_PATH: string = '/bc'

interface ICreateBusiness {
  businessSize: TBusinessSize
  businessWebsite: string
  businessType: TBusinessTypes[]
  businessCategoryId: string
  interest: any
  // interest: string
}
@Injectable({
  providedIn: 'root',
})
export class OpenBusinessService {
  private userObj = new UserOwner()
  private bizId = '' //: string
  private token = ''

  userAttributesEmail = new BehaviorSubject(undefined)
  userAttributesEmail$ = this.userAttributesEmail.asObservable()

  constructor(
    private tokenService: TokenService,
  ) {}

  getAccessToken() {
    return defer(() =>
      from(Auth.currentSession()).pipe(
        tap((res) => {
          console.warn('get access token', res)
          this.userObj.bizName = res.getIdToken().payload['name']
          this.userObj.email = res.getIdToken().payload['email']
          this.userObj.firstName = res.getIdToken().payload['given_name']
          this.userObj.lastName = res.getIdToken().payload['family_name']
          this.userObj.phone = res.getIdToken().payload['phone_number']
          this.userObj.identityId = res.getIdToken().payload['custom:identityId']
          this.userObj.username = res.getIdToken().payload['cognito:username'] // NOTE: change from username

          const groups = res.getAccessToken().payload['cognito:groups'] as string[]
          this.bizId = groups.filter((group) => group !== 'tenant0')[0].split('#')[0]

          console.warn(this.userObj)
        })
      )
    )
  }

  /**
   * POST -> Create Business Account -> DB (Initialize)
   */

  /*businessCategory,*/
  createBusiness({
    businessSize,
    businessWebsite,
    businessCategoryId,
    businessType,
    interest,
  }: ICreateBusiness): Observable<any> {
    const postObs = defer(() => {
      const businessObj: GappBusiness.CreateBusiness = {
        cognitoUsername: this.userObj.username,
        businessId: this.bizId,
        businessCategoryId: businessCategoryId,
        businessLogoUrl: '',
        businessLogoThumbUrl: '',
        avatarThumbUrl: '',
        businessName: this.userObj.bizName,
        businessSize: businessSize,
        businessTypes: businessType,
        email: this.userObj.email,
        interests: interest,
        ownerName: `${this.userObj.firstName} ${this.userObj.lastName}`,
        phone: this.userObj.phone,
        website: businessWebsite,
        identityId: this.userObj.identityId,
        useAsCompany: false,
      }
      console.log({ businessObj })
      return from(
        API.post(API_NAME, `${API_PATH}/business`, {
          body: {
            ...businessObj,
          },
        }).then((res) => {
          console.warn('--------then-----createBusiness', res)
          return res
        }).catch((err) => {
          console.warn('------error-------createBusiness', err)
          return err
        })
      )
    })

    const updateOwnerCustomAttribute = defer(() => {
      console.warn('--------updateOwnerCustomAttribute-----', this.userObj.username)
      return from(
        API.post('AdminQueries', '/adminUpdateUserAttributes', {
          body: {
            userAttributes: {
              'custom:userrole': 'owner',
            },
            username: this.userObj.username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
        })),
          this.getAccessToken(),
          postObs.pipe(
            tap((r) => {
              console.warn('createBusiness', r)
            })
          ),
          updateOwnerCustomAttribute.pipe(
            catchError((err) => {
                console.warn('updateOwnerCustomAttribute', err)
                return err
              }
            ))
    )

  }

  setUserAttributesEmail(data: any) {
    console.log('setUserAttributesEmail =>', data)
    this.userAttributesEmail.next(data)
  }

  getUserAttributesEmail() {
    return this.userAttributesEmail.getValue()
  }
}
