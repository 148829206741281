import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// Import libraries (NgZorro)
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzUploadModule} from 'ng-zorro-antd/upload';
// Import libraries (FontAwesome)
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// Import libraries (PrimeNG)
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
// Import shared components
import {PathImageComponent} from './utils/path-image/path-image.component';
import {EmptyComponent} from './utils/empty/empty.component';
import {PathIconComponent} from './path-icon/path-icon.component';
import {FormTemplateComponent} from './form-template/form-template.component';
import {ToggleButtonComponent} from './components/toggle-button/toggle-button.component';
import {BtnCheckboxComponent} from './components/btn-checkbox/btn-checkbox.component';
import {LoginFooterComponent} from './components/login-footer/login-footer.component';
import {DialogGappListComponent} from './components/dialog-gapp-list/dialog-gapp-list.component';
import {InputDateChartComponent} from './components/input-date-chart/input-date-chart.component';
import {CSelectDataListComponent} from './components/c-select-data-list/c-select-data-list.component';
import {DialogSetOtpComponent} from './components/dialogs/dialog-set-otp/dialog-set-otp.component';
import {
    DialogDeleteEmployeeComponent
} from './components/dialogs/dialog-delete-employee/dialog-delete-employee.component';
import {
    DialogResetPasswordConfirmComponent
} from './components/dialogs/dialog-reset-password-confirm/dialog-reset-password-confirm.component';
import {
    DialogConfirmDeleteCompanyComponent
} from './components/dialogs/dialog-confirm-delete-company/dialog-confirm-delete-company.component';

const components = [
    PathImageComponent,
    EmptyComponent,
    PathIconComponent,
    FormTemplateComponent,
    ToggleButtonComponent,
    BtnCheckboxComponent,
    LoginFooterComponent,
    DialogGappListComponent,
    InputDateChartComponent,
    CSelectDataListComponent,
    DialogSetOtpComponent,
    DialogDeleteEmployeeComponent,
    DialogResetPasswordConfirmComponent,
    DialogConfirmDeleteCompanyComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        // NgZorro
        NzIconModule,
        NzModalModule,
        NzProgressModule,
        NzUploadModule,
        // FontAwesome
        FontAwesomeModule,
        // PrimeNG
        DialogModule,
        CalendarModule,
        DropdownModule,
        MenuModule,
    ],
    exports: [...components,
        FontAwesomeModule,
        NzIconModule,
        NzModalModule,
        NzProgressModule,
        NzUploadModule,
        DialogModule, CalendarModule,
        DropdownModule,
        MenuModule,
    ],
})
export class SharedModule {
}
