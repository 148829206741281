<div class="bg-card-gray h-screen xl:grid xl:grid-cols-5 flex items-center">
  <!-- รูปปกหลัง -->
  <img
    style="object-fit: cover; object-position: top"
    class="hidden xl:block h-screen z-10 w-full absolute w-vw40"
    src="assets/image/gapp-img/img-register.png"
  />

  <!-- คำอธิบาย Feature ของ App ฝั่งซ้าย -->
  <div
    class="col-start-2 backdrop-blur-2xl hidden xl:block p-6 z-20 rounded-l-2xl h-vh90"
  >
    <!-- logo -->
    <div class="mb-8">
      <img
        src="assets/image/gapp-img/logo_business_center.svg"
        class="w-64 mt-2"
      />
    </div>
    <div class="xl:h-4.5/5 overflow-y-auto">
      <!-- description -->
      <div class="flex flex-col gap-4">
        <div *ngFor="let tex of textlist">
          <div class="flex flex-col gap-2 pr-2" *ngIf="tex.text">
            <div class="flex gap-2 items-end">
              <img
                class="w-10 h-10 rounded-full bg-color-blue-bg"
                src="assets/image/gapp-img/alert.svg"
              />
              <p class="text-2xl text-white">
                {{ tex.header }}
              </p>
            </div>

            <div class="text-xl text-white">
              {{ tex.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- สมัครเข้าใช้งานระบบ GAPP Biz ฝั่งขวา -->
  <div
    class="sm:col-span-2 w-full bg-white p-2 sm:p-6 flex flex-col gap-1 rounded-r-2xl overflow-y-auto relative h-vh90 justify-between"
  >
    <!-- เข้าสู่ระบบ -->
    <div class="flex flex-col xl:flex-row items-center justify-between gap-4">
      <!-- สมัครเข้าใช้งานระบบ GAPP Biz -->
      <p class="text-green-island-500 text-3xl font-bold text-center">
        สมัครเข้าใช้งานระบบ GAPP biz
      </p>
      <a
        class="text-green-island-500 px-2 rounded text-lg font-bold"
        style="background-color: #d8e9f4"
        href="https://app.gappbiz.com/login"
        target="_blank"
      >
        เข้าสู่ระบบ
      </a>
    </div>

    <form class="w-full" novalidate [formGroup]="registerForm">
      <div class="grid md:grid-cols-4 gap-4">
        <!-- ชื่อ -->
        <div class="relative col-span-2">
          <label for="firstname" class="text-base">ชื่อ</label>
          <input
            type="text"
            formControlName="firstname"
            placeholder="ชื่อ"
            id="firstname"
            class="border border-gray-300 rounded-md block w-full h-10 text-base placeholder-gray-400 placeholder-opacity-60 inputBorderFocus"
            [ngClass]="{
              'border-red-400 border-1':
                (registerForm.get('firstname')!.touched ||
                  registerForm.get('firstname')!.dirty) &&
                !registerForm.get('firstname')!.valid
            }"
          />
          <div class="space-y-2">
            <ng-container
              *ngIf="
                registerForm.get('firstname')!.invalid &&
                (registerForm.get('firstname')!.dirty ||
                  registerForm.get('firstname')!.touched)
              "
            >
              <div class="absolute flex">
                <p
                  class="text-xs sm:text-sm text-red-400"
                  *ngIf="registerForm.controls['firstname'].errors?.['required']"
                >
                  กรุณากรอกชื่อของคุณ
                </p>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- นามสกุล -->
        <div class="relative col-span-2">
          <label for="lastName" class="text-base">นามสกุล</label>
          <input
            type="text"
            formControlName="lastname"
            placeholder="นามสกุล"
            id="lastName"
            class="border border-gray-300 rounded-md block w-full h-10 text-base placeholder-gray-400 placeholder-opacity-60 inputBorderFocus"
            [ngClass]="{
              'border-red-400 border-1':
                (registerForm.get('lastname')!.touched ||
                  registerForm.get('lastname')!.dirty) &&
                !registerForm.get('lastname')!.valid
            }"
          />
          <div class="space-y-2">
            <ng-container
              *ngIf="
                registerForm.get('lastname')!.invalid &&
                (registerForm.get('lastname')!.dirty ||
                  registerForm.get('lastname')!.touched)
              "
            >
              <div class="absolute flex">
                <p
                  class="text-xs sm:text-sm text-red-400"
                  *ngIf="registerForm.get('lastname')!.errors?.['required']"
                >
                  กรุณากรอกนามสกุลของคุณ
                </p>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- อีเมล -->
        <div class="md:col-span-3">
          <label for="email" class="text-base">อีเมล</label>

          <input
            (keydown)="validateEmail()"
            type="text"
            placeholder="อีเมล"
            autocomplete="email"
            id="email"
            class="border border-gray-300 rounded-md w-full h-10 text-base placeholder-gray-400 placeholder-opacity-60 inputBorderFocus"
            formControlName="email"
            [ngClass]="{
              'border-red-400 border-1':
                (registerForm.get('email')!.touched ||
                  registerForm.get('email')!.dirty) &&
                !registerForm.get('email')!.valid
            }"
          />
          <!-- Validate Email -->
          <div
            class="text-xs sm:text-sm flex items-center mt-1"
            [ngClass]="
                        registerForm.get('email')!.invalid &&
                        (registerForm.get('email')!.dirty ||
                        registerForm.get('email')!.touched) && 
                        registerForm.get('email')!.value || texterror
                          ? 'text-red-400 '
                          : 'text-green-500' &&
                            registerForm.get('email')!.errors
                              ?.['required']
                          ? ''
                          : 'text-green-500'
                        "
          >
            <img
              *ngIf="
                            registerForm.get('email')!.errors?.['required']
                          "
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/taoo.svg"
            />
            <img
              *ngIf="registerForm.get('email')!.valid && !texterror"
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/cheak_bc.svg"
            />
            <img
              *ngIf="registerForm.get('email')!.invalid && !registerForm.get('email')!.errors?.['required'] || texterror
                          "
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/check_bcc.svg"
            />
            <!-- แจ้งเตือนอีเมลซ้ำ ให้แสดง errMessage แต่ถ้ากรอกผิดให้ขึ้นข้อความกรอกใหม่ -->
            <span class="text-xs sm:text-sm">
              {{
                texterror
                  ? errMessage
                  : "กรอกอีเมลให้ถูกต้อง ระบบจะส่งลิงค์ยืนยันตัวตนผ่านอีเมลที่ระบุไว้"
              }}
            </span>
          </div>
        </div>

        <!-- เบอร์มือถือ -->
        <div class="col-span-1">
          <label for="phone" class="text-base">เบอร์มือถือ</label>
          <input
            (keydown)="validatePhone()"
            id="phone"
            autocomplete="off"
            placeholder="เบอร์มือถือ"
            type="text"
            mask="000-000-0000"
            maxlength="12"
            class="border border-gray-300 rounded-md w-full h-10 text-base placeholder-gray-400 placeholder-opacity-60 inputBorderFocus"
            formControlName="phone"
            [ngClass]="{
              'border-red-400 border-1':
                (registerForm.get('phone')!.touched ||
                  registerForm.get('phone')!.dirty) &&
                !registerForm.get('phone')!.valid
            }"
            (ngModelChange)="modelChangeFn($event)"
          />
          <!-- Validate Phone number -->
          <p
            class="flex items-center mt-1 text-xs sm:text-sm"
            [ngClass]="
                        registerForm.get('phone')!.invalid &&
                        (registerForm.get('phone')!.dirty ||
                        registerForm.get('phone')!.touched) &&
                        registerForm.get('phone')!.value
                          ? 'text-red-400 '
                          : 'text-green-500'
                          &&
                            registerForm.get('phone')!.errors
                              ?.['required']
                          ? ''
                          : 'text-green-500'
                          "
          >
            <img
              *ngIf="
                registerForm.get('phone')!.errors?.['required'] || 
                (registerForm.get('phone')!.errors?.['pattern'] && !registerForm.get('phone')!.value)
              "
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/taoo.svg"
            />
            <img
              *ngIf="registerForm.get('phone')!.valid"
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/cheak_bc.svg"
            />
            <img
              *ngIf="
                registerForm.get('phone')!.invalid && 
                !registerForm.get('phone')!.errors?.['required']
                          "
              class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
              src="assets/image/check_bcc.svg"
            />
            <span class="text-xs sm:text-sm">ใส่เบอร์มือถือที่ถูกต้อง</span>
          </p>
        </div>
      </div>

      <!-- รหัสผ่าน -->
      <div
        class="pt-4"
        formGroupName="passwordGroup"
      >
        <!-- รหัสผ่าน -->
        <div class="">
          <label for="inputpassword" class="text-base">รหัสผ่าน</label>

          <div class="flex flex-wrap items-stretch w-full">
            <input
              (keydown)="validatePassword()"
              id="inputpassword"
              type="password"
              formControlName="password"
              placeholder="รหัสผ่าน"
              autocomplete="new-password"
              class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none text-base h-10 inputBorderFocus rounded-md border border-gray-300 px-3 relative placeholder-gray-400 placeholder-opacity-60"
              [ngClass]="{
                'border-red-400 border-1':
                  registerForm.get('passwordGroup')!.errors ||
                  ((registerForm.get('passwordGroup.password')!.touched ||
                    registerForm.get('passwordGroup.password')!.dirty) &&
                    !registerForm.get('passwordGroup.password')!.valid)
              }"
            />
            <div class="flex">
              <span
                class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-base"
              >
                <img
                  *ngIf="hide == false"
                  (click)="showpass()"
                  class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6"
                  src="../../../../assets/image/hide.svg"
                />
                <img
                  *ngIf="hide == true"
                  (click)="showpass()"
                  class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6"
                  src="../../../../assets/image/view.svg"
                />
              </span>
            </div>
          </div>

          <!-- Validate Password -->
          <div class="flex flex-row mt-1 gap-4">
            <div>
              <p
                class="flex items-center h-5"
                [ngClass]="
                            validatepassChar('passwordGroup.password')
                              ? ' dark:text-red-300'
                              : 'text-green-500' &&
                                registerForm.get('passwordGroup.password')!.errors?.['required']
                              ? '  '
                              : 'text-green-500'
                          "
              >
                <img
                  *ngIf="registerForm.get('passwordGroup.password')!.errors?.['required']"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src="assets/image/taoo.svg"
                />
                <img
                  *ngIf="
                              !validatepassChar('passwordGroup.password') &&
                              !registerForm.get('passwordGroup.password')!.errors?.['required']
                            "
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src="assets/image/cheak_bc.svg"
                />
                <img
                  *ngIf="validatepassChar('passwordGroup.password')"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src="assets/image/check_bcc.svg"
                />
                <span class="text-xs sm:text-sm">
                  กรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์เล็ก
                </span>
              </p>
              <p
                class="flex items-center h-5"
                [ngClass]="
                            validatepassCharUpper('passwordGroup.password')
                              ? 'text-red-400 dark:text-red-300'
                              : 'text-green-500' &&
                                registerForm.get('passwordGroup.password')!.errors?.['required']
                              ? '  '
                              : 'text-green-500'
                          "
              >
                <img
                  *ngIf="registerForm.get('passwordGroup.password')!.errors?.['required']"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/taoo.svg"
                />
                <img
                  *ngIf="
                              !validatepassCharUpper('passwordGroup.password') &&
                              !registerForm.get('passwordGroup.password')!.errors?.['required']
                            "
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/cheak_bc.svg"
                />
                <img
                  *ngIf="validatepassCharUpper('passwordGroup.password')"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/check_bcc.svg"
                />
                <span class="text-xs sm:text-sm">
                  กรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์ใหญ่
                </span>
              </p>
            </div>

            <div>
              <p
                class="flex items-center h-5"
                [ngClass]="
                            validatepassNumber('passwordGroup.password')
                              ? 'text-red-400 dark:text-red-300'
                              : 'text-green-500' &&
                                registerForm.get('passwordGroup.password')!.errors?.['required']
                              ? '  '
                              : 'text-green-500'
                          "
              >
                <img
                  *ngIf="registerForm.get('passwordGroup.password')!.errors?.['required']"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/taoo.svg"
                />
                <img
                  *ngIf="
                              !validatepassNumber('passwordGroup.password') &&
                              !registerForm.get('passwordGroup.password')!.errors?.['required']
                            "
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/cheak_bc.svg"
                />
                <img
                  *ngIf="validatepassNumber('passwordGroup.password')"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/check_bcc.svg"
                />
                <span class="text-xs sm:text-sm">กรอกรหัสผ่านที่มีตัวเลข</span>
              </p>
              <p
                class="flex items-center h-5"
                [ngClass]="
                            registerForm.get('passwordGroup.password')!.errors?.['minlength']
                              ? 'text-red-400 dark:text-red-300'
                              : 'text-green-500' &&
                                registerForm.get('passwordGroup.password')!.errors?.['required']
                              ? '  '
                              : 'text-green-500'
                          "
              >
                <img
                  *ngIf="registerForm.get('passwordGroup.password')!.errors?.['required']"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/taoo.svg"
                />
                <img
                  *ngIf="
                              !registerForm.get('passwordGroup.password')!.errors?.['minlength'] &&
                              !registerForm.get('passwordGroup.password')!.errors?.['required']
                            "
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/cheak_bc.svg"
                />
                <img
                  *ngIf="registerForm.get('passwordGroup.password')!.errors?.['minlength']"
                  class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                  src=" assets/image/check_bcc.svg"
                />
                <span class="text-xs sm:text-sm"
                  >กรอกรหัสผ่านอย่างน้อย 8 ตัว</span
                >
              </p>
            </div>
          </div>
        </div>

        <!-- ยืนยันรหัสผ่าน -->
        <div class="pt-4">
          <label for="inputpassword2" class="text-base">ยืนยันรหัสผ่าน</label>

          <div
            class="flex flex-wrap items-stretch w-full focus:ring-green-island-500 focus:border-green-island-500 focus:ring-1"
          >
            <input
              id="inputpassword2"
              type="password"
              placeholder="ยืนยันรหัสผ่าน"
              formControlName="confirmpassword"
              autocomplete="new-password"
              class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 text-base rounded-r-none h-10 inputBorderFocus rounded-md border border-gray-300 px-3 relative placeholder-gray-400 placeholder-opacity-60"
              [ngClass]="{
                'border-red-400 border-1':
                  registerForm.get('passwordGroup')!.errors ||
                  ((registerForm.get('passwordGroup.confirmpassword')!
                    .touched ||
                    registerForm.get('passwordGroup.confirmpassword')!
                      .dirty) &&
                    !registerForm.get('passwordGroup.confirmpassword')!.valid)
              }"
            />
            <div class="flex">
              <span
                class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-base"
              >
                <img
                  *ngIf="hide2 == false"
                  (click)="showpass2()"
                  class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6"
                  src="assets/image/hide.svg"
                />
                <img
                  *ngIf="hide2 == true"
                  (click)="showpass2()"
                  class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6"
                  src="assets/image/view.svg"
                />
              </span>
            </div>
          </div>

          <!-- Validate Confirm Password -->
          <div>
            <p
              class="flex items-center h-5 mt-1"
              [ngClass]="
                          registerForm.get('passwordGroup')!.errors?.['match'] &&
                          !registerForm.get('passwordGroup.confirmpassword')!.errors?.['required']
                            ? 'text-red-400 dark:text-red-300'
                            : 'text-green-500' &&
                              registerForm.get('passwordGroup.confirmpassword')!.errors
                                ?.['required']
                            ? ' '
                            : 'text-green-500'
                        "
            >
              <img
                *ngIf="
                            registerForm.get('passwordGroup.confirmpassword')!.errors?.['required']
                          "
                class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                src="assets/image/taoo.svg"
              />
              <img
                *ngIf="registerForm.get('passwordGroup')!.valid"
                class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                src="assets/image/cheak_bc.svg"
              />
              <img
                *ngIf="
                            registerForm.get('passwordGroup')!.errors?.['match'] &&
                            !registerForm.get('passwordGroup.confirmpassword')!.errors?.['required']
                          "
                class="w-4 h-4 sm:w-5 sm:h-5 mr-1"
                src="assets/image/check_bcc.svg"
              />
              <span class="text-xs sm:text-sm">ยืนยันรหัสผ่านตรงกัน</span>
            </p>
          </div>
        </div>
      </div>

      <hr class="border my-4" />

      <!-- Policy -->
      <div class="flex flex-col gap-1">
        <div class="flex gap-1 sm:gap-2 items-center">
          <input
            [formControl]="$any(registerForm.get('confirmPolicy'))"
            type="checkbox"
            id="specification"
            class="w-4 h-4 sm:w-5 sm:h-5 rounded-md focus:ring-0 focus:ring-offset-0 focus:outline-none"
          />
          <label for="specification">
            <p class="text-xs sm:text-sm clamp-1">
              ข้าพเจ้าได้อ่านและยอมรับ
              <a
                href="https://gappbiz.com/term-condition/"
                target="_blank"
                class="text-green-island-500 underline"
              >
                ข้อกำหนดและเงื่อนไขการใช้บริการ
              </a>
              <span> และ </span>
              <a
                href="https://gappbiz.com/privacy-policy/"
                target="_blank"
                class="text-green-island-500 underline"
              >
                นโยบายความเป็นส่วนตัว
              </a>
            </p> </label
          ><br />
        </div>
        <!-- <div class="flex gap-1 sm:gap-2 items-center">
          <input
            [formControl]="$any(registerForm.get('confirmSpecification'))"
            type="checkbox"
            id="specification"
            class="w-4 h-4 sm:w-5 sm:h-5 rounded-md"
          />
          <label for="specification">
            <p class="text-xs sm:text-sm clamp-1">
              ข้าพเจ้าได้อ่านและยอมรับ ข้อกำหนดและเงื่อนไขการใช้บริการ
              <a
                href="https://gappbiz.com/term-condition/"
                target="_blank"
                class="text-green-island-500 underline"
              >
                รายละเอียดเพิ่มเติม</a
              >
            </p> </label
          ><br />
        </div>

        <div class="flex gap-1 sm:gap-2 items-center">
          <input
            [formControl]="$any(registerForm.get('confirmPolicy'))"
            type="checkbox"
            id="policy"
            class="w-4 h-4 sm:w-5 sm:h-5 rounded-md"
          />
          <label for="policy">
            <p class="text-xs sm:text-sm clamp-1">
              ข้าพเจ้าได้อ่านและยอมรับ นโยบายความเป็นส่วนตัว
              <a
                href="https://gappbiz.com/privacy-policy/"
                target="_blank"
                class="text-green-island-500 underline"
              >
                รายละเอียดเพิ่มเติม</a
              >
            </p> </label
          ><br />
        </div> -->
        <div class="flex gap-1 sm:gap-2 items-center">
          <input
            [formControl]="
              $any(registerForm.get('confirmGetNewsAndPromptions'))
            "
            type="checkbox"
            id="checkbox3"
            value="checkbox3"
            class="w-4 h-4 sm:w-5 sm:h-5 rounded-md focus:ring-0 focus:ring-offset-0 focus:outline-none"
          />
          <label for="checkbox3">
            <p class="text-xs sm:text-sm">
              ต้องการรับข่าวสารและโปรโมชั่นจาก GAPP biz
            </p>
          </label>
        </div>
      </div>
    </form>
    <!-- สมัครใช้งาน -->
    <button
      (click)="save()"
      class="w-full text-2xl relative"
      type="submit"
      [disabled]="
        !registerForm.valid ||
        showErrorMobileNumber('phone') ||
        validatepassNumber('passwordGroup.password') ||
        validatepassChar('passwordGroup.password') ||
        validatepassCharUpper('passwordGroup.password') ||
        registerForm.get('confirmPolicy')?.value === false ||
        texterror
      "
      [ngClass]="
        !registerForm.valid ||
        showErrorMobileNumber('phone') ||
        validatepassNumber('passwordGroup.password') ||
        validatepassChar('passwordGroup.password') ||
        validatepassCharUpper('passwordGroup.password') ||
        registerForm.get('confirmPolicy')?.value === false ||
        texterror
          ? 'btn_blue_small_disabled cursor-not-allowed'
          : 'btn_blue_small'
      "
    >
      <span class="absolute left-0 inset-y-0 flex items-center pl-3">
        <ng-container *ngIf="loading === true">
          <svg
            class="animate-spin h-5 w-5 fill-current"
            style="color: #f5f9fc"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 458.186 458.186"
            style="enable-background: new 0 0 458.186 458.186"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M445.651,201.95c-1.485-9.308-10.235-15.649-19.543-14.164c-9.308,1.485-15.649,10.235-14.164,19.543
                             c0.016,0.102,0.033,0.203,0.051,0.304c17.38,102.311-51.47,199.339-153.781,216.719c-102.311,17.38-199.339-51.47-216.719-153.781
                             S92.966,71.232,195.276,53.852c62.919-10.688,126.962,11.29,170.059,58.361l-75.605,25.19
                             c-8.944,2.976-13.781,12.638-10.806,21.582c0.001,0.002,0.002,0.005,0.003,0.007c2.976,8.944,12.638,13.781,21.582,10.806
                             c0.003-0.001,0.005-0.002,0.007-0.002l102.4-34.133c6.972-2.322,11.675-8.847,11.674-16.196v-102.4
                             C414.59,7.641,406.949,0,397.523,0s-17.067,7.641-17.067,17.067v62.344C292.564-4.185,153.545-0.702,69.949,87.19
                             s-80.114,226.911,7.779,310.508s226.911,80.114,310.508-7.779C435.905,339.799,457.179,270.152,445.651,201.95z"
                />
              </g>
            </g>
          </svg>
        </ng-container>
      </span>
      <p>สมัครใช้งาน</p>
    </button>
  </div>
</div>

<!-- อย่าเพิ่งลบเผื่อใช้ -->
<!-- <div
  class="relative w-full"
  style="
    background-image: url('assets/image/gapp-img/img-register.png');
    background-repeat: no-repeat;
    background-position: cover;
    width: 40vw;
  "
></div> -->

<!-- Dialog PinNumber -->
<!-- <div class="relative" *ngIf="showDialog">
  <div class="absoulte">
    <div
      class="bg-black bg-opacity-40 min-h-screen fixed top-0 left-0 right-0 bottom-0 z-20 flex justify-center items-center">
      <div class="card filter drop-shadow-2xl" style="height: 389; width: 389px">
        <div class="relative">
          <div class="absolute top-0 right-0">
            <fa-icon (click)="closeDialog()" [icon]="faTimes"
              class="cursor-pointer text-gray-300 hover:text-red-500 text-sm">
            </fa-icon>
          </div>
        </div>

        <ng-container *ngIf="!dialogConfirm">
          <div class="flex flex-row gap-2">
            <img src="assets/image/just_logo.svg" class="object-contain min-w-10 max-w-10 min-h-10 max-h-10"   />
            <div class="flex flex-col gap-0.5">
              <p class="text-deep-blue-sea text-left text-xl flex ml-0 justify-start">
                กำหนดรหัสธุรกิจ (PIN ID)
              </p>
              <div class="  text-base">
                กรุณากรอกรหัสธุรกิจเป็นตัวเลข 6 หลัก ที่ต้องการใช้
              </div>
            </div>
          </div>


          <form novalidate [formGroup]="pinForm">

            <div formGroupName="pinNumberGroup">
              <div class="py-4 relative">
                <label for="last_name" class="text-txt-title   text-base">รหัสธุรกิจ </label>
                <div class="flex flex-wrap items-stretch w-full relative">
                  <input mask="000000" maxlength="6" (keydown)="validatePinNumber()" id="inputPinNumber"
                    [type]="hide4 ? 'password' : 'text'" formControlName="pinNumber" placeholder="รหัสธุรกิจ"
                    autocomplete="new-password"
                    class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none text-xl h-10 inputBorderFocus rounded-md border border-gray-300 px-3 relative   placeholder-gray-400 placeholder-opacity-60"
                    [ngClass]="{
                      'border-red-400 border-1':
                        pinForm.get('pinNumberGroup')!.errors ||
                        ((pinForm.get('pinNumberGroup.pinNumber')!.touched ||
                          pinForm.get('pinNumberGroup.pinNumber')!.dirty) &&
                          !pinForm.get('pinNumberGroup.pinNumber')!.valid)
                    }" />
                  <div class="flex -mr-px">
                    <span
                      class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap     text-base">
                      <img *ngIf="!hide4" (click)="hide4 = !hide4"
                        class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6" src="../../../../assets/image/view.svg"
                          />
                      <img *ngIf="hide4" (click)="hide4 = !hide4"
                        class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6" src="../../../../assets/image/hide.svg"
                          />
                    </span>
                  </div>
                </div>
                <div class="space-y-2">
                  <p [ngClass]="
                      pinForm.get('pinNumberGroup.pinNumber')!.errors?.['minlength']
                        ? 'text-red-400 dark:text-red-300'
                        : 'text-green-500' &&
                          pinForm.get('pinNumberGroup.pinNumber')!.errors?.['required']
                        ? '  '
                        : 'text-green-500'
                    " class="  text-base   flex items-center">
                    <img *ngIf="pinForm.get('pinNumberGroup.pinNumber')!.errors?.['required']" class="w-5 h-5 mr-2"
                      src=" assets/image/taoo.svg"   />
                    <img *ngIf="
                        !pinForm.get('pinNumberGroup.pinNumber')!.errors?.['minlength'] &&
                        !pinForm.get('pinNumberGroup.pinNumber')!.errors?.['required']
                      " class="w-5 h-5 mr-2" src=" assets/image/cheak_bc.svg"   />
                    <img *ngIf="pinForm.get('pinNumberGroup.pinNumber')!.errors?.['minlength']" class="w-5 h-5 mr-2"
                      src=" assets/image/check_bcc.svg"   />
                    กรอกรหัสธุรกิจเป็นตัวเลข 6 หลัก
                  </p>
                </div>
              </div>
              <div class="py-1 relative">
                <label for="last_name" class="text-txt-title   text-base">ยืนยันรหัสธุรกิจ </label>
                <div
                  class="flex flex-wrap items-stretch w-full relative focus:ring-green-island-500 focus:border-green-island-500 focus:ring-1">
                  <input mask="000000" maxlength="6" id="inputPinNumber2" [type]="hide5 ? 'password' : 'text'"
                    placeholder="ยืนยันรหัสธุรกิจ" formControlName="confirmPinNumber" autocomplete="new-password"
                    class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none text-xl h-10 inputBorderFocus rounded-md border border-gray-300 px-3 relative   placeholder-gray-400 placeholder-opacity-60"
                    [ngClass]="{
                      'border-red-400 border-1':
                        pinForm.get('pinNumberGroup')!.errors ||
                        ((pinForm.get('pinNumberGroup.confirmPinNumber')!.touched ||
                          pinForm.get('pinNumberGroup.confirmPinNumber')!.dirty) &&
                          !pinForm.get('pinNumberGroup.confirmPinNumber')!.valid)
                    }" (keyup.enter)="
                      pinForm.get('pinNumberGroup')!.errors?.['match'] &&
                      !pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                        ? ''
                        : patchPinToAliasName(
                            pinForm.get('pinNumberGroup.confirmPinNumber').value
                          )
                    " />
                  <div class="flex -mr-px">
                    <span
                      class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap     text-base">
                      <img *ngIf="!hide5" (click)="hide5 = !hide5"
                        class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6" src="../../../../assets/image/view.svg"
                          />
                      <img *ngIf="hide5" (click)="hide5 = !hide5"
                        class="cursor-pointer opacity-50 top-10 right-3 w-6 h-6" src="../../../../assets/image/hide.svg"
                          />
                    </span>
                  </div>
                </div>

                <div class="space-y-2">
                  <p [ngClass]="
                      pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['minlength']
                        ? 'text-red-400 dark:text-red-300'
                        : 'text-green-500' &&
                          pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                        ? '  '
                        : 'text-green-500'
                    " class="  text-base   flex items-center">
                    <img *ngIf="
                        pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                      " class="w-5 h-5 mr-2" src=" assets/image/taoo.svg"   />
                    <img *ngIf="
                        !pinForm.get('pinNumberGroup.confirmPinNumber')!.errors
                          ?.minlength &&
                        !pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                      " class="w-5 h-5 mr-2" src=" assets/image/cheak_bc.svg"   />
                    <img *ngIf="
                        pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['minlength']
                      " class="w-5 h-5 mr-2" src=" assets/image/check_bcc.svg"   />
                    กรอกรหัสยืนยันธุรกิจเป็นตัวเลข 6 หลัก
                  </p>
                </div>

                <div class="space-y-2">
                  <p [ngClass]="
                      pinForm.get('pinNumberGroup')!.errors?.['match'] &&
                      !pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required'] ||
                      pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['minlength']
                        ? 'text-red-400 dark:text-red-300'
                        : 'text-green-500' &&
                          pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                        ? '  '
                        : 'text-green-500'
                    " class="  text-base   flex items-center">
                    <img *ngIf="
                        pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required']
                      " class="w-5 h-5 mr-2" src=" assets/image/taoo.svg"   />
                    <img *ngIf="pinForm.get('pinNumberGroup')!.valid" class="w-5 h-5 mr-2"
                      src=" assets/image/cheak_bc.svg"   />
                    <img *ngIf="
                        pinForm.get('pinNumberGroup')!.errors?.['match'] &&
                        !pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['required'] ||
                      pinForm.get('pinNumberGroup.confirmPinNumber')!.errors?.['minlength']
                      " class="w-5 h-5 mr-2" src=" assets/image/check_bcc.svg"
                        />ยืนยันรหัสธุรกิจตรงกัน
                  </p>
                </div>
              </div>
            </div>
          </form>

          <div class="flex justify-center gap-2">
            <button class="btn_cancel_small" (click)="closeDialog()">ยกเลิก</button>

            <button [disabled]="!pinForm.get('pinNumberGroup').valid" [ngClass]="!pinForm.get('pinNumberGroup').valid
                  ? 'btn_cancel_small cursor-not-allowed'
                  : 'btn_blue_small'
              " class="" (click)="
                patchPinToAliasName(pinForm.get('pinNumberGroup.confirmPinNumber').value)
              ">
              บันทึก
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="dialogConfirm">
          <div class="flex flex-col justify-center items-center gap-6 py-6">
            <img src="assets/image/check-circle.svg"   />
            <p class="font-semibold   text-base">ยืนยัน PIN ID สำเร็จ</p>
            <p class="text-center   text-base">
              รหัสธุรกิจ (PIN ID) นี้ จะใช้สำหรับเข้าสู่ระบบ Business Center และ
              เปลี่ยนแปลงข้อมูลสำคัญของธุรกิจ
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<!-- * รหัสธุรกิจ -->
<!-- <div>
                <div class="relative">
                  <label for="last_name" class="text-txt-title   text-base">รหัสธุรกิจ
                    <span class="text-red-400 text-xl">*</span>
                  </label>
                  <div class="tooltip ml-2">
                    <fa-icon class="text-color-blue-bg" [icon]="faQuestionCircle"></fa-icon>
                    <span class="tooltiptext ml-2 w-72 text-xl break-words" style="font-size: 18px">รหัสธุรกิจ
                      เป็นตัวเลข
                      6 หลัก ที่ใช้สำหรับเข้าสู่ระบบ Business Center
                      และ เปลี่ยนแปลงข้อมูลสำคัญของธุรกิจ</span>
                  </div>

                  <ng-container *ngIf="!registerForm.get('aliasname')?.value">
                    <button type="button" class="flex btn_blue_small" (click)="openDialog()">
                      เพิ่ม PIN ID
                    </button>
                  </ng-container>

                  <ng-container *ngIf="registerForm.get('aliasname')?.value">
                    <div class="flex flex-row gap-2">
                      <div class="flex flex-wrap items-stretch w-48 relative">
                        <input (keydown)="engOnly($event)" (keydown)="validateFields($event)" disabled
                          (keydown)="checkspace($event)" autocomplete="organization" id="aliasname" type="password"
                          formControlName="aliasname"
                          class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none h-10 rounded-md border border-gray-300 px-3 inputBorderFocus relative text-xl text-gray-400 bg-gray-200 cursor-not-allowed placeholder-gray-400 placeholder-opacity-60"
                          placeholder="รหัสธุรกิจ" [ngClass]="{
                          'border-red-400 border-1':
                            (registerForm.get('aliasname')!.touched ||
                              registerForm.get('aliasname')!.dirty) &&
                            !registerForm.get('aliasname')!.valid
                        }" />
                        <div class="flex -mr-px">
                          <span
                            class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-main-text   text-base">
                            <img *ngIf="hide3 === true" (click)="showpass3()"
                              class="cursor-pointer opacity-50 top-9 right-3 w-6 h-6"
                              src="../../../../assets/image/view.svg"   />
                            <img *ngIf="hide3 === false" (click)="showpass3()"
                              class="cursor-pointer opacity-50 top-9 right-3 w-6 h-6"
                              src="../../../../assets/image/hide.svg"   />
                          </span>
                        </div>
                      </div>

                      <div class="flex items-center">
                        <button type="button" class="text-white bg-yellow-600 bg-opacity-80 rounded-md p-1.5 text-sm"
                          (click)="openDialog()">
                          แก้ไข PIN ID
                        </button>
                      </div>
                    </div>

                    <div class="space-y-2">
                      <ng-container class="absolute" *ngIf="
                        registerForm.get('aliasname')!.invalid &&
                        (registerForm.get('aliasname')!.dirty ||
                          registerForm.get('aliasname')!.touched)
                      ">
                        <div class="absolute flex">
                          <p class="text-sm text-red-400" *ngIf="registerForm.get('aliasname').errors?.['required']">
                            กรุณากรอกรหัสธุรกิจ
                          </p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div> -->

<!-- codeOtp -->
<!--
<div class="flex justify-center">
  <ng-container *ngFor="let pin of convertNumberToArray(pinlength); index as i" [key]="'codefield_' + i">
    <input [autofocus]="i == 0" [id]="'codefield_' + i" class="
                    h-16
                    w-12
                    border
                    mx-2
                    rounded-lg
                    flex
                    items-center
                    text-center
                    font-thin
                    text-3xl
                  " value="" max="9" min="0" type="number" (keyup)="stepForward(i)" (keydown.backspace)="stepBack(i)"
      (focus)="resetValue(i)" autocomplete="off" onKeyPress="if(this.value.length==1) return false;" />
  </ng-container>
</div>
tar2::{{codeOtpConfirm}}
<div class="flex justify-center">
  <ng-container *ngFor="let pin of convertNumberToArrayConfirm(pinlengthConfirm); index as i"
    [key]="'codeconfirm_' + i">
    <input [autofocus]="i == 0" [id]="'codeconfirm_' + i" class="
                    h-16
                    w-12
                    border
                    mx-2
                    rounded-lg
                    flex
                    items-center
                    text-center
                    font-thin
                    text-3xl
                  " value="" max="9" min="0" type="number" (keyup)="stepForwardConfirm(i)"
      (keydown.backspace)="stepBackConfirm(i)" (focus)="resetValueConfirm(i)" autocomplete="off"
      onKeyPress="if(this.value.length==1) return false;" />
  </ng-container>
</div> -->

<!-- <style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style> -->

<!-- <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company">
    <h2 class="mt-10 text-center   text-base font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
        <div class="mt-2">
          <input id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
          <div class="text-sm">
            <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
          </div>
        </div>
        <div class="mt-2">
          <input id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div>
        <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
      </div>
    </form>

    <p class="mt-10 text-center text-sm text-gray-500">
      Not a member?
      <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14 day free trial</a>
    </p>
  </div>
</div>-->
