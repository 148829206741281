import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private businessId = new BehaviorSubject<string>('')
  private companyCode = new BehaviorSubject<string>('')
  private comId = new BehaviorSubject<string>('')
  private haveDefaultReceiver = new BehaviorSubject<string>('')
  private username = new BehaviorSubject<string>('')
  private personalCode = new BehaviorSubject<string>('')
  private adminUsername = new BehaviorSubject<string>('')
  private staffUsername = new BehaviorSubject<string>('')
  private userRole = new BehaviorSubject<string>('')

  sendbusinessId(data: string) {
    this.businessId.next(data)
  }

  getbusinessId(): Observable<string> {
    return this.businessId.asObservable()
  }
  sendcompanyCode(data: string) {
    this.companyCode.next(data)
  }
  getcompanyCode(): Observable<string> {
    return this.companyCode.asObservable()
  }
  sendcomId(data: string) {
    this.comId.next(data)
  }
  getcomId(): Observable<string> {
    return this.comId.asObservable()
  }
  sendhaveDefaultReceiver(data: string) {
    this.haveDefaultReceiver.next(data)
  }
  gethaveDefaultReceiver(): Observable<string> {
    return this.haveDefaultReceiver.asObservable()
  }

  sendadminUsername(data: string) {
    this.adminUsername.next(data)
  }
  getadminUsername(): Observable<string> {
    return this.adminUsername.asObservable()
  }

  sendstaffUsername(data: string) {
    this.staffUsername.next(data)
  }
  getstaffUsername(): Observable<string> {
    return this.staffUsername.asObservable()
  }

  senduserRole(data: string) {
    this.userRole.next(data)
  }
  getuserRole(): Observable<string> {
    return this.userRole.asObservable()
  }

  private MainTitle = new BehaviorSubject<string>('')
  private StaffTitle = new BehaviorSubject<string>('')
  private Title = new BehaviorSubject<string>('')
  private CompanyName = new BehaviorSubject<string>('')
  private TitlelLevelOne = new BehaviorSubject<string>('')
  private TitlelLevelTwo = new BehaviorSubject<string>('')
  private companyTitle = new BehaviorSubject<string>('')

  private Link1 = new BehaviorSubject<string>('')
  private Link2 = new BehaviorSubject<string>('')
  private Link3 = new BehaviorSubject<string>('')
  private Link4 = new BehaviorSubject<string>('')

  sendMainTitle(data: string) {
    this.MainTitle.next(data)
  }
  getMainTitle(): Observable<string> {
    return this.MainTitle.asObservable()
  }

  sendComTitle(data: string) {
    this.companyTitle.next(data)
  }

  getComTitle(): Observable<string> {
    return this.companyTitle.asObservable()
  }

  sendStaffTitle(data: string) {
    this.StaffTitle.next(data)
  }
  getStaffTitle(): Observable<string> {
    return this.StaffTitle.asObservable()
  }

  sendTitle(data: string) {
    this.Title.next(data)
  }
  getTitle(): Observable<string> {
    return this.Title.asObservable()
  }

  sendCompanyName(data: string) {
    this.CompanyName.next(data)
  }
  getCompanyName(): Observable<string> {
    return this.CompanyName.asObservable()
  }

  senderTitlelLevOne(data: string) {
    this.TitlelLevelOne.next(data)
  }

  getTitlelLevOne(): Observable<string> {
    return this.TitlelLevelOne.asObservable()
  }

  senderTitlelLevTwo(data: string) {
    this.TitlelLevelTwo.next(data)
  }

  getTitlelLevtwo(): Observable<string> {
    return this.TitlelLevelTwo.asObservable()
  }

  sendLinkOne(data: string) {
    this.Link1.next(data)
  }

  getLinkOne(): Observable<string> {
    return this.Link1.asObservable()
  }

  // sendLink(data: string) {
  //   this.Link.next(data)
  // }

  // getLink(): Observable<string> {
  //   return this.Link.asObservable()
  // }

  sendusername(data: string) {
    this.username.next(data)
  }
  getusername(): Observable<string> {
    return this.username.asObservable()
  }

  sendpersonalCode(data: string) {
    this.personalCode.next(data)
  }
  getpersonalCode(): Observable<string> {
    return this.personalCode.asObservable()
  }

  constructor() {}
}
