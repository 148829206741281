import {
  EAmplifyEnvName, ENV_DATADOG_LOG_SERVICE_BETA,
  ENV_DATADOG_RUM_APPLICATION_ID_BETA, ENV_DATADOG_RUM_CLIENT_TOKEN_BETA, ENV_DATADOG_RUM_SERVICE_BETA,
  EUrlGappBizPrefix,
  IEnvironment,
  S3PublicBucketPrefix
} from "../app/core/interface";

export const environment: IEnvironment = {
  production: false,
  name: 'dev',
  amplifyEnvName: EAmplifyEnvName.dev,
  timeStamp: '06/15/2022 02:57:36 PM +07',
  s3PublicBucketName: `${S3PublicBucketPrefix}-${EAmplifyEnvName.dev}`,
  urlGappBizPrefix: EUrlGappBizPrefix.dev,
  datadogRumApplicationId: ENV_DATADOG_RUM_APPLICATION_ID_BETA,
  datadogRumClientToken: ENV_DATADOG_RUM_CLIENT_TOKEN_BETA,
  datadogRumService: ENV_DATADOG_RUM_SERVICE_BETA,
  datadogLogService: ENV_DATADOG_LOG_SERVICE_BETA
}
