import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'btn-checkbox',
  templateUrl: './btn-checkbox.component.html',
  styles: [],
})
export class BtnCheckboxComponent implements OnInit {
  @Input() active: boolean = false
  @Input() label: string = ''
  constructor() {}

  ngOnInit(): void {}

  check() {
    this.active = !this.active
  }
}
