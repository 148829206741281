<div class="  fixed right-3 top-0 rounded-lg dialog-size z-30 shadow-md border bg-white max-h-60 min-h-32">

  <div class="h-auto">
    <div class="grid grid-cols-2 mt-4 justify-items-center gap-3">
      <div *ngFor="let item of gappList" class="cursor-pointer">
        <ng-container *ngIf="item.gappStatus">
          <a target="_blank" href="{{ item.gappLinkUrl }}">
            <img src="{{ item.gappLogo }}" class="
                dialog-img
                border-2 border-white
                rounded-md
                hover:border-btn-second-color
                transition
                ease-in
                duration-300
              " />
            <p class="text-sm text-center mt-3 break-words w-16">{{ item.gappName }}</p>
          </a>
        </ng-container>
      </div>
      <!-- <div class="cursor-pointer">
        <a target="_blank" href="https://dev.business.gappbiz.com/login">
          <img src="assets/images/logo-bc.png" class="
              dialog-img
              border-2 border-white
              rounded-md
              hover:border-orange-second-color-500
              transition
              ease-in
              duration-300
            " />
          <p class="text-sm text-center mt-3 break-words w-16">Business Center</p>
        </a>
      </div> -->
    </div>
  </div>
</div>

<style>
  .dialog-img {
    width: 60px;
    height: 60px;
  }

  .dialog-size {
    width: 200px;
    /* height: 200px; */
    top: 80px;
  }
</style>