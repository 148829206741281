<div class="flex flex-col items-center justify-center h-screen bg-mainBackground-500">
  <div class="w-full flex items-center justify-center">
    <div class="pageAccountHalf">
      <div class="p-5 md:px-8 md:py-6 bg-white rounded-xl shadow-xl">
        <!-- mb-4 -->
        <div class="grid grid-cols-1 gap-8">
          <div class="col-span-1">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <button routerLink="/login" class="hover:underline hover:text-green-island-500">
                  << Back To menu </button>
                    <img class="h-8" src="assets/image/gapp-img/new_logo_main.png" />
              </div>

              <div class="flex justify-center my-6">
                <img src="../../../../assets/image/dash_logo_key.svg" class="w-16 h-16 object-contain" alt="" />
              </div>

              <p class="text-3xl text-center text-gray-700">ตั้งรหัสผ่านใหม่</p>
              <p class="mt-1 text-xl text-center text-gray-700">กรุณากรอกอีเมล</p>
            </div>

            <div class="flex flex-col gap-4 md:gap-6 mt-8 mx-5">
              <form class="" [formGroup]="form" (keyup.enter)="submit()">
                <div class="rounded-md shadow-sm">
                  <div class="relative">
                    <p *ngIf="form.get('mail')?.value" for="mail"
                      class="text-xl text-gray-400 absolute z-20 bg-white px-1 left-4 -top-3">
                      อีเมล
                    </p>
                    <input id="mail" name="mail" formControlName="mail" class="border-gray-300 focus:ring-green-island-500 focus:border-green-island-500 focus:ring-1
                      relative block w-full px-3 h-12 text-gray-700 placeholder-gray-300 border font-medium appearance-none
                      rounded-md focus:outline-none focus:z-10 mb-5 text-lg" placeholder="E-mail" autofocus
                      autocomplete="off" />
                  </div>
                </div>

                <div class="flex flex-col gap-4 md:gap-6 mb-4">
                  <button [disabled]="!form.valid" type="submit" (click)="submit()" class="mt-20 group relative w-full flex justify-center items-center  h-12 border border-transparent text-lg font-medium rounded-md text-white {{
                      form.valid
                        ? 'bg-green-island-500 focus:ring-offset-2 focus:outline-none focus:ring-2 focus:ring-orange-second-color-500'
                        : 'bg-gray-400 cursor-not-allowed'
                    }}">
                    รีเซ็ตรหัสผ่าน
                  </button>
                  <!-- <button type="submit" (click)="submitSendEmail()"
                    class="mt-20 group relative w-full flex justify-center items-center h-12 border border-transparent text-lg font-medium rounded-md text-white bg-green-island-500 focus:ring-offset-2 focus:outline-none focus:ring-2 focus:ring-orange-second-color-500">
                    รีเซ็ตรหัสผ่าน
                  </button> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <app-login-footer></app-login-footer>
  </div>
</div>
