import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

// Amplify Config
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

// Datadog Config
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';

const env = environment.name;
console.log('@@@env@@@', env);

if (environment.name === 'dev') {
  datadogLogs.init({
    clientToken: environment.datadogRumClientToken as string,
    site: 'ap1.datadoghq.com',
    service: environment.datadogLogService as string,
    env: environment.name,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sessionSampleRate: 100,
  })
  datadogRum.init({
    applicationId: environment.datadogRumApplicationId as string,
    clientToken: environment.datadogRumClientToken as string,
    site: 'ap1.datadoghq.com',
    service: environment.datadogRumService as string,
    env: environment.name,
    version: '1.0.0',
    sessionSampleRate:100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
  });
  datadogRum.startSessionReplayRecording();
}

else if (environment.production) {
  datadogLogs.init({
    clientToken: environment.datadogRumClientToken as string,
    site: 'ap1.datadoghq.com',
    service: environment.datadogLogService as string,
    env: environment.name,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sessionSampleRate: 100,
  })
  datadogRum.init({
    applicationId: environment.datadogRumApplicationId as string,
    clientToken: environment.datadogRumClientToken as string,
    site: 'ap1.datadoghq.com',
    service: environment.datadogRumService as string,
    env: environment.name,
    version: '1.0.0',
    sessionSampleRate:100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}




platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
