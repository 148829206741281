import { Component, Input, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'

import { AuthService } from './../../../core/services/auth.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

function passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
  const passwordControl: any = c.get('password')
  const confirmControl: any = c.get('confirmpassword')

  if (passwordControl.pristine || confirmControl.pristine) {
    return null
  }

  if (passwordControl.value === confirmControl.value) {
    return null
  }
  return { match: true }
}

@LogComponent()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})
export class ForgotPasswordComponent implements OnInit {
  @Input() control: FormControl = new FormControl('')
  formSendEmail!: FormGroup
  send: boolean = false
  showValidatePassword: boolean = false
  sendSubmit!: FormGroup
  hide: boolean = true
  hide2: boolean = true
  form!: FormGroup

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formSendEmail = this.fb.group({
      phone: ['', [Validators.required]],
    })

    this.sendSubmit = this.fb.group({
      phone: ['', [Validators.required]],
      code: ['', [Validators.required]],
      passwordGroup: this.fb.group(
        {
          password: ['', [Validators.required, Validators.minLength(8)]],
          confirmpassword: ['', [Validators.required]],
        },
        { validator: passwordMatcher }
      ),
    })
    this.Builder()
  }

  // PANG
  Builder() {
    this.form = this.fb.group({
      mail: ['', [Validators.required]],
    })
  }

  //* New submit send email (Forgot Password -> Mail)
  submit() {
    if (this.form.valid) {
      this.authService._setEmail$.next(this.form.get('mail')?.value)
      this.authService.forgotPasswordSendMail(this.form.get('mail')?.value).subscribe(
        () => {
          this.authService.setCompState(this.form.get('mail')?.value)
          this.router.navigateByUrl('/new-password')
        },
        (error) => {
          console.warn(' --- error check --- ', error)
          this.router.navigateByUrl('/login')
        }
      )
    }
  }

  submitSendEmail() {
    console.warn('log submit Send Email =>')
    this.router.navigateByUrl('/new-password')
  }

  sendEmailForm() {
    console.log(this.formSendEmail)
    this.authService
      .forgotPasswordSendMail(this.formSendEmail.get('phone')!.value)
      .subscribe()
    this.send = true
    let form1 = document.getElementById('form1') as HTMLDivElement
    let form2 = document.getElementById('form2') as HTMLDivElement
    form1.style.display = 'none'
    form2.style.display = 'block'
  }

  sendSubmitfx() {
    console.log(this.sendSubmit)
    this.authService
      .forgotPasswordSubmit(
        this.sendSubmit.get('phone')!.value,
        this.sendSubmit.get('code')!.value,
        this.sendSubmit.get('passwordGroup.password')!.value
      )
      .subscribe()
    this.router.navigateByUrl('/login')
  }

  showpass() {
    let txtpwd: any = document.getElementById('inputpassword')

    if (txtpwd.type === 'password') {
      txtpwd.type = 'text'
      this.hide = false
    } else {
      txtpwd.type = 'password'
      this.hide = true
    }
  }

  showpass2() {
    let txtpwd: any = document.getElementById('inputpassword2')

    if (txtpwd.type === 'password') {
      txtpwd.type = 'text'
      this.hide2 = false
    } else {
      txtpwd.type = 'password'
      this.hide2 = true
    }
  }
  validatePassword() {
    this.showValidatePassword = true
  }
}
