import { Component, Input, OnInit } from '@angular/core'
import { Storage } from 'aws-amplify'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-path-image',
  templateUrl: './path-image.component.html',
  styles: [],
})
export class PathImageComponent implements OnInit {
  @Input() s3Key: any
  @Input() width: string = 'w-36'
  @Input() height: string = 'h-36'
  @Input() rounded: string = 'rounded-full'
  @Input() objectFit: string = 'object-cover'

  Linkimg: any
  constructor() {}

  ngOnInit(): void {
    Storage.get(this.s3Key, {
      bucket: environment.s3PublicBucketName,
      region: 'ap-southeast-1',
    }).then((res) => {
      this.Linkimg = res
    })
  }
}
