<section class="bg-card-gray lg:pb-0 h-auto min-h-screen">
  <div class="container mx-auto pt-20 pb-5 px-4 xl:px-0">
    <div class="w-full mx-auto">
      <div class="flex justify-center">
        <!-- <img class="mx-auto w-40 lg:w-72 dark:hidden block" src="../../../../assets/image/Group 2770.svg" alt="" /> -->
        <img class="mx-auto w-40 lg:w-72" src="../../../../assets/image/gapp-img/gapp-logo.svg" alt="" />
      </div>

      <p class="mt-7 text-center font-normal text-gray-400 text-base md:text-lg xl:text-xl">
        เปิดใช้งานธุรกิจของคุณโดยการกรอกและเลือกแบบสอบถามของเรา
      </p>
    </div>

    <div class="my-5">
      <!-- <form (ngSubmit)="openbiz()" novalidate [formGroup]="openbusinessForm"> -->
      <form [formGroup]="openbusinessForm">
        <div class="grid grid-cols-1 gap-0">
          <!-- <div class="py-3 w-full mx-auto  max-w-4xl  relative">
            <label for="owner_interests"
              class="block  dark:text-gray-300    font-normal  text-blue-400  capitalize ">ชื่อธุรกิจ</label>
            <input autocomplete="off" formControlName="" id="" name="" type="text" class="mt-1  dark:text-gray-200 text-gray-500 font-normal   placeholder-gray-500 placeholder-opacity-50  h-12
                    dark:bg-gray-600  bg-gray-100    dark:focus:ring-white focus:ring-indigo-500 dark:focus:border-white focus:border-indigo-500
                 block w-full shadow-sm sm:text-sm  dark:border-transparent  border-gray-200   border rounded-md">
          </div> -->

          <!-- <div class="py-3  w-full mx-auto  max-w-4xl  relative">
            <label for="business_website" class="block  dark:text-gray-300   font-normal  text-blue-400  capitalize ">
              เว็บไซต์ธุรกิจ </label>


            <div class="grid  grid-cols-5 gap-4 ">
              <select #frontwww id="frontwww" autocomplete="off"
                class="mt-1  w-full dark:text-gray-200 text-gray-500 font-semibold   placeholder-gray-500 placeholder-opacity-50  h-12
                        dark:bg-gray-600  bg-gray-100  text-center   dark:focus:ring-white focus:ring-indigo-500 dark:focus:border-white focus:border-indigo-500
                     block  shadow-sm  text-xs  sm:text-base  dark:border-transparent  border-gray-200   border  rounded-md ">

                <option *ngFor="let rr of listtypewab ">
                  {{rr.name}}
                </option>



              </select>
              <input autocomplete="off" formControlName="businessWebsite" id="business_website" name="business_website"
                type="text" class="mt-1 col-span-4   dark:text-gray-200 text-gray-500 font-semibold   placeholder-gray-500 placeholder-opacity-50  h-12
                        dark:bg-gray-600  bg-gray-100    dark:focus:ring-white focus:ring-indigo-500 dark:focus:border-white focus:border-indigo-500
                     block w-full shadow-sm sm:text-sm  dark:border-transparent  border-gray-200   rounded-md"
                autofocus>
            </div>
          </div> -->

          <!-- <div class="py-3 w-full mx-auto  max-w-7xl     relative">
            <label for="business_size"
              class="block dark:text-gray-300   text-center  font-normal  text-blue-400    capitalize "> ขนาดธุรกิจ
            </label>



            <div class="grid   grid-cols-1 md:grid-cols-4   gap-8  my-5   ">

              <div class="relative" *ngFor="let listsizebusiness of listssizebusiness; let i = index ">
                <input class="absolute top-3 left-3  hidden    dark:text-indigo-900 text-indigo-200  "
                  [formControl]="openbusinessForm.get('businessSize')" value="{{listsizebusiness.value}}"
                  name="businessize" id="r{{i}}" type="radio">

                <label class=" transition duration-500 ease-in  ddd  flex cursor-pointer text-sm  focus:text-gray-500   justify-center    items-center
                                       dark:bg-gray-600 bg-gray-200
                                    px-2   py-5    xl:py-10  rounded-xl   dark:text-white
                                  text-gray-600  " for="r{{i}}">

                  <div
                    class="      md:text-center    w-full  md:w-auto    flex justify-start items-center   md:block     ">


                    <img class=" werr dark:hidden block   md:mx-auto   w-10  lg:w-20  h-10  lg:h-20  "
                      src="{{listsizebusiness.imagedark }}" alt="">
                    <img class=" wer dark:block hidden   md:mx-auto   w-10   lg:w-20   h-10 lg:h-20  "
                      src="{{listsizebusiness.imagelight}}" alt="">
                    <div class="  ml-5 md:ml-0   mt-0 md:mt-4 ">
                      <p class=" td  dark:hidden block         capitalize font-normal   text-base  xl:text-xl">
                        {{listsizebusiness.name}}</p>
                      <p class=" tl  dark:block hidden     capitalize font-normal   text-base  xl:text-xl">
                        {{listsizebusiness.name}}</p>

                      <p class=" td  dark:hidden block  capitalize font-normal     text-xs  lg:text-sm">
                        {{listsizebusiness.note}}</p>
                      <p class=" tl  dark:block hidden  capitalize font-normal    text-xs   lg:text-sm">
                        {{listsizebusiness.note}}</p>
                    </div>



                  </div>


                </label>

              </div>

            </div>
          </div>


 -->

          <!-- <div class="py-3 w-full mx-auto  max-w-6xl   relative">
            <label for="business_type"
              class="block  dark:text-gray-300  text-center  font-normal  text-blue-400  capitalize "> ประเภทธุรกิจ
            </label>
            <div [formGroup]='openbusinessForm' class="grid  grid-cols-1 sm:grid-cols-3 gap-8  my-5 ">
              <ng-container formArrayName="businessType">
                <div class="relative " *ngFor="let liststypebusines of liststypebusiness; let i = index ">
                  <input class="absolute top-3   hidden   left-3   dark1:text-indigo-900 text-indigo-200  "
                    (change)="onCheckType($event,i)" formControlName="{{ i }}" value="{{liststypebusines.value}}"
                    name="businesstype" id="t{{i}}" type="checkbox">

                  <label class="transition duration-500 ease-in  ccc  flex cursor-pointer text-sm  focus:text-gray-500   justify-center    items-center
                             dark:bg-gray-600 bg-gray-200
                          px-2   py-5    xl:py-10  rounded-xl   dark:text-white
                        text-gray-600  " for="t{{i}}">


                    <p class=" td1  dark:hidden block  capitalize font-normal    text-base xl:text-xl">
                      {{liststypebusines.name}}</p>
                    <p class=" tl1  dark:block hidden  capitalize font-normal    text-base xl:text-xl">
                      {{liststypebusines.name}}</p>
                  </label>
                </div>
              </ng-container>
            </div>





          </div> -->

          <!-- <div class="py-3  w-full mx-auto  max-w-6xl  relative">
                      <label for="business_categories" class="block  dark:text-gray-300    font-normal  text-blue-400  capitalize "> หมวดหมู่ธุรกิจ</label>

                      <div class="grid  grid-cols-1  md:grid-cols-2   xl:grid-cols-3 gap-8  my-5 ">

                          <div class="  relative"  *ngFor="let listcatebusiness of listscatebusiness; let i = index " >
                            <input  class="absolute top-3 left-3   hidden  dark:text-indigo-900 text-indigo-200  " [formControl]="openbusinessForm.get('businessCategory')"  value="{{listcatebusiness.name}}"     name="businesscategory"  id="l{{i}}"  type="radio"  >
                            <label  class="transition duration-500 ease-in  ddd flex cursor-pointer   space-x-8  items-center  dark:bg-gray-600 bg-gray-200
                               p-5  xl:p-10 rounded-xl  text-sm  dark:text-white   text-gray-600  "
                              for="l{{i}}"  >


                              <img   class="     w-10  lg:w-20  h-10  lg:h-20   "  src="{{listcatebusiness.image}}" alt="">




                               <p   class=" td  dark:hidden block  capitalize font-normal break-words   text-base  xl:text-xl" >{{listcatebusiness.name}}</p>
                             <p   class=" tl  dark:block hidden  capitalize font-normal break-words   text-base  xl:text-xl" >{{listcatebusiness.name}}</p>

                       </label>
                          </div>


                      </div>

                    </div> -->

          <!-- <div class="py-3 w-full mx-auto  max-w-4xl  relative">
            <label for="owner_interests"
              class="block  dark:text-gray-300    font-normal  text-blue-400  capitalize ">สิ่งที่คุณชอบ </label>
            <input autocomplete="off" formControlName="interest" id="owner_interests" name="owner_interests" type="text"
              class="mt-1  dark:text-gray-200 text-gray-500 font-normal   placeholder-gray-500 placeholder-opacity-50  h-12
                    dark:bg-gray-600  bg-gray-100    dark:focus:ring-white focus:ring-indigo-500 dark:focus:border-white focus:border-indigo-500
                 block w-full shadow-sm sm:text-sm  dark:border-transparent  border-gray-200   border rounded-md">
          </div> -->
          <div class="py-3 w-full mx-auto max-w-4xl relative">
            <label class="flex items-center font-normal text-gray-400 capitalize text-xl">แบบสอบถาม
            </label>
            <!-- <div
              class="h-auto px-3 py-2 mt-1 flex-wrap bg-gray-100 dark:focus:ring-white focus:ring-indigo-500 dark:focus:border-white focus:border-indigo-500 flex items-center w-full shadow-sm sm:text-sm dark:border-transparent border-gray-200 border rounded-md">
              <ng-container *ngIf="interests.length">
                <div class="flex items-center flex-wrap">
                  <label
                    class="my-1 mr-3 h-8 font-normal text-base rounded-md flex items-center px-4 py-0 bg-blue-300 shadow-md text-blue-600"
                    *ngFor="let interested of interests; let i = index">
                    {{ interested }}
                    <img src="../../../assets/image/cancel.svg" class="ml-2 h-3 cursor-pointer"
                      (click)="removeInterests(i)" alt="" />
                  </label>
                </div>
              </ng-container>
              <input autocomplete="off" (keyup)="pushInterests($event)" (blur)="onBlurMethod()" #interest id="interest"
                type="text"
                class="border border-gray-300 rounded-md w-full h-12 text-xl text-gray-400 placeholder-gray-300" />
            </div>
            <p class="flex items-center font-normal text-gray-400 capitalize text-lg mt-1">
              (สามารถเพิ่มได้มากกว่า 1 คำ กดปุ่ม Enter เพื่อบันทึกหรือเพิ่มใหม่)
            </p> -->
          </div>
        </div>

        <div class="text-center mx-auto max-w-4xl">
          <ng-container *ngIf="!openbusinessForm.invalid; else btnDisabled">
            <p (click)="openbiz()"
              class="mt-10 w-full md:w-6/12 mx-auto h-16 inline-flex justify-center py-2 px-24 items-center my-2 md:mb-0 transition duration-300 ease-in md:px-6 shadow-sm text-lg font-normal rounded-md text-white bg-color-btn cursor-pointer">
              เปิดใช้งาน
            </p>
          </ng-container>
          <ng-template #btnDisabled>
            <p
              class="mt-10 w-full md:w-6/12 mx-auto h-16 inline-flex justify-center py-2 px-24 items-center my-2 md:mb-0 transition duration-300 ease-in md:px-6 shadow-sm text-lg font-normal rounded-md text-gray-400 bg-card-gray cursor-pointer">
              เปิดใช้งาน
            </p>
          </ng-template>
        </div>

        <!-- <div class="text-center  mx-auto  max-w-4xl ">
          <button [disabled]="!openbusinessForm.valid || loading == true " type="submit" class="mt-10 w-full   md:w-6/12  mx-auto  h-16 inline-flex justify-center py-2 px-24
                    items-center     my-2 md:mb-0
                    transition duration-300 ease-in md:px-6
                   shadow-sm text-lg font-normal
                  rounded-md text-white bg-color-btn ">

            <ng-container *ngIf="loading === true">
              <svg class="animate-spin h-5 w-5  mr-3 fill-current text-white    " version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 458.186 458.186" style="enable-background:new 0 0 458.186 458.186;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M445.651,201.95c-1.485-9.308-10.235-15.649-19.543-14.164c-9.308,1.485-15.649,10.235-14.164,19.543
                           c0.016,0.102,0.033,0.203,0.051,0.304c17.38,102.311-51.47,199.339-153.781,216.719c-102.311,17.38-199.339-51.47-216.719-153.781
                           S92.966,71.232,195.276,53.852c62.919-10.688,126.962,11.29,170.059,58.361l-75.605,25.19
                           c-8.944,2.976-13.781,12.638-10.806,21.582c0.001,0.002,0.002,0.005,0.003,0.007c2.976,8.944,12.638,13.781,21.582,10.806
                           c0.003-0.001,0.005-0.002,0.007-0.002l102.4-34.133c6.972-2.322,11.675-8.847,11.674-16.196v-102.4
                           C414.59,7.641,406.949,0,397.523,0s-17.067,7.641-17.067,17.067v62.344C292.564-4.185,153.545-0.702,69.949,87.19
                           s-80.114,226.911,7.779,310.508s226.911,80.114,310.508-7.779C435.905,339.799,457.179,270.152,445.651,201.95z" />
                  </g>
                </g>

              </svg>

            </ng-container>
            เปิดใช้งาน
          </button>
        </div> -->
      </form>
    </div>
  </div>
</section>

<style>
  /* Toggle B */
  input:checked~.dot {
    transform: translateX(100%);
  }

  input:checked~.toggle-bg {
    background-color: #48bb78;
  }
</style>

<!-- <style>
  /* active checkbox mode dark  */
  .dark1 input[type='checkbox']:checked~.ccc {
    border: 3px solid #388adb;
    background: #143454 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 20px #5491cf31;
    opacity: 1;
  }

  /* active checkbox light dark  */
  input[type='checkbox']:checked~.ccc {
    border: 3px solid #388adb;
    background: #388adb 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 20px #5491cf31;
    opacity: 1;
  }

  input[type='checkbox']:checked+.ccc div .wer1 {
    filter: grayscale(100%) brightness(39%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.7) !important;
  }

  input[type='checkbox']:checked+.ccc div .td1 {
    color: #ffffff;
  }

  input[type='checkbox']:checked+.ccc div .tl1 {
    color: #388adb;
  }

  input[type='checkbox']:checked+.ccc .td1 {
    color: #ffffff;
  }

  input[type='checkbox']:checked+.ccc .tl1 {
    color: #388adb;
  }

  input[type='checkbox']:checked+.ccc div .werr1 {
    filter: brightness(100) !important;
  }

  /* active checkbox mode white  */
  input[type='checkbox']:checked~.ccf {
    background-color: #143454;
    color: white;
    border: 2px solid #ccc;
  }
</style> -->
<style></style>
