<div class="flex min-h-screen" (window:resize)="onResize()">
  <ng-container *ngIf="Owner$ | async as owner">
    <div class="flex flex-row w-full">
      <app-sidebar></app-sidebar>

      <main class="main flex flex-col w-full">
        <header class=" flex items-center justify-between bg-white min-h-18 px-4 shadow-md sticky top-0 z-20">
          <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">

            <ng-container *ngIf="comp$ | async as comp">
              <!-- <pre>{{comp | json}}</pre> -->
              <div *ngIf="!isEmpty(comp)" class="border border-gray-200 p-2 max-w-52 min-w-52 clamp-1 rounded-lg">
                <div class="flex flex-row items-center gap-2">
                  <ng-container *ngIf="comp.companyLogoThumbUrl !== 'logo/noimage.png'; else notImg">
                    <img
                      class="max-w-6 min-w-6 min-h-6 max-h-6 rounded-md bg-contain object-contain bg-center bg-no-repeat border"
                      [src]="'https://'+ BucketName +'.s3.ap-southeast-1.amazonaws.com/public/img/'+comp.companyLogoThumbUrl" />
                    <!-- <app-path-image [s3Key]="comp.companyLogoThumbUrl" objectFit="object-contain " rounded="rounded-md"
                      width="w-6" height="h-6">
                    </app-path-image> -->
                  </ng-container>
                  <ng-template #notImg>
                    <img class="h-6 w-6 rounded-md bg-contain object-contain bg-center bg-no-repeat border"
                      src="https://gapp-media.s3.ap-southeast-1.amazonaws.com/public/icons/web/noLogoComp.png" />
                  </ng-template>
                  <p class="font-semibold text-xl">{{ comp.companyName}}</p>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="flex items-center ml-auto">


            <!-- NOTE G-PACKAGE -->
            <!-- <div class="flex flex-row items-center">
              <div class="rounded-lg bg-blue-100 px-7 py-2 flex items-center">
                <p class="text-blue-500 font-semibold text-xl">
                  <span class="text-yellow-300">G</span>-PACKAGE
                </p>
              </div>
            </div> -->
            <div class="flex flex-row items-center ml-3">
              <div class="flex flex-row items-center rounded-l-lg border border-gray-200 p-2">
                <fa-icon class="text-sm text-blue-500 mr-2" [icon]="faBuilding"></fa-icon>
                <p *ngIf="companies$ | async as num">จำนวนบริษัท {{ num.length || '0'}}</p>
              </div>
              <div class="flex flex-row items-center border border-l-none border-r-none border-gray-200 p-2">
                <fa-icon class="text-sm text-blue-500 mr-2" [icon]="faUserTie"></fa-icon>
                <p>Admin {{ admin || '0'}}</p>
              </div>

              <div class="flex flex-row items-center rounded-r-lg border border-gray-200 p-2">
                <fa-icon class="text-sm text-blue-500 mr-2" [icon]="faUsers"></fa-icon>
                <p>Staff {{ staff || '0'}}</p>
              </div>
            </div>

            <div class="ml-3 mt-1 cursor-pointer" (click)="[onClickGappList(),showMenu = false]">
              <img src="../../../assets/image/Group-app.svg" class="h-7 w-7" />
            </div>


            <ng-container *ngIf="isShowGappList; else noShowList">
              <div class=" flex flex-row" (mouseleave)="onClickGappList()">
                <app-dialog-gapp-list></app-dialog-gapp-list>
              </div>
            </ng-container>
            <ng-template #noShowList>

            </ng-template>

            <div class="flex flex-row items-center">
              <button (click)="[showmenu(),isShowGappList = false]"
                class="relative block h-10 w-10 rounded-full overflow-hidden shadow focus:outline-none ml-3">
                <img class="absolute top-0 rounded-full w-full h-full object-cover mx-auto"
                  src="https://gapp-media.s3.ap-southeast-1.amazonaws.com/public/icons/web/noimage.png" alt="" />
                <!--                src="https://gapp-media.s3.ap-southeast-1.amazonaws.com/public/icons/web/empty.webp"-->
                <!-- <ng-container *ngIf="
                    owner.avatarThumbUrl !== 'avatar/noimage.jpg' && owner.avatarThumbUrl !=='undefined' ; else
                  notImg ">
                  <app-path-image [s3Key]="'scale-200/' + owner.avatarThumbUrl" width="w-full" height="h-full">
                  </app-path-image>
                </ng-container>
                <ng-template #notImg>
                  <img class="absolute top-0 rounded-full w-full h-full object-cover mx-auto"
                    src="assets/image/empty.svg" alt="" />
                </ng-template> -->
              </button>
              <ng-container *ngIf="showMenu">
                <div (mouseleave)="closemenu()"
                  class="origin-top-right absolute z-10 menuTop right-3 mt-2 w-48 rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <a href="#" routerLink="/app/profile/edit/{{ infoBusiness.businessId }}/{{
                      owner.identityId
                    }}" class="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100" role="menuitem">
                    ข้อมูลส่วนตัว
                  </a>
                  <a href="#" routerLink="/logout" class="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                    role="menuitem">ออกจากระบบ</a>
                </div>
              </ng-container>
            </div>
          </div>
        </header>


        <div class="w-full min-h-screen" style="background-color: #F4F6FA;">
          <router-outlet></router-outlet>
        </div>



      </main>
    </div>
  </ng-container>
</div>


<!-- NOTE open mobile menu open|close can use innerWidth -->

<style>
  .menuTop {
    top: 72px;
  }
</style>
