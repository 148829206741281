import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styles: [],
})
export class ToggleButtonComponent implements OnInit {
  @Input() toggle1: string = ''
  @Input() toggle2: string = ''
  @Input() active: boolean = true
  @Output() func = new EventEmitter()
  chkHasTrue: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.chkHasTrue = this.active
  }

  check() {
    this.chkHasTrue = !this.chkHasTrue
    this.func.emit(this.chkHasTrue)
  }
}
