import { Component, OnInit } from '@angular/core'
import { DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-dialog-reset-password-confirm',
  templateUrl: './dialog-reset-password-confirm.component.html',
  styles: [],
})
export class DialogResetPasswordConfirmComponent implements OnInit {
  constructor(protected ref: DynamicDialogRef) {}

  ngOnInit(): void {}

  btnConfirm() {
    this.ref.close('confirm')
  }

  btnCancel() {
    this.ref.close('cancel')
  }
}
