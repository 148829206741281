import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthRoutingModule} from './auth-routing.module';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
// Import library
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
// Import components
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {ConfirmComponent} from "./components/confirm/confirm.component";
import {CheckmailComponent} from "./components/checkmail/checkmail.component";
import {OpenBusinessComponent} from "./components/open-business/open-business.component";
import {LogoutComponent} from "./components/logout/logout.component";
import {FirstLoginComponent} from "./components/first-login/first-login.component";
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ChooseCompanyComponent} from "./components/choose-company/choose-company.component";
import {NewPasswordComponent} from "./components/new-password/new-password.component";
// Import shared module
import {SharedModule} from "../shared/shared.module";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";



@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ConfirmComponent,
    CheckmailComponent,
    OpenBusinessComponent,
    LogoutComponent,
    FirstLoginComponent,
    ForgotPasswordComponent,
    ChooseCompanyComponent,
    NewPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RxReactiveFormsModule,
    SharedModule,
    FontAwesomeModule,
    AuthRoutingModule,
    DynamicDialogModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [DialogService, provideNgxMask()]
})
export class AuthModule {
}
