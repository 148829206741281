import { Component, OnInit } from '@angular/core'
import { DynamicDialogRef } from 'primeng/dynamicdialog'

import { AuthService } from '../../../../core/services/auth.service'

@Component({
  selector: 'app-dialog-set-otp',
  templateUrl: './dialog-set-otp.component.html',
  styles: [],
})
export class DialogSetOtpComponent implements OnInit {
  pinlength = 6
  codeOtp = ''
  emailVal: any

  constructor(protected ref: DynamicDialogRef, private authService: AuthService) {}

  ngOnInit(): void {
    this.emailVal = this.authService._setEmail$.getValue()
    console.log('log email => ', this.emailVal)
  }

  resetValue(i: number) {
    for (let x = 0; x < this.pinlength; x++) {
      const refElement = document.getElementById(`codefield_${x}`) as any
      if (x >= i) refElement.value = ''
    }
  }

  stepForward(i: number) {
    const refElement = document.getElementById(`codefield_${i}`) as any
    const refElementNextStep = document.getElementById(`codefield_${i + 1}`) as any
    if (refElement.value && i != this.pinlength - 1) {
      refElementNextStep.focus()
      refElementNextStep.value = ''
    }
    this.checkPin()
  }

  stepBack(i: number) {
    const refElementPreStep = document.getElementById(`codefield_${i - 1}`) as any
    console.log('refElementPreStep.value', refElementPreStep.value)
    if (refElementPreStep.value && i != 0) {
      refElementPreStep.focus()
      refElementPreStep.value = ''
    }
  }

  checkPin() {
    let code = ''
    for (let i = 0; i < this.pinlength; i++) {
      const refElement = document.getElementById(`codefield_${i}`) as any
      code = code + refElement.value
    }
    if (code.length == this.pinlength) {
      this.codeOtp = code
    }
  }

  convertNumberToArray(number: any) {
    return new Array(number)
  }

  btnCancelClick() {
    this.ref.close(false)
  }

  btnSubmitClick() {
    console.log('otp ==>', this.codeOtp)
    this.ref.close(this.codeOtp)
  }
}
