import { Component } from '@angular/core';
import { OpenBusinessService } from '../auth/services/open-business.service';
import { Router } from '@angular/router';
import { UserAdminService } from '../core/services/user-admin.service';
import { environment } from '../../environments/environment';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { LogComponent } from '../shared/utils/log.decorator';

@LogComponent()
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styles: [],
})
export class WelcomeComponent {
  faArrowUp = faArrowUp;
  loading: boolean = false;

  ownerEmail: any;
  bucket: any;
  constructor(
    public router: Router,
    private openBusinessService: OpenBusinessService
  ) {}

  ngOnInit(): void {
    this.bucket = environment.urlGappBizPrefix;
    this.openBusinessService.userAttributesEmail$.subscribe((res) => {
      this.ownerEmail = res;
      console.warn('email =>', res);
    });
  }

  // create business
  onClickNextpage() {
    this.loading = true;
    setTimeout(() => {
      window.open(
        `https://${this.bucket}.gappbiz.com/login?email=${this.ownerEmail}`
      ),
        (this.loading = false);
    }, 500);

    // เก็บไว้แป๊บนึงเผื่อเอากลับมาใช้
    // this.openBusinessService
    //   .createBusiness({
    //     businessCategoryId: '',
    //     businessSize: 'none',
    //     businessType: [],
    //     businessWebsite: '',
    //     interest: [],
    //   })
    //   .subscribe(
    //     (a) => console.warn('Return data to create business :',a),
    //     (err) => {
    //       this.loading = false;
    //       console.error('log err (send user to login page)  =>', err);
    //       window.open(
    //         `https://${this.bucket}.gappbiz.com/login?email=${this.ownerEmail}`
    //       );
    //     },
    //     () => {
    //       this.loading = false;
    //       console.error('route to gappbiz =>');
    //       window.open(
    //         `https://${this.bucket}.gappbiz.com/login?email=${this.ownerEmail}`
    //       );
    //     }
    //   );
  }
}
