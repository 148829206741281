/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hm4ddqlknb.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiBiz",
            "endpoint": "https://6add5xhotf.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiCommerce",
            "endpoint": "https://3is4b0vr9g.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiDas",
            "endpoint": "https://9g3zd7eo65.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiData",
            "endpoint": "https://9tsnt7tk0h.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiFinance",
            "endpoint": "https://o1a19eud42.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiIam",
            "endpoint": "https://ksr15w703j.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiInventory",
            "endpoint": "https://6m5cqcjikg.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiLog",
            "endpoint": "https://gf46fyozr5.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiOps",
            "endpoint": "https://buekpngihg.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiProcure",
            "endpoint": "https://oa9cjdf534.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiProduct",
            "endpoint": "https://hqidtucyv5.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiPurchase",
            "endpoint": "https://ywc04d43kl.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiRfqMarket",
            "endpoint": "https://ip8hgvagf2.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiService",
            "endpoint": "https://68042vzdt1.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiInventory",
            "endpoint": "https://xleftpcawg.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiMarket",
            "endpoint": "https://n2uu4hkom0.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiPort",
            "endpoint": "https://tdo3s25vu6.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiService",
            "endpoint": "https://ppujvvtbkb.execute-api.ap-southeast-1.amazonaws.com/beta",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://sjm7pr7yfvbnzihtbfg6jiog7m.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_cognito_identity_pool_id": "ap-southeast-1:5de7a1b2-3311-4eff-b038-752bcd0f29ad",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_Gm6EwY8aH",
    "aws_user_pools_web_client_id": "1mgvjee4cghmh092q4ujiu82q3",
    "oauth": {
        "domain": "apigappbizb8f1f480-b8f1f480-beta.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/,https://business.gappbiz.com/",
        "redirectSignOut": "http://localhost:4200/signout/,https://business.gappbiz.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gbz-auth-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-biz-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-commerce-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-aff-conv-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-aff-event-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-item-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-pay-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-utm-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-docs-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-facebook-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-finance-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-iam-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-inventory-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-live-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-order-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-stock-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-ops-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-product-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-rfq-market-beta",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-work-beta",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "apigappbiz3b6c8f79753f442aba587f48a05359fa221301-beta",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
