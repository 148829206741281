enum LoggingLevel {
    INFO,
    WARN,
    DEBUG,
    TRACE,
}

const loggingLevel = LoggingLevel.DEBUG

export function LogMethod(level: LoggingLevel = loggingLevel): Function {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    console.log('target', target) // > class
    console.log('propertyKey', propertyKey) // > method name
    console.log('descriptor', descriptor) // > method descriptor
    // const originalMethod = descriptor.value
    // descriptor.value = function (...args: any[]) {
    //   if (level <= loggingLevel) {
    //     console.log(`LogMethod: ${propertyKey}(${JSON.stringify(args)})`)
    //   }
    //   return originalMethod.apply(this, args)
    // }
    // return descriptor
  }
}

export function LogComponent(level: LoggingLevel = loggingLevel): Function {
  return (target: any) => {
    console.log('Component Name', target.name) // > class
    // console.log('propertyKey', propertyKey) // > undefined
    // console.log('descriptor', descriptor) // > undefined
  }
  // return (target: any) => {
  //   console.log('target', target) // > class
  // }
}
