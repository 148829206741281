import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styles: [],
})
export class EmptyComponent implements OnInit {
  @Input() Title1: string = ''
  @Input() SubTitle: string = ''
  @Input() TxtButton: string = ''
  @Input() LinkButton: string = ''

  constructor() {}

  ngOnInit(): void {}
}
