import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { Auth } from 'aws-amplify'
import { from } from 'rxjs'
import { logServiceL1 } from '../../shared/utils/log.function'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() {
    logServiceL1('TokenService')
  }


  jwtToken() {
    return from(Auth.currentSession()).pipe(
      map((obj) => obj.getAccessToken().getJwtToken())
    )
  }

  tokenOwner() {
    return from(Auth.currentSession()).pipe(
      map((obj) => {
        const token = obj.getAccessToken().getJwtToken()
        console.log('token-in-tokenService', token)
        const groups = obj.getAccessToken().payload['cognito:groups'] as string[]
        const bizName = obj.getIdToken().payload['name']
        const OwnerEmail = obj.getIdToken().payload['email']
        const identityId = obj.getIdToken().payload['custom:identityId']
        const firstName = obj.getIdToken().payload['given_name']
        const lastName = obj.getIdToken().payload['family_name']
        let ownerUsername = ''
        if (groups.includes('tenant0')) {
          ownerUsername = obj.getAccessToken().payload['username']
        }
        const tenantId = groups.filter(g => g !== 'tenant0')[0].split('#')[0]
        const newObj = {
          token: obj.getAccessToken().getJwtToken(),
          ownerUsername, OwnerEmail, bizName, tenantId, identityId, firstName, lastName
        }
        console.log('newObj', newObj)
        return newObj
      })
    )
  }

  tenantId() {
    return from(Auth.currentSession()).pipe(
      map(obj => {
        const groups = obj.getIdToken().payload['cognito:groups'] as string[]
        return groups.filter(g => g !== 'tenant0')[0].split('#')[0]
      })
    )
  }
}
