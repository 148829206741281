import { Injectable, OnInit } from '@angular/core'
import { GappBiz, GeeesyApps } from '@geeesy/type-interfaces'
import { GappBusiness } from '@geeesy/type-interfaces'
import { API } from 'aws-amplify'
import { concat, defer, from } from 'rxjs'
import { pluck, tap } from 'rxjs/operators'

import { logServiceL2 } from '../../shared/utils/log.function'
import { TokenService } from './token.service'

const API_NAME: string = 'gbzApiIam'
const API_PATH: string = '/bc'

@Injectable({
  providedIn: 'root',
})
export class UserStaffService implements OnInit {
  private tenantId = '' //: string

  constructor(private tokenService: TokenService) {}

  ngOnInit() {
    logServiceL2('User-StaffService')
  }

  /**
   * GET -> STAFF identity
   * businessId companyCode userRole username ? staffUsername
   */
  getStaffIdentity(
    companyCode: string,
    userRole: string,
    adminUsername: string,
    staffUsername: string
  ) {
    const getObs = defer(() =>
      from(
        API.get(
          API_NAME,
          `${API_PATH}/user/${this.tenantId}/${companyCode}/${userRole}/${staffUsername}?adminUsername=${adminUsername}`,
          {}
        )
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      getObs
    ).pipe(tap((res) => console.log('[GET] Staff Detail', res)))
  }

  /**
   * Update Staff GeeesyApps.User
   */
  updateStaff(
    staffUpdate: GappBiz.User,
    businessId: any,
    companyCode: any,
    userRole: any,
    adminUsername: any,
    staffUsername: any
  ) {
    console.log('staffUpdate', staffUpdate)
    return from(
      API.put(
        API_NAME,
        `${API_PATH}/user/${businessId}/${companyCode}/${userRole}/${staffUsername}?adminUsername=${adminUsername}`,
        {
          body: {
            ...staffUpdate,
          },
        }
      )
    )
  }
  // gbzApiIam/bc//user/${businessId}/${companyCode}/${userRole}/${staffUsername}?adminUsername=${adminUsername}

  update(staffUpdate: GappBiz.User) {
    return from(
      API.put(API_NAME, `${API_PATH}/user/identity`, {
        body: {
          ...staffUpdate,
        },
      })
    )
  }

  /**
   * NOTE PUT DELETE STAFF
   */
  deleteStaff(businessId: any, companyCode: any, userRole: any, username: any, adminUsername: any) {
    return from(
      API.put(
        API_NAME,
        `${API_PATH}/user/delete/${businessId}/${companyCode}/${userRole}/${username}?adminUsername=${adminUsername}`,
        {}
      )
    ).pipe(tap((res) => console.log('[PUT] Delete Staff', res)))
  }
}
