import {Component, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog'

import {UserAdminService} from '../../../../core/services/user-admin.service'
import {UserStaffService} from '../../../../core/services/user-staff.service'

@Component({
  selector: 'app-dialog-delete-employee',
  templateUrl: './dialog-delete-employee.component.html',
  styles: [],
})
export class DialogDeleteEmployeeComponent implements OnInit {
  createForm!: FormGroup
  userData: any
  userType: any

  constructor(
    protected ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private fb: FormBuilder,
    private userAdminService: UserAdminService,
    private userStaffService: UserStaffService
  ) {}

  ngOnInit(): void {
    this.userData = this.config.data.userData
    this.userType = this.config.data.userData.userRole
    console.warn('log user role =>', this.userType)
    console.warn('log userData =>', this.userData)

    this.createForm = this.fb.group({
      email: ['', [Validators.required]],
      pinNumber: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  checkEmail() {
    console.log('incoming .....')
    const emailControl = this.createForm.get('email')
    const emailConfirmControl = this.userData.contact.emails[0]
    console.log('log emailConfirmControl =>', emailConfirmControl)
    let isReturn: boolean = false

    if (emailControl?.value === emailConfirmControl) {
      isReturn = true
    } else if (emailControl?.value !== emailConfirmControl) {
      isReturn = false
    }
  }

  btnCancelClick() {
    this.ref.close(this.userData)
  }

  submit() {
    let submit = this.createForm.value
    console.log('log submit form =>', submit)
    console.log('log userData =>', this.userData)
    console.warn(this.userType, this.userData.businessId, this.userData.username)
    if (this.userType === 'admin') {
      console.log('---- log admin ----')
      this.userAdminService
        .deleteAdmin(
          this.userData.businessId,
          this.userData.companyCode,
          this.userData.userRole,
          this.userData.username
        )
        .subscribe(
          (res) => console.log(res),
          (err) => {
            console.log(err)
          },
          () => {
            this.ref.close(this.userData)
          }
        )
    } else if (this.userType === 'staff') {
      console.log('---- log staff ----')
      this.userStaffService
        .deleteStaff(
          this.userData.businessId,
          this.userData.companyCode,
          this.userData.userRole,
          this.userData.username,
          this.userData.adminUsername
        )
        .subscribe(
          (res) => console.log(res),
          (err) => {
            console.log(err)
          },
          () => {
            this.ref.close(this.userData)
          }
        )
    }
  }
}
