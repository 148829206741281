import { IOwnerObj } from './auth.interface'

export class UserOwner implements IOwnerObj {
  bizName: string = ''
  email: string = ''
  firstName: string = ''
  identityId: string = ''
  lastName: string = ''
  phone: string = ''
  username: string = ''
}
