<div class="pages flex flex-col gap-4" style="width: 640px">
  <div class="flex items-center">
    <img class="bullet_main" src="../../../../../assets/image/icon_doc.svg" />
    <div class="title-main">เปลี่ยนรหัสผ่าน</div>
  </div>

  <div class="text-center mt-4">คุณแน่ใจไหม ว่าต้องการเปลี่ยนรหัสผ่าน ?</div>
  <div class="flex justify-items-center justify-center gap-2 pt-4">
    <div>
      <button class="btn_blue_small" (click)="btnConfirm()">ยืนยัน</button>
    </div>
    <div>
      <button class="btn_cancel_small" (click)="btnCancel()">ยกเลิก</button>
    </div>
  </div>
</div>
