import { Component, OnInit } from '@angular/core'
import { CompanyService } from '../../../core/services/company.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styles: [],
})
export class ChooseCompanyComponent implements OnInit {
  companyList: any
  select: any
  dirty: boolean = false
  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.getListCompany()
  }

  getListCompany() {
    this.companyService.getAllCompanies().subscribe((res) => {
      console.log('res', res), (this.companyList = res)
    })
  }

  selectCompany(value: any) {
    console.log(value)
    this.select = value
    this.dirty = true
  }
}
