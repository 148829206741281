<div
  class="flex flex-col justify-center items-center min-h-screen"
  style="background-color: #f4f6fa"
>
  <div class="flex flex-col gap-4">
    <div class="pageAccount">
      <div class="">
        <div class="flex justify-center bg-white rounded-xl shadow-md">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="col-span-1 p-4">
              <div class="flex justify-center">
                <img
                  class="w-80 py-2"
                  src="../../../../assets/image/gapp-img/new_logo_main.png"
                  alt="Gapp_bc_logo"
                />
              </div>

              <form
                (ngSubmit)="login(loginForm)"
                [formGroup]="loginForm"
                class="space-y-4 px-5"
                novalidate
              >
                <ng-container *ngIf="showError == true">
                  <div
                    id="dialogerror"
                    class="mt-2 rounded-lg flex items-center bg-red-500 border-2 border-red-500 p-2 text-base text-white"
                  >
                    <img
                      class="w-8 mr-3 my-auto"
                      src="../../../../assets/image/Icon feather-alert-triangle.svg"
                      alt=""
                    />
                    ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ อีเมล, รหัสผ่าน หรือ
                    รหัสธุรกิจให้ถูกต้อง
                  </div>
                </ng-container>
                <!-- NOTE email -->
                <div class="relative text-lg">
                  <label class="text-txt-title"> อีเมล </label>
                  <input
                    id="email"
                    type="text"
                    autocomplete="email"
                    class="border border-gray-300 rounded-md w-full h-10 px-11 text-xl text-gray-400 placeholder-gray-300 inputBorderFocus"
                    placeholder="E-mail"
                    formControlName="email"
                    autofocus
                    [ngClass]="{
                      ' border-1 border-red-400':
                        (loginForm.get('email')!.touched ||
                          loginForm.get('email')!.dirty) &&
                        !loginForm.get('email')!.valid
                    }"
                  />
                  <!-- <div class="space-y-2">
                    <ng-container class="absolute" *ngIf="
                            loginForm.get('email').invalid &&
                            (loginForm.get('email').dirty || loginForm.get('email').touched)
                          ">
                      <div class="absolute flex">
                        <p class="text-sm text-red-400" *ngIf="loginForm.get('email').errors?.required">
                          กรุณากรอกอีเมล
                        </p>
                      </div>
                    </ng-container>
                  </div> -->
                  <span>
                    <img
                      src="../../../../assets/image/login_icon/icon_email.svg"
                      class="absolute mt-2 fill-current dark:text-white text-gray-600 cursor-pointer opacity-50 top-7 left-3 w-6 h-6"
                    />
                  </span>
                </div>
                <!-- NOTE pass -->
                <div class="relative text-lg">
                  <label class="text-txt-title">รหัสผ่าน</label>
                  <div
                    class="flex flex-wrap items-stretch w-full relative focus:ring-btn-second-color focus:border-btn-second-color focus:ring-1"
                  >
                    <input
                      id="inputpassword"
                      type="password"
                      placeholder="Password"
                      formControlName="password"
                      autocomplete="new-password"
                      class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none text-xl inputBorderFocus px-11 h-10 rounded-md border border-gray-300 relative text-gray-400 placeholder-gray-300"
                      [ngClass]="{
                        ' border-1 border-red-400':
                          (loginForm.get('password')!.touched ||
                            loginForm.get('password')!.dirty) &&
                          !loginForm.get('password')!.valid
                      }"
                    />
                    <span>
                      <img
                        src="../../../../assets/image/login_icon/icon_key.svg"
                        class="absolute mt-2 fill-current dark:text-white text-gray-600 cursor-pointer opacity-50 top-0 left-3 w-6 h-6"
                      />
                    </span>
                    <div class="flex -mr-px">
                      <span
                        class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-txt-title text-lg"
                      >
                        <img
                          *ngIf="hide == false"
                          (click)="showpass()"
                          class="cursor-pointer opacity-50 right-3 w-5 h-5"
                          src="../../../../assets/image/view.svg"
                          alt=""
                        />
                        <img
                          *ngIf="hide == true"
                          (click)="showpass()"
                          class="cursor-pointer opacity-50 top-0 right-3 w-5 h-5"
                          src="../../../../assets/image/hide.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <!-- NOTE b-pass -->
                <div class="relative text-lg">
                  <label class="text-txt-title">รหัสธุรกิจ</label>
                  <div class="flex flex-wrap items-stretch w-full relative">
                    <input
                      mask="000000"
                      type="password"
                      id="inputpassword2"
                      formControlName="code"
                      autocomplete="organization"
                      class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 rounded-r-none h-10 inputBorderFocus px-11 rounded-md border border-gray-300 relative text-xl text-gray-400 placeholder-gray-300"
                      placeholder="Business password"
                      [ngClass]="{
                        ' border-1 border-red-400':
                          (loginForm.get('code')!.touched ||
                            loginForm.get('code')!.dirty) &&
                          !loginForm.get('code')!.valid
                      }"
                    />
                    <span>
                      <img
                        src="../../../../assets/image/login_icon/icon-bd.svg"
                        class="absolute mt-2 fill-current dark:text-white text-gray-600 cursor-pointer opacity-50 top-0 left-3 w-6 h-6"
                      />
                    </span>
                    <div class="flex -mr-px">
                      <span
                        class="flex items-center leading-normal bg-grey-lighter rounded-l-none rounded-md border border-gray-300 px-3 border-l-0 whitespace-no-wrap text-txt-title text-lg"
                      >
                        <img
                          *ngIf="hide2 == false"
                          (click)="showpass2()"
                          class="cursor-pointer opacity-50 top-0 right-3 w-5 h-5"
                          src="../../../../assets/image/view.svg"
                          alt=""
                        />
                        <img
                          *ngIf="hide2 == true"
                          (click)="showpass2()"
                          class="cursor-pointer opacity-50 top-0 right-3 w-5 h-5"
                          src="../../../../assets/image/hide.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <!-- NOTE  [ลืมรหัสผ่าน?] ยังไม่ใข้เอาออกก่่อน -->
                <div class="flex justify-end mt-1 2xl:mt-4">
                  <div class="text-base 2xl:text-lg text-right">
                    <a
                      href="#"
                      routerLink="/forgot-password"
                      class="cursor-pointer underline text-gray-400 font-normal"
                    >
                      ลืมรหัสผ่าน?
                    </a>
                    <!-- <a (click)="ShowDialog()" class="cursor-pointer underline text-gray-400 pl-2 font-normal">
                      ลืมรหัสธุรกิจ?
                    </a> -->
                  </div>
                </div>
                <div class="flex flex-col">
                  <!-- <button type="submit" [disabled]="!loginForm.valid || loading == true"
                    class="text-white mt-4 group relative w-full flex justify-center items-center h-10 text-xl rounded-md bg-color-blue-bg btnBorderFocus"
                    [ngStyle]="">
                    ลงชื่อเข้าใช้
                  </button> -->

                  <button
                    type="submit"
                    [disabled]="!loginForm.valid || loading == true"
                    [class]="
                      !loginForm.valid || loading == true
                        ? 'text-white mt-4 group relative w-full flex justify-center items-center h-10 text-xl rounded-md bg-gray-300 btnBorderFocus'
                        : 'text-white mt-4 group relative w-full flex justify-center items-center h-10 text-xl rounded-md bg-color-blue-bg btnBorderFocus'
                    "
                    [ngStyle]=""
                  >
                    ลงชื่อเข้าใช้
                  </button>

                  <div class="grid grid-cols-3">
                    <div
                      class="grid col-span-1 border-b border-gray-300 my-3"
                    ></div>
                    <div
                      class="flex justify-center text-base text-gray-400 mt-3 btnBorderFocus"
                    >
                      ลงชื่อเข้าใช้งาน
                    </div>
                    <div
                      class="grid col-span-1 border-b border-gray-300 my-3"
                    ></div>
                  </div>

                  <div class="flex justify-center mb-2">
                    <div class="text-xl text-right">
                      <a
                        href="#"
                        routerLink="/register"
                        class="cursor-pointer underline font-normal text-color-blue-bg"
                      >
                        สมัครใช้งาน GAPP biz
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-span-1 hidden md:block">
              <div class="flex justify-center items-center h-full w-full">
                <img
                  class="w-full object-cover h-full rounded-l-none rounded-xl"
                  src="https://gapp-media.s3.ap-southeast-1.amazonaws.com/public/icons/web/img-login.webp"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            id="dialog"
            class="fixed hidden duration-300 z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>

              <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
              >

              <div
                class="inline-block align-bottom rounded-lg bg-white dark:bg-gradient-to-r from-gray-900 to-gray-800 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              >
                <div class="px-5">
                  <div class="grid grid-cols-3">
                    <div class="col-span-2">
                      <div class="sm:flex sm:items-start mt-5">
                        <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3
                            class="text-xlleading-6 font-normal text-xl text-white"
                            id="modal-title"
                          >
                            ติดต่อ Gapp Support
                          </h3>
                          <div class="mt-5 mx-2">
                            <div class="flex space-x-6">
                              <p
                                class="dark:text-white text-gray-600 font-normal tracking-widest text-xl"
                              >
                                <svg
                                  class="stroke-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="27"
                                  viewBox="0 0 26 27"
                                >
                                  <g
                                    id="Group_2761"
                                    data-name="Group 2761"
                                    transform="translate(-0.339 0.027)"
                                  >
                                    <g
                                      id="Rectangle_1932"
                                      data-name="Rectangle 1932"
                                      transform="translate(0.339 -0.027)"
                                      fill="#fff"
                                      stroke="#707070"
                                      stroke-width="1"
                                      opacity="0"
                                    >
                                      <rect
                                        width="26"
                                        height="27"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="25"
                                        height="26"
                                        fill="none"
                                      />
                                    </g>
                                    <g
                                      id="Group_2760"
                                      data-name="Group 2760"
                                      transform="translate(3.514 5.464)"
                                    >
                                      <path
                                        id="Icon_material-mail"
                                        data-name="Icon material-mail"
                                        d="M20.553,6H4.95A1.948,1.948,0,0,0,3.01,7.95L3,19.652A1.956,1.956,0,0,0,4.95,21.6h15.6a1.956,1.956,0,0,0,1.95-1.95V7.95A1.956,1.956,0,0,0,20.553,6Zm0,3.9-7.8,4.876L4.95,9.9V7.95l7.8,4.876,7.8-4.876Z"
                                        transform="translate(-3 -6)"
                                        fill="#efefef"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </p>
                              <p
                                class="dark:text-white text-gray-600 font-normal h-10 md:h-12 text-lg clamp-2 md:text-base"
                              >
                                Gapp@email.com
                              </p>
                            </div>

                            <div class="flex space-x-6">
                              <p
                                class="dark:text-white font-normal text-gray-600 tracking-widest text-lg"
                              >
                                <svg
                                  class="stroke-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                >
                                  <g
                                    id="Group_2762"
                                    data-name="Group 2762"
                                    transform="translate(-0.339 -0.268)"
                                  >
                                    <g
                                      id="Rectangle_1931"
                                      data-name="Rectangle 1931"
                                      transform="translate(0.339 0.268)"
                                      fill="#fff"
                                      stroke="#707070"
                                      stroke-width="1"
                                      opacity="0"
                                    >
                                      <rect
                                        width="26"
                                        height="26"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="25"
                                        height="25"
                                        fill="none"
                                      />
                                    </g>
                                    <path
                                      id="Icon_ionic-ios-call"
                                      data-name="Icon ionic-ios-call"
                                      d="M23.305,19.672a16.274,16.274,0,0,0-3.4-2.275c-1.019-.489-1.392-.479-2.113.04-.6.434-.989.837-1.68.686a10.019,10.019,0,0,1-3.374-2.5,9.947,9.947,0,0,1-2.5-3.374c-.146-.7.257-1.079.686-1.68.52-.721.535-1.094.04-2.113a15.952,15.952,0,0,0-2.275-3.4c-.741-.741-.908-.58-1.316-.434a7.491,7.491,0,0,0-1.205.641A3.636,3.636,0,0,0,4.724,6.8c-.287.62-.62,1.775,1.074,4.792a26.737,26.737,0,0,0,4.7,6.269h0l.005.005.005.005h0a26.841,26.841,0,0,0,6.269,4.7c3.016,1.695,4.171,1.362,4.792,1.074A3.574,3.574,0,0,0,23.1,22.194a7.491,7.491,0,0,0,.641-1.205C23.885,20.58,24.052,20.414,23.305,19.672Z"
                                      transform="translate(-0.649 -0.661)"
                                      fill="#efefef"
                                    />
                                  </g>
                                </svg>
                              </p>
                              <p
                                class="dark:text-white text-gray-600 font-normal h-10 md:h-12 text-lg clamp-2 md:text-base"
                              >
                                000-000-0000
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="flex justify-end mt-4">
                        <img
                          src="../../../../assets/image/dang.svg"
                          (click)="HideDialog()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!showError">
      <div class="mt-5">
        <app-login-footer></app-login-footer>
      </div>
    </ng-container>
    <ng-container *ngIf="showError">
      <div class="mt-20">
        <app-login-footer></app-login-footer>
      </div>
    </ng-container>
  </div>
</div>
<style>
  .card {
    background: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
</style>
