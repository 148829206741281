const orange = 'color:black;background-color:orange'
const green = 'color:white;background-color:green'
const red = 'color:white;background-color:red'
const blue = 'color:white;background-color:blue'
const yellow = 'color:black;background-color:yellow'

function logColor(val: any, color: any) {
  console.log(`%c${val}`, color)
}

export const logServiceL1 = (val: any) => logColor(val, orange)
export const logGreen = (val: any) => logColor(val, green)
export const logRed = (val: any) => logColor(val, red)
export const logBlue = (val: any) => logColor(val, blue)
export const logServiceL2 = (val: any) => logColor(val, yellow)
