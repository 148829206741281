import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {faChartPie, faHome, faSignOutAlt,} from '@fortawesome/free-solid-svg-icons'
import {AuthStoreService} from 'src/app/auth/services/auth-store.service'
import {CompanyService} from 'src/app/core/services/company.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [],
})
export class SidebarComponent implements OnInit {
  select: any
  faHome = faHome
  faChartPie = faChartPie
  faSignOutAlt = faSignOutAlt
  chkRoute: boolean = false
  typeParam: any

  comp$ = this._authUser.dataComp$

  constructor(
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    private _compSer: CompanyService,
    private _authUser: AuthStoreService
  ) { }

  ngOnInit(): void {
    // this.router.events.subscribe((route) => {
    //   if (route instanceof NavigationStart) {
    //     console.log('route =>>>>>>>>>>', route.url)
    //     if (route.url === '/app/business') {
    //       this.chkRoute = false
    //     } else if (route.url === '/app/company/create') {
    //       this.chkRoute = false
    //     } else if (route.url.startsWith('/app/profile')) {
    //       this.chkRoute = false
    //     } else if (route.url.startsWith('/app/package')) {
    //       this.chkRoute = false
    //     } else {
    //       this.chkRoute = true
    //     }
    //   }
    // })
  }

  isEmpty(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  setData() {
    this._authUser.setCompState({})
  }
}
