import { Injectable, OnInit } from '@angular/core'
import { GappBiz, GappBusiness } from '@geeesy/type-interfaces'
import { API } from 'aws-amplify'
import { BehaviorSubject, Observable, concat, defer, from } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { logServiceL2 } from '../../shared/utils/log.function'
import { TokenService } from './token.service'

const API_NAME = 'gbzApiIam'
const API_PATH = '/bc'

interface INewUser {
  username?: string
  identityId?: string
  phone?: string
  email?: string
}

interface ICreateStaff {
  phone: string
  name: string
  firstName: string
  lastName: string
  nickname: string
  birthDate: string
  companyCode: string
  adminUsername: string
  personalCode: string
  email: string
  isDefaultReceiver: boolean
  compId: string
  avatarImageUrl: string
  companyPosition: string
  companyDept: string
}

@Injectable({
  providedIn: 'root',
})
export class UserAdminService implements OnInit {
  private tenantId = '' // : string
  private token = '' // : string
  private ownerUsername = '' // : string
  private ownerEmail = '' // : string
  private bizName = '' // : string

  public newStaffB$ = new BehaviorSubject<INewUser>({})

  constructor(private tokenService: TokenService) {}

  private checkOwnerEmail = new BehaviorSubject<any>('')
  checkOwnerEmail$ = this.checkOwnerEmail.asObservable()

  private adminUsername = new BehaviorSubject<any>({})
  adminUsername$ = this.adminUsername.asObservable()

  public thumnailSubject = new BehaviorSubject<[]>([])
  thumnail$ = this.thumnailSubject.asObservable()

  private usernameRegister = new BehaviorSubject<any>({})
  usernameRegister$ = this.adminUsername.asObservable()

  ngOnInit() {
    logServiceL2('User-AdminService')
  }

  getOwnerEmail() {
    this.checkOwnerEmail.next(JSON.stringify(localStorage.getItem('ownweEmail')))
    return this.checkOwnerEmail$
  }

  postOwnerEmail(data: any) {
    console.warn('--- set OwnerEmail ---', data)
    this.adminUsername.next(data)
    localStorage.setItem('ownweEmail', this.adminUsername.getValue())
  }

  getAdminUsername() {
    // this.thumnailSubject.next(JSON.parse(localStorage.getItem('thumnail')))
    // this.adminUsername.next(JSON.parse(localStorage.getItem('adminUsername')))
    return this.adminUsername$
  }

  postAdminUsername(data: any) {
    console.warn('--- set postAdminUsername ---', data)
    this.adminUsername.next(data)
    localStorage.setItem('adminUsername', JSON.stringify(this.adminUsername.getValue()))
  }

  getThumnail() {
    // this.thumnailSubject.next(JSON.parse(localStorage.getItem('thumnail')))
    return this.thumnail$
  }

  postThumnail(data: any) {
    this.thumnailSubject.next(data)
    localStorage.setItem('thumnail', JSON.stringify(this.thumnailSubject.getValue()))
    // console.log('postThumnail Service', JSON.parse(localStorage.getItem('thumnail')))
  }

  // get getProductForm() {
  //   return JSON.parse(localStorage.getItem('catalogProductForm'))
  // }
  /**
   * POST -> Create STAFF
   */
  createStaff({
    phone,
    name,
    firstName,
    lastName,
    nickname,
    birthDate,
    companyCode,
    adminUsername,
    personalCode,
    email,
    compId,
    avatarImageUrl,
    companyPosition,
    companyDept,
  }: ICreateStaff): Observable<any> {
    const adminCreateStaffUserObs = defer(() =>
      from(
        API.post('AdminQueries', '/adminCreateStaffUser', {
          body: {
            email: email,
            username: email,
            name: this.bizName,
            given_name: name,
            userrole: 'staff',
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    ).pipe(
      tap((res) => {
        const newStaffUsername = res.data.User.Username
        const attributes = res.data.User.Attributes as []
        const newAtt: any = attributes.find((p: any) => p.Name === 'custom:identityId')
        const emailAtt: any = attributes.find((p: any) => p.Name === 'email')
        const phoneAtt: any = attributes.find((p: any) => p.Name === 'phone_number')
        this.newStaffB$.next({
          username: newStaffUsername,
          identityId: newAtt.Value,
          phone: emailAtt.Value,
          email: emailAtt.Value,
        })
        console.log('create staff in company', res)
      })
    )

    // * STAFF User -> Tenant Member
    const addUserToMemberGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: 'tenant1',
            username: this.newStaffB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addUserToBizGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: this.tenantId,
            username: this.newStaffB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addUserToCompGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: `${this.tenantId}#${companyCode}`,
            username: this.newStaffB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const createBusinessUserStaffObs = defer(() => {
      const userCreateObj: GappBusiness.CreateCompanyUser = {
        adminUsername,
        compId,
        companyCode,
        email,
        personalCode,
        hasArchived: false,
        companyDept: companyDept,
        nickname: nickname,
        firstName: firstName,
        lastName: lastName,
        gender: 'neutral',
        birthDate: birthDate,
        companyPosition: companyPosition,
        isKeyContact: false,
        avatarImageUrl: avatarImageUrl,
        businessDept: '',
        isDefaultReceiver: false,
        businessId: this.tenantId,
        fullName: name,
        identityId: this.newStaffB$.getValue().identityId as string,
        mobile: this.newStaffB$.getValue().phone as string,
        penName: name,
        userRole: 'staff',
        username: this.newStaffB$.getValue().username as string,
        businessPosition: '',
        approverRole: 'none',
        approvalFlowRules: {
          rfqTypeR: false,
          quotationTypeM: false,
          quotationTypeR: false,
          quotationTypeD: false,
          poTypeR: false,
          poTypeS: false,
          poTypeD: false,
          soTypeR: false,
          soTypeS: false,
          soTypeD: false,
        },
        contact: {
          firstName: '',
          lastName: '',
          prefixName: '',
          tags: [''],
          personPic: '',
          fullName: '',
          emails: [email],
          // mobiles: [this.newStaffB$.getValue().phone as string],
          mobiles: [phone],
          social: {
            line: '',
            facebook: '',
          },
          taxNo:'',
          address: {
            taxInvoiceName:'',
            addrName: '',
            addrFull: '',
            addrNumber: '',
            province: '',
            amphoe: '',
            tambon: '',
            postcode: '',
            phones: [''],
            mobiles: [this.newStaffB$.getValue().phone as string],
            faxes: [''],
            emails: [''],
            country: '',
            lat: '',
            lng: '',
            officeHours: [
              {
                day: '',
                times: ['', ''],
              },
            ],
          },
          shippingAddress: [
            {
              taxInvoiceName:'',
              addrName: '',
              addrFull: '',
              addrNumber: '',
              province: '',
              amphoe: '',
              tambon: '',
              postcode: '',
              phones: [],
              mobiles: [],
              faxes: [],
              emails: [],
              country: '',
              lat: '',
              lng: '',
              officeHours: [
                {
                  day: '',
                  times: ['', ''],
                },
              ],
            },
          ],
          note: '',
        },
        roleId: '',
      }
      console.log('userCreateObj', userCreateObj)
      return from(
        API.post(API_NAME, `${API_PATH}/user/admin/staff`, {
          body: {
            ...userCreateObj,
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.ownerUsername = obj.ownerUsername
          this.bizName = obj.bizName
          this.tenantId = obj.tenantId
          this.ownerEmail = obj.OwnerEmail
        })
      ),
      adminCreateStaffUserObs,
      addUserToMemberGroupObs,
      addUserToBizGroupObs,
      addUserToCompGroupObs,
      createBusinessUserStaffObs
    ).pipe(tap((res) => console.log('---concat----', res)))
  }

  /**
   * GET -> List all STAFF
   */
  getAllStaffs(companyCode: string, adminUsername: string) {
    const listAdminStaffsObs = defer(() =>
      from(
        API.get(
          API_NAME,
          `${API_PATH}/user/admin/staff?businessId=${this.tenantId}&companyCode=${companyCode}&adminUsername=${adminUsername}`,
          {
            // queryStringParameters: {
            //   companyCode: companyCode,
            //   adminUsername: adminUsername,
            // },
          }
        )
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listAdminStaffsObs
    ).pipe(tap((res) => console.log('[GET Staff List]', res)))
  }

  /**
   * GET -> ADMIN Identity
   */
  getAdminIdentity(companyCode: any, userRole: any, adminUsername: string) {
    const getObs = defer(() =>
      from(
        API.get(
          API_NAME,
          `${API_PATH}/user/${this.tenantId}/${companyCode}/${userRole}/${adminUsername}`,
          {}
        )
      )
    )

    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      getObs
    )
  }

  /**
   * UPDATE GeeesyApps.User
   */
  adminUpdate(adminUpdate: GappBiz.User, businessId: any, companyCode: any, userRole: any, username: any) {
    return from(
      API.put(
        API_NAME,
        `${API_PATH}/user/${businessId}/${companyCode}/${userRole}/${username}`,
        {
          body: {
            ...adminUpdate,
          },
        }
      )
    )
  }

  /**
   * UPDATE GappBusiness.User
   */
  putUpdateAdmin(updateAdmin: GappBusiness.User, businessId: any, username: any) {
    return from(
      API.put(API_NAME, `${API_PATH}/admin/identity/${businessId}/${username}`, {
        body: {
          ...updateAdmin,
        },
      })
    )
  }

  /**
   * GET AFTER UPDATE GappBusiness.User
   */
  getUpdateAdmin(businessId: any, username: any) {
    return from(
      API.get(API_NAME, `${API_PATH}/admin/identity/${businessId}/${username}`, {})
    )
  }

  getOwnerDetail(businessId: any, identityId: any): Observable<GappBusiness.Owner> {
    return from(
      API.get(API_NAME, `${API_PATH}/owner/identity/${businessId}/${identityId}`, {})
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET] Owner Detail => ', res))
    )
  }

  updateOwnerDetail(Owner: GappBusiness.Owner, businessId: string, identityId: string) {
    return from(
      API.put(API_NAME, `${API_PATH}/owner/identity/${businessId}/${identityId}`, {
        body: { ...Owner },
      })
    )
  }

  /**
   * NOTE PUT DELETE ADMIN
   */
  deleteAdmin(businessId: any, companyCode: any, userRole: any, username: any) {
    return from(
      API.put(
        API_NAME,
        `${API_PATH}/user/delete/${businessId}/${companyCode}/${userRole}/${username}`,
        {}
      )
    ).pipe(tap((res) => console.log('[PUT] Delete Admin', res)))
  }
}
