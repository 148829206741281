import { Component, Input, OnInit } from '@angular/core'
import { TitleService } from './../../core/services/title.service'
import { Router } from '@angular/router'
import { StaffSubHeadService } from '../../core/services/staff-sub-head.service'

@Component({
  selector: 'ui-subheader',
  templateUrl: './subheader.component.html',
  styles: [],
})
export class SubheaderComponent implements OnInit {
  // CompanyName: any
  // TitlePage: any
  // StaffTitle: any
  // TitlelLevOne: any
  // TitlelLevTwo: any
  // StaffLevelOne: any
  // businessId: any
  // companyCode: any
  // compId: any
  // haveDefaultReceiver: any
  // username: any
  // personalCode: any
  // Link: any
  // adminUsername: any
  // staffUsername: any
  // userRole: any
  // MainTitle: any
  // Comtitle: any

  constructor(
    private title: TitleService,
    private route: Router,
    private staff: StaffSubHeadService
  ) {}

  ngOnInit(): void {
    // this.getTitle()
    // this.getCompanyName()
    // this.getTitlelLev1()
    // this.getTitlelLev2()
    // this.getbusinessId()
    // this.getcompanyCode()
    // this.getcomId()
    // this.gethaveDefaultReceiver()
    // this.getusername()
    // this.getpersonalCode()
    // this.getcompanyCode()
    // this.getadminUsername()
    // this.getstaffUsername()
    // this.getuserRole()
    // this.getMainTitle()
    // this.getStaffTitle()
    // this.getComTitle()
    // console.log('dsfsfsdfsd', this.username, this.personalCode)
  }

  // getbusinessId() {
  //   this.title.getbusinessId().subscribe((a) => (this.businessId = a))
  // }
  // getcompanyCode() {
  //   this.title.getcompanyCode().subscribe((a) => (this.companyCode = a))
  // }
  // getcomId() {
  //   this.title.getcomId().subscribe((a) => (this.compId = a))
  // }
  // gethaveDefaultReceiver() {
  //   this.title.gethaveDefaultReceiver().subscribe((a) => (this.haveDefaultReceiver = a))
  // }
  // getTitle() {
  //   this.title.getTitle().subscribe((a) => (this.TitlePage = a))
  // }
  // getusername() {
  //   this.title.getusername().subscribe((a) => (this.username = a))
  // }
  // getpersonalCode() {
  //   this.title.getpersonalCode().subscribe((a) => (this.personalCode = a))
  // }

  // getadminUsername() {
  //   this.title.getadminUsername().subscribe((a) => (this.adminUsername = a))
  // }

  // getstaffUsername() {
  //   this.title.getstaffUsername().subscribe((a) => (this.staffUsername = a))
  // }

  // getCompanyName() {
  //   this.title.getCompanyName().subscribe((res) => (this.CompanyName = res))
  // }

  // getuserRole() {
  //   this.title.getuserRole().subscribe((res) => (this.userRole = res))
  // }

  // getTitlelLev1() {
  //   this.title.getTitlelLevOne().subscribe((res) => (this.TitlelLevOne = res))
  // }

  // getTitlelLev2() {
  //   this.title.getTitlelLevtwo().subscribe((res) => (this.TitlelLevTwo = res))
  // }

  // getStaffTitle() {
  //   this.title.getStaffTitle().subscribe((a) => (this.StaffTitle = a))
  // }
  // getMainTitle() {
  //   this.title.getMainTitle().subscribe((a) => (this.MainTitle = a))
  // }

  // getComTitle() {
  //   this.title.getComTitle().subscribe((a) => (this.Comtitle = a))
  // }
}
function getCompanyName() {
  throw new Error('Function not implemented.')
}
