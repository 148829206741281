import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  faCheck,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { AuthService } from './../../../core/services/auth.service';
import { Router } from '@angular/router';
import { UserAdminService } from '../../../core/services/user-admin.service';
import { LogComponent } from 'src/app/shared/utils/log.decorator';

function passwordMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const passwordControl: any = c.get('password');
  const confirmControl: any = c.get('confirmpassword');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { match: true };
}

function pinNumberMatcher(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const pinNumberControl: any = c.get('pinNumber');
  const confirmPinNumberControl: any = c.get('confirmPinNumber');

  if (pinNumberControl.pristine || confirmPinNumberControl.pristine) {
    return null;
  }

  if (pinNumberControl.value === confirmPinNumberControl.value) {
    return null;
  }
  return { match: true };
}

@LogComponent()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  hide: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;
  hide4: boolean = true;
  hide5: boolean = true;
  showValidatePhone: boolean = false;
  showValidatePassword: boolean = false;
  showValidatePinNumber: boolean = false;
  showValidateEmail: boolean = false;
  texterror: boolean = false;
  regexStr = '^[\u0E00-\u0E7Fa-zA-Z0-9]*$';
  regexSS = '^[a-zA-Z0-9]*$';
  validateLang: boolean = false;
  errMessage: string | undefined;
  faQuestionCircle = faQuestionCircle;
  loading: boolean = false;

  @Input() control: FormControl = new FormControl('');
  registerForm!: FormGroup;

  pinForm!: FormGroup;

  showDialog: boolean = false;
  faTimes = faTimes;
  faCheck = faCheck;

  dialogConfirm: boolean = false;

  pinlength = 6;
  codeOtp = '';
  pinlengthConfirm = 6;
  codeOtpConfirm = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private el: ElementRef,
    private router: Router,
    private UserAdminService: UserAdminService
  ) {}

  textlist = [
    {
      header: 'Affiliate',
      text: 'ระบบ Affiliate ฟีเจอร์ทำเงินแบบใหม่ ที่ช่วยให้ทุกคนหารายได้ง่ายขึ้น แค่แนะนำสินค้าหรือบริการก็ทำเงินได้ง่าย ๆ',
      icon: 'assets/image/icon_bc.png',
    },
    {
      header: 'Product Catalog',
      text: 'สร้างแคตตาล็อกสินค้าที่ตอบโจทย์ สวยงาม ใช้งานง่าย ช่วยให้การนำเสนอสินค้านับร้อยเป็นไปอย่างราบรื่น',
      icon: 'assets/image/icon_bc.png',
    },
    {
      header: 'Sale Page',
      text: 'กระตุ้นยอดขาย สร้างกำไร ด้วยฟีเจอร์ Sale Page โปรโมทสินค้าและบริการได้อย่างมีประสิทธิภาพ ใช้ต้นทุนน้อยแต่สร้างกำไรเยอะ',
      icon: 'assets/image/icon_bc.png',
    },
    {
      header: 'ระบบจัดการเอกสาร',
      text: 'จัดการเอกสารหลังบ้านที่แสนยุ่งยากให้เป็นระเบียบเพียงแค่ไม่กี่คลิก ด้วยระบบการจัดการเอกสารจาก GAPP biz ให้ธุรกิจเดินต่อไม่มีสะดุด',
      icon: 'assets/image/icon_bc.png',
    },
  ];

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.registerForm = this.fb.group({
      firstname: ['', [Validators.required]],
      businessname: [''],
      // aliasname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^0{1}[\\d]{8,9}$')]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      passwordGroup: this.fb.group(
        {
          password: ['', [Validators.required, Validators.minLength(8)]],
          confirmpassword: ['', [Validators.required]],
        },
        { validator: passwordMatcher }
      ),
      // confirmSpecification: false,
      confirmPolicy: false,
      confirmGetNewsAndPromptions: false,
    });

    this.pinForm = this.fb.group({
      pinNumberGroup: this.fb.group(
        {
          pinNumber: ['', [Validators.required, Validators.minLength(6)]],
          confirmPinNumber: [
            '',
            [Validators.required, Validators.minLength(6)],
          ],
        },
        { validator: pinNumberMatcher }
      ),
    });
  }

  showErrorMobileNumber(control: any) {
    const reg = new RegExp(/^0{1}[\d]{9}$/g);
    let valuePhoneMobile = this.registerForm.get(control)!.value;
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile);
    } else {
      return false;
    }
  }

  modelChangeFn(event: any) {
    var phone = document.getElementById('phone') as HTMLInputElement;
    console.log('phone', phone.value);
    this.texterror = false;
  }

  save() {
    this.loading = true;
    console.log('---- registerForm ------- ', this.registerForm.getRawValue());
    this.UserAdminService.postOwnerEmail(this.registerForm.get('email')!.value);
    this.authService
      .signUp({
        name: this.registerForm.get('firstname')!.value,
        email: this.registerForm.get('email')!.value,
        password: this.registerForm.get('passwordGroup.password')!.value,
        alias: '000000',
        phone_number: this.registerForm.get('phone')!.value,
        firstName: this.registerForm.get('firstname')!.value,
        lastName: this.registerForm.get('lastname')!.value,
      })
      .subscribe(
        (a) => console.log(a),
        (eer) => {
          console.log(eer.message);
          if (
            eer.message === 'An account with the given email already exists.'
          ) {
            (this.texterror = true),
              (this.loading = false),
              (this.errMessage =
                'อีเมลนี้มีการลงทะเบียนไว้แล้ว กรุณาใช้อีเมลอื่น');
            console.log('errMessage', this.errMessage);
          }
        },
        () => {
          this.authService.confirmPolicy(
            this.registerForm.get('email')?.value,
            this.registerForm.get('confirmGetNewsAndPromptions')?.value
          );
          this.router.navigateByUrl('/check-mail');
        }
      );
  }

  showpass() {
    let txtpwd: any = document.getElementById('inputpassword');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide = false;
    } else {
      txtpwd.type = 'password';
      this.hide = true;
    }
  }

  showpass2() {
    let txtpwd: any = document.getElementById('inputpassword2');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide2 = false;
    } else {
      txtpwd.type = 'password';
      this.hide2 = true;
    }
  }

  showpass3() {
    let txtpwd: any = document.getElementById('aliasname');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide3 = true;
    } else {
      txtpwd.type = 'password';
      this.hide3 = false;
    }
  }

  showPinNumber() {
    let txtpwd: any = document.getElementById('inputPinNumber');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide4 = true;
    } else {
      txtpwd.type = 'password';
      this.hide4 = false;
    }
  }

  showPinNumber2() {
    let txtpwd: any = document.getElementById('inputPinNumber2');
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text';
      this.hide5 = true;
    } else {
      txtpwd.type = 'password';
      this.hide5 = false;
    }
  }

  checkspace(event: any) {
    if (event.keyCode == 32) {
      return false;
    } else {
      return true;
    }
  }

  engOnly(event: any) {
    var english = /^[A-Za-z0-9]+$/;
    var currectLang = english.test(event.key);
    if (!currectLang) {
      this.validateLang = true;
      return false;
    } else {
      this.validateLang = false;
      return true;
    }
  }

  validatepassNumber(control: any) {
    const reg = new RegExp(/^(?=.*[0-9])[a-zA-Z0-9]+$/g);
    let valuePhoneMobile = this.registerForm.get(control)!.value;
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile);
    } else {
      return false;
    }
  }

  validatepassChar(control: any) {
    const reg = new RegExp(/^(?=.*[a-z])[a-zA-Z0-9]+$/g);
    let valuePhoneMobile = this.registerForm.get(control)!.value;
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile);
    } else {
      return false;
    }
  }

  validatepassCharUpper(control: any) {
    const reg = new RegExp(/^(?=.*[A-Z])[a-zA-Z0-9]+$/g);
    let valuePhoneMobile = this.registerForm.get(control)!.value;
    if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
      return !reg.test(valuePhoneMobile);
    } else {
      return false;
    }
  }

  validateFields(event: any) {
    return new RegExp(this.regexStr).test(event.key);
  }

  validatePhone() {
    this.showValidatePhone = true;
  }

  validatePassword() {
    this.showValidatePassword = true;
  }
  validatePinNumber() {
    this.showValidatePinNumber = true;
  }
  validateEmail() {
    this.showValidateEmail = true;
    this.texterror = false; // if user change email give texterorr is false for validate again
  }

  openDialog() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;

    this.dialogConfirm = false;
  }

  patchPinToAliasName(e: any) {
    console.log('pinNumber ==>', e);
    this.registerForm.get('aliasname')?.patchValue(e);

    this.dialogConfirm = true;
  }

  resetValue(i: number) {
    for (let x = 0; x < this.pinlength; x++) {
      const refElement = document.getElementById(`codefield_${x}`) as any;
      if (x >= i) refElement.value = '';
    }
  }

  stepForward(i: number) {
    const refElement = document.getElementById(`codefield_${i}`) as any;
    const refElementNextStep = document.getElementById(
      `codefield_${i + 1}`
    ) as any;
    if (refElement.value && i != this.pinlength - 1) {
      refElementNextStep.focus();
      refElementNextStep.value = '';
    }
    this.checkPin();
  }

  stepBack(i: number) {
    const refElementPreStep = document.getElementById(
      `codefield_${i - 1}`
    ) as any;
    console.log('refElementPreStep.value', refElementPreStep.value);
    if (refElementPreStep.value && i != 0) {
      refElementPreStep.focus();
      refElementPreStep.value = '';
    }
  }

  checkPin() {
    let code = '';
    for (let i = 0; i < this.pinlength; i++) {
      const refElement = document.getElementById(`codefield_${i}`) as any;
      code = code + refElement.value;
    }
    if (code.length == this.pinlength) {
      this.codeOtp = code;
      this.pinForm.get('pinNumberGroup.pinNumber')?.patchValue(this.codeOtp);
    }
  }

  convertNumberToArray(number: any) {
    return new Array(number);
  }

  resetValueConfirm(i: number) {
    console.log('1 ==>');
    for (let x = 0; x < this.pinlengthConfirm; x++) {
      const refElement = document.getElementById(`codeconfirm_${x}`) as any;
      if (x >= i) refElement.value = '';
    }
  }

  stepForwardConfirm(i: number) {
    const refElement = document.getElementById(`codeconfirm_${i}`) as any;
    const refElementNextStep = document.getElementById(
      `codeconfirm_${i + 1}`
    ) as any;
    if (refElement.value && i != this.pinlengthConfirm - 1) {
      refElementNextStep.focus();
      refElementNextStep.value = '';
    }
    this.checkPinConfirm();
  }

  stepBackConfirm(i: number) {
    const refElementPreStep = document.getElementById(
      `codeconfirm_${i - 1}`
    ) as any;
    console.log('refElementPreStep.value', refElementPreStep.value);
    if (refElementPreStep.value && i != 0) {
      refElementPreStep.focus();
      refElementPreStep.value = '';
    }
  }

  checkPinConfirm() {
    let code = '';
    for (let i = 0; i < this.pinlengthConfirm; i++) {
      const refElement = document.getElementById(`codeconfirm_${i}`) as any;
      code = code + refElement.value;
    }
    if (code.length == this.pinlengthConfirm) {
      this.codeOtpConfirm = code;
      this.pinForm
        .get('pinNumberGroup.confirmPinNumber')
        ?.patchValue(this.codeOtpConfirm);
    }
  }

  convertNumberToArrayConfirm(number: any) {
    return new Array(number);
  }
}
