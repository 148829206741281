import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dialog-gapp-list',
  templateUrl: './dialog-gapp-list.component.html',
  styles: [],
})
export class DialogGappListComponent implements OnInit {
  gappList = [
    {
      gappName: 'GAPP biz',
      gappLogo: 'assets/image/Gapp_icons/Group_biz.svg',
      gappLinkUrl: 'https://dev.gappbiz.com/app',
      gappStatus: true,
    },
    {
      gappName: 'GAPP med',
      gappLogo: 'assets/image/Gapp_icons/Group_med.svg',
      gappLinkUrl: 'https://dev.gappmed.com/marketplace-clinic',
      gappStatus: true,
    },
  ]
  constructor() {}

  ngOnInit(): void {}
}
