<div class="flex flex-col items-center justify-center h-screen bg-mainBackground-500">
  <div class="w-full flex items-center justify-center">
    <div class="pageAccountHalf">
      <div class="p-5 md:px-8 md:py-6 bg-white rounded-xl shadow-xl mb-4">
        <div class="grid grid-cols-1 gap-8">
          <div class="col-span-1">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <button routerLink="/login" class="hover:underline hover:text-green-island-500">
                  << ย้อนกลับ </button>
                    <img class="h-8" src="assets/image/gapp-img/new_logo_main.png" />
              </div>

              <div class="flex justify-center my-6">
                <img src="assets/image/dash_logo_reset.svg" class="w-16 h-16 object-contain" alt="" />
              </div>

              <p class="font-extrabold text-center text-gray-700" style="font-size: 27px">
                เปลี่ยนรหัสผ่านใหม่
              </p>

              <p class="mt-1 text-center text-gray-700 text-xl">กรุณากรอกรหัสผ่านใหม่</p>
            </div>

            <form class="" [formGroup]="form" (keyup.enter)="submit()">
              <input type="hidden" name="remember" value="true" />
              <div class="rounded-md shadow-sm">
                <div class="mt-8">
                  <label for="newPassword" class="sr-only">รหัสผ่านใหม่</label>
                  <div class="relative rounded-md">
                    <input id="newPassword" name="password" [type]="changeType" formControlName="password"
                      class="shadow-sm appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-island-500 focus:border-green-island-500 focus:z-10 text-xl"
                      placeholder="รหัสผ่านใหม่" autofocus />

                    <div class="absolute inset-y-0 right-3 flex items-center z-10 pr-4">
                      <button>
                        <fa-icon [icon]="changeType === 'text' ? faEye : faEyeSlash"
                          class="focus:outline-none focus:ring-0" (click)="seePass()"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="h-auto flex items-start flex-col py-1">
                  <div class="grid grid-cols-1 gap-1 items-center mt-0.5">
                    <p class="flex flex-row items-center text-lg text-gray-500" *ngIf="
                        form.get('password')?.hasError('required') ||
                          form.get('password')?.hasError('minlength');
                        else noNewPassword2
                      ">
                      <img src="assets/image/taoo.svg" class="w-4 h-4 mr-1" />กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว
                    </p>
                    <ng-template #noNewPassword2>
                      <p class="flex flex-row items-center text-lg text-green-500">
                        <img src="assets/image/cheak_bc.svg" class="h-4 mr-1" />
                        กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว
                      </p>
                    </ng-template>
                    <p class="flex flex-row items-center text-lg text-gray-500" *ngIf="
                        form.get('password')?.hasError('required') ||
                          validatepassNumber('password');
                        else noNewPassword3
                      ">
                      <img src="assets/image/taoo.svg" class="w-4 h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวเลข
                    </p>
                    <ng-template #noNewPassword3>
                      <p class="flex flex-row items-center text-lg text-green-500">
                        <img src="assets/image/cheak_bc.svg" class="h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวเลข
                      </p>
                    </ng-template>

                    <p class="flex flex-row items-center text-lg text-gray-500" *ngIf="
                        form.get('password')?.hasError('required') ||
                          validatepassCharUpper('password');
                        else noNewPassword4
                      ">
                      <img src="assets/image/taoo.svg"
                        class="w-4 h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์ใหญ่
                    </p>
                    <ng-template #noNewPassword4>
                      <p class="flex flex-row items-center text-lg text-green-500">
                        <img src="assets/image/cheak_bc.svg"
                          class="h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์ใหญ่
                      </p>
                    </ng-template>

                    <p class="flex flex-row items-center text-lg text-gray-500" *ngIf="
                        form.get('password')?.hasError('required') ||
                          validatepassChar('password');
                        else noNewPassword5
                      ">
                      <img src="assets/image/taoo.svg"
                        class="w-4 h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์เล็ก
                    </p>
                    <ng-template #noNewPassword5>
                      <p class="flex flex-row items-center text-lg text-green-500">
                        <img src="assets/image/cheak_bc.svg"
                          class="h-4 mr-1" />กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษพิมพ์เล็ก
                      </p>
                    </ng-template>
                  </div>
                </div>

                <div class="mt-5">
                  <label for="seePassword" class="sr-only text-xl">ยืนยันรหัสผ่านใหม่</label>
                  <div class="relative rounded-md">
                    <input id="seePassword" name="confirmPassword" [type]="changeTypeConfirm"
                      formControlName="confirmPassword"
                      class="shadow-sm appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-island-500 focus:border-green-island-500 focus:z-10 text-xl"
                      placeholder="ยืนยันรหัสผ่านใหม่" />

                    <div class="absolute inset-y-0 right-3 flex items-center z-10 pr-4">
                      <button>
                        <fa-icon [icon]="changeTypeConfirm === 'text' ? faEye : faEyeSlash"
                          class="focus:outline-none focus:ring-0" (click)="seePassConfirm()"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="h-6 flex items-start mt-0.5 py-1">
                <span *ngIf="!confirmPassword?.errors?.['compare'] && confirmPassword?.dirty"
                  class="text-lg text-green-500 text-center flex items-center justify-center flex-row gap-1">
                  <img src="assets/image/cheak_bc.svg" class="w-4 h-4 mr-1" />รหัสผ่านตรงกัน</span>
                <span *ngIf="confirmPassword?.errors?.['compare'] || !confirmPassword?.dirty"
                  class="text-lg text-gray-500 text-center items-center flex justify-center flex-row gap-1"><img
                    class="w-4 h-4 mr-1" src="assets/image/taoo.svg" />กรุณากรอกรหัสผ่านให้ตรงกัน
                </span>
              </div>

              <div>
                <button (click)="submit()" [class]="
                    !confirmPassword?.errors?.['compare'] && confirmPassword?.dirty
                      ? 'bg-green-island-500'
                      : 'bg-gray-300 cursor-not-allowed'
                  " type="submit"
                  class="mt-5 group relative w-full flex justify-center h-12 items-center border border-transparent text-lg font-medium rounded-md text-white focus:ring-2 focus:ring-offset-2 focus:ring-orange-second-color-500">
                  ตั้งรหัสผ่านใหม่
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mt-10">
    <app-login-footer></app-login-footer>
  </div> -->
</div>
