<ng-container *ngIf="userData">
  <div class="p-2" style="width: 695px; height: 495px">
    <div class="flex justify-end gap-2">
      <div class="w-10 h-10 bg-gray-100 rounded-full" (click)="btnCancelClick()">
        <p class="text-center mt-2">X</p>
      </div>
    </div>

    <div class="flex items-center gap-2">
      <div class="">
        <div class="h-10 bg-slate-200"></div>
      </div>
      <p class="text-deep-blue-sea mb-0 text-left text-2xl">
        การลบพนักงาน - <span class="capitalize">{{ userData.userRole }}</span>
      </p>
    </div>

    <div>
      <p class="text-base">
        ฟรังก์ฟีด บอยคอตสโลว์ ไทม์ ตังค์เย้วเอ๋อมหาอุปราชาสี่แยก มาร์เก็ตติ้งชนะเลิศ
        หมิงแยมโรลแครอทเซี้ยว แบล็ควานิลลามาร์จินโมเดิร์น
      </p>
    </div>

    <ng-container>
      <div class="bg-white rounded-md border border-gray-300 mb-4 my-4">
        <div class="flex flex-row items-center justify-between px-4 py-2 min-w">
          <div class="flex flex-row items-center w-full gap-4">
            <div class="w-10 text-left">{{userData.personalCode || '-'}}</div>

            <div class="min-w-20 text-center">
              <ng-container *ngIf="userData.avatarThumbUrl; else noImg">
                <app-path-image [s3Key]="userData.avatarThumbUrl" width="max-w-12 min-w-12" height="max-h-12 min-h-12">
                </app-path-image>
              </ng-container>
              <ng-template #noImg>
                <img class="rounded-full max-w-14 min-w-14 max-h-14 min-h-14 object-cover mx-auto"
                  src="../../../../assets/image/empty.svg" alt="" />
              </ng-template>
            </div>

            <div class="min-w-27 text-left">{{userData.fullName || '-'}}</div>
            <div class="min-w-24 text-center">{{userData.contact.emails[0] || '-'}}</div>
            <div class="min-w-24 text-center">{{userData.companyDept || '-' }}</div>
            <div class="min-w-24 text-center">{{userData.companyPosition || '-'}}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <form [formGroup]="createForm">
      <div class="mt-2">
        <div class="text-deep-blue-sea mb-0 text-left text-xl">ยืนยันการลบข้อมูลด้วย</div>

        <div class="flex flex-col gap-2">
          <div class="">
            <label class="text-txt-title text-lg">อีเมล</label>
            <input autocomplete="off" type="text" placeholder="email" formControlName="email"
              class="border border-input-border rounded-md h-10 text-xl text-gray-400 font-normal placeholder-text-placeholder inputBorderFocus w-52" />
          </div>

          <div class="">
            <label class="text-txt-title text-lg">pin</label>
            <input (keyup)="checkEmail()" placeholder="pin" mask="000000" maxlength="6" autocomplete="off" type="text"
              formControlName="pinNumber"
              class="border border-input-border rounded-md h-10 text-xl text-gray-400 font-normal placeholder-text-placeholder inputBorderFocus w-52" />
          </div>
        </div>

        <div class="flex justify-center gap-4 mt-7">
          <button
            class="bg-blue-new-500 text-lg text-white px-5 py-1.5 rounded-md transition duration-500 ease-in hover:bg-opacity-90"
            (click)="btnCancelClick()">
            ยกเลิก
          </button>

          <button class="text-lg py-1 px-6 rounded ml-2 btnBorderFocus cursor-pointer" (click)="submit()" type="submit"
            [disabled]="createForm.invalid || !createForm.dirty" [ngClass]="
                createForm.valid && createForm.dirty ? 'btn_red_smail' : 'btnDisables'
            ">
            ลบรายชื่อ
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
