import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type ItypeName =
  | 'now'
  | 'before'
  | 'threeMonth'
  | 'oneYear'
  | 'nowYear'
  | 'beforeYear'
  | 'custom'
  | 'customMonth'
  | 'sixMonth'
  | 'customYear'

@Component({
  selector: 'input-date-chart',
  templateUrl: './input-date-chart.component.html',
  styles: [
  ]
})
export class InputDateChartComponent implements OnInit {
  @Input() Icon = true
  selectedType: ItypeName = 'now'
  startDate: any = null
  endDate: any = null
  displayModal: boolean = false

  selectArr = [
    { name: 'เดือนปัจจุบัน', value: 'now' },
    { name: 'เดือนก่อนหน้า', value: 'before' },
    // { name: 'เดือน...', value: 'customMonth' },
    // { name: '3 เดือน', value: 'threeMonth' },
    // { name: '6 เดือน', value: 'sixMonth' },
    // { name: '1 ปี', value: 'oneYear' },
    { name: 'ปีปัจจุบัน', value: 'nowYear' },
    { name: 'ปีก่อน', value: 'beforeYear' },
    // { name: 'ปี...', value: 'customYear' },
    { name: 'กำหนดเอง', value: 'custom' },
  ]

  dateArr = [
    {
      label: 'เดือนปัจจุบัน',
      command: () => {
        this.selectedType = 'now'
        this.selectFilter('now');
      }
    },
    {
      label: 'เดือนก่อนหน้า',
      command: () => {
        this.selectedType = 'before'
        this.selectFilter('before');
      }
    },
    // {
    //   label: 'เดือน...',
    //   command: () => {
    //     this.selectedType = 'customMonth'
    //     this.selectFilter('customMonth');
    //     this.showModalDialog()
    //   }
    // },
    // {
    //   label: '3 เดือน',
    //   command: () => {
    //     this.selectedType = 'threeMonth'
    //     this.selectFilter('threeMonth');

    //   }
    // },
    // {
    //   label: '6 เดือน',
    //   command: () => {
    //     this.selectedType = 'sixMonth'
    //     this.selectFilter('sixMonth');
    //   }
    // },
    // {
    //   label: '1 ปี',
    //   command: () => {
    //     this.selectFilter('oneYear');
    //   }
    // },
    {
      label: 'ปีปัจจุบัน',
      command: () => {
        this.selectedType = 'nowYear'
        this.selectFilter('nowYear');
      }
    },
    {
      label: 'ปีก่อน',
      command: () => {
        this.selectedType = 'beforeYear'
        this.selectFilter('beforeYear');
      }
    },
    // {
    //   label: 'ปี...',
    //   command: () => {
    //     this.selectedType = 'customYear'
    //     this.selectFilter('customYear');
    //     this.showModalDialog()
    //   }
    // },
    {
      label: 'กำหนดเอง',
      command: () => {
        this.selectedType = 'custom'
        this.selectFilter('custom');
        this.showModalDialog()
      }
    },
  ]

  @Output() date = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.selectFilter('now')

  }
  selectFilter(day: string) {
    const date: Date = new Date() // วันที่ปัจจุบัน

    if (day === 'now') {
      // ANCHOR: เดือนปัจจุบัน
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //วันที่เริ่มต้นของเดือน
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); //วันที่สิ้นสุดของเดือน
      lastDay.setHours(23, 59, 59, 999)

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    } else if (day === 'before') {
      // ANCHOR: เดือนก่อนหน้า
      date.setDate(1);
      date.setMonth(date.getMonth() - 1) // เดือนปัจจุบัน - 1
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //วันที่เริ่มต้นของเดือน
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); //วันที่สิ้นสุดของเดือน
      lastDay.setHours(23, 59, 59, 999)

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    } else if (day === 'threeMonth') {
      // ANCHOR: 3 เดือน
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 1); //วันที่สิ้นสุดของเดือน

      date.setMonth(date.getMonth() - 2);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //วันที่เริ่มต้นของเดือน
      lastDay.setHours(23, 59, 59, 999)

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    }
    else if (day === 'sixMonth') {
      // ANCHOR: 3 เดือน
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 1); //วันที่สิ้นสุดของเดือน

      date.setMonth(date.getMonth() - 5);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); //วันที่เริ่มต้นของเดือน
      lastDay.setHours(23, 59, 59, 999)

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    }
    // else if (day === 'oneYear') {
    //   // ANCHOR: 1 ปี
    //   const firstDay = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()); //วันที่เริ่มต้นของเดือน
    //   const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()); //วันที่สิ้นสุดของเดือน
    //   lastDay.setHours(23, 59, 59, 999)

    //   this.startDate = firstDay.toISOString()
    //   this.endDate = lastDay.toISOString()
    //   this.emitData()
    // }
    else if (day === 'nowYear') {
      // ANCHOR: ปีปัจจุบัน
      const firstDay = new Date(date.getFullYear(), 0, 1);//วันที่เริ่มต้นของเดือน
      const lastDay = new Date(date.getFullYear(), 11, 31); //วันที่สิ้นสุดของเดือน
      lastDay.setHours(23, 59, 59, 999)

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    } else if (day === 'beforeYear') {
      // ANCHOR: ปีก่อน
      const firstDay = new Date(date.getFullYear() - 1, 0, 1); //วันที่เริ่มต้นของเดือน
      const lastDay = new Date(date.getFullYear(), 0, 0); //วันที่สิ้นสุดของเดือน
      lastDay.setHours(23, 59, 59, 999)
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.startDate = firstDay.toISOString()
      this.endDate = lastDay.toISOString()
      this.emitData()
    } else if (day === 'custom') {
      // ANCHOR: กำหนดเอง
      this.startDate = ''
      this.endDate = ''
    }
    else if (day === 'customMonth') {
      // ANCHOR: กำหนดเอง
      this.startDate = ''
      this.endDate = ''
    }
    else if (day === 'customYear') {
      // ANCHOR: กำหนดเอง
      this.startDate = ''
      this.endDate = ''
    }
  }

  selectDateCustom() {
    const firstDay = new Date(this.startDate);
    const lastDay = new Date(this.endDate);
    lastDay.setHours(23, 59, 59, 999)
    this.date.emit({
      startDate: this.startDate ? firstDay.toISOString() : '',
      endDate: this.endDate ? lastDay.toISOString() : ''
    })

  }

  selectMonthCustom() {
    console.log(event);
    const firstDay = new Date(this.startDate);
    const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0); //วันที่สิ้นสุดของเดือน
    lastDay.setHours(23, 59, 59, 999)
    this.date.emit({
      startDate: firstDay ? firstDay.toISOString() : '',
      endDate: lastDay ? lastDay.toISOString() : ''
    })
  }

  selectYearCustom() {
    console.log(event);
    const firstDay = new Date(this.startDate);
    const lastDay = new Date(firstDay.getFullYear(), 11, 31); //วันที่สิ้นสุดของปี
    lastDay.setHours(23, 59, 59, 999)
    this.date.emit({
      startDate: firstDay ? firstDay.toISOString() : '',
      endDate: lastDay ? lastDay.toISOString() : ''
    })
  }

  convertDate(date: string | number | Date) {
    const newDate = new Date(date)
    console.log(newDate.getDate());
    console.log(newDate.getMonth());
    const monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
      "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤษจิกายน", "ธันวาคม"];
    return `${newDate.getDate()} ${monthNamesThai[newDate.getMonth()]} ${newDate.getFullYear()}`
  }

  clickOk() {
    if (this.selectedType === 'custom') {
      this.selectDateCustom()
    }
    if (this.selectedType === 'customMonth') {
      this.selectMonthCustom()
    }
    if (this.selectedType === 'customYear') {
      this.selectYearCustom()
    }
  }

  showModalDialog() {
    this.displayModal = true;
  }

  emitData() {
    this.date.emit({
      startDate: this.startDate,
      endDate: this.endDate
    })
  }

}
