import { Injectable } from '@angular/core';
import {logGreen, logServiceL1} from "../../shared/utils/log.function";
import {LogComponent} from "../../shared/utils/log.decorator";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() {
    logServiceL1('LoggerService')
  }
  log(message: any) {
    console.log(message);
    logGreen(message)
  }
}
