<ng-container *ngIf="!Icon; else mini">
  <div class="flex gap-4 items-center">
    <p-dropdown [options]="selectArr" [(ngModel)]="selectedType" optionLabel="name" optionValue="value"
      (onChange)="selectFilter($event.value)"></p-dropdown>
    <ng-container [ngSwitch]="selectedType">
      <!-- เลือก custom -->
      <div *ngSwitchCase="'custom'" class="flex gap-2 items-center">
        <p-calendar styleClass="w-40 h-10" [(ngModel)]="startDate" [showIcon]="true" inputId="icon"
          dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
          (onSelect)="selectDateCustom()">
        </p-calendar>
        <span>-</span>
        <p-calendar styleClass="w-40 h-10" [(ngModel)]="endDate" [showIcon]="true" inputId="icon" dateFormat="dd/mm/yy"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030" [minDate]="startDate"
          (onSelect)="selectDateCustom()">
        </p-calendar>
      </div>
      <!-- เลือก customMonth -->
      <div *ngSwitchCase="'customMonth'" class="flex gap-2 items-center">
        <p-calendar styleClass="w-40 h-10" [showIcon]="true" [(ngModel)]="startDate" inputId="icon" view="month"
          dateFormat="mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
          (onSelect)="selectMonthCustom()">
        </p-calendar>
      </div>
      <!-- เลือก customYear -->
      <div *ngSwitchCase="'customYear'" class="flex gap-2 items-center">
        <p-calendar styleClass="w-40 h-10" [showIcon]="true" [(ngModel)]="startDate" inputId="icon" view="year"
          dateFormat="yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
          (onSelect)="selectYearCustom()">
        </p-calendar>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #mini>
  <div class="flex justify-end">
    <!-- icon="pi pi-calendar" -->
    <button class="
        flex
        items-center
        justify-center
        bg-green-island-500
        hover:bg-green-island-hover-500
        focus:bg-green-island-focus-500
        p-1.5
        rounded
        font-semibold
      " type="button" (click)="menu.toggle($event)">
      <img class="min-w-4 max-w-4 min-h-4 max-h-4" src="assets/image/icon-calendar.svg" alt="" />
    </button>
    <p-menu #menu [popup]="true" [model]="dateArr"></p-menu>
  </div>

  <p-dialog [(visible)]="displayModal" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="min-w-116 max-w-116 min-h-36 p-2">
      <div class="flex items-center gap-2 mb-3">
        <div class="">
          <img class="h-10" src="assets/image/Group 9375.svg" />
        </div>
        <span class="title-main">กำหนดช่วงเวลาของรายงาน</span>
      </div>

      <ng-container [ngSwitch]="selectedType">
        <!-- เลือก custom -->
        <div *ngSwitchCase="'custom'" class="flex flex-col gap-2">
          <div class="flex flex-row items-end gap-4">
            <div class="flex flex-row gap-2 items-center">
              <div class="flex flex-col">
                <p>วันที่เริ่มต้น</p>
                <p-calendar styleClass="w-40 h-10" [(ngModel)]="startDate" [showIcon]="true" inputId="icon"
                  dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                </p-calendar>
              </div>

              <p>-</p>

              <div class="flex flex-col">
                <p>วันที่สิ้นสุด</p>
                <p-calendar styleClass="w-40 h-10" [(ngModel)]="endDate" [showIcon]="true" inputId="icon"
                  dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030"
                  [minDate]="startDate">
                </p-calendar>
              </div>
            </div>
            <div class="btn_blue_small" (click)="displayModal = false; clickOk()">
              บันทึก
            </div>
          </div>
        </div>
        <!-- เลือก customMonth -->
        <div *ngSwitchCase="'customMonth'" class="flex gap-2 items-center">
          <div class="flex flex-row justify-between items-end gap-4">
            <div class="flex flex-row gap-2 items-center">
              <div class="flex flex-col">
                <p>เลือกเดือน</p>
                <p-calendar styleClass="w-72 h-10" [(ngModel)]="startDate" [showIcon]="true" inputId="icon" view="month"
                  dateFormat="mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                </p-calendar>
              </div>
            </div>

            <div class="ml-aut btn_blue_small" (click)="displayModal = false; clickOk()">
              บันทึก
            </div>
          </div>
        </div>
        <!-- เลือก customYear -->
        <div *ngSwitchCase="'customYear'" class="flex gap-2 items-center">
          <div class="flex flex-row justify-between items-end gap-4">
            <div class="flex flex-row gap-2 items-center">
              <div class="flex flex-col">
                <p>เลือกปี</p>
                <p-calendar styleClass="w-72 h-10" [(ngModel)]="startDate" [showIcon]="true" inputId="icon" view="year"
                  dateFormat="yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                </p-calendar>
              </div>
            </div>

            <div class="ml-auto btn_blue_small" (click)="displayModal = false; clickOk()">
              บันทึก
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- <div class="flex justify-center items-center">
      <div class="btn_blue_small" (click)="displayModal = false; clickOk()">ประมวลผล</div>
      <p-button icon="pi pi-times" (click)="displayModal = false" label="No"></p-button>
    </div> -->
  </p-dialog>
</ng-template>
