<div class="outline-none  h-auto   mt-10  md:mt-20 w-full mx-auto  md:w-6/12 pt-4
            flex  justify-center items-center  py-16">
  <div class=" text-center   ">
    <img src="../../../assets/image/user empty.svg" class="w-80 h-80 -mb-14  " alt="">
    <p *ngIf="Title1" class="text-xl  text-center  font-normal  dark:text-gray-500  text-gray-400 ">{{Title1}}</p>
    <p *ngIf="SubTitle" class="text-lg  text-center  font-normal  dark:text-gray-500  text-gray-400  "> {{SubTitle}}
    </p>
    <button routerLink="{{LinkButton}}" *ngIf="TxtButton" class="h-12  mt-5   text-white bg-blue-500 transition duration-100 ease-linear
           rounded-md  px-8 py-2 bg-transparent border-2 border-blue-500 text-lg ">{{TxtButton}}</button>
  </div>
</div>
