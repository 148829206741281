<div (click)="check()" class="grid grid-cols-2 rounded-full h-8 p-1 shadow-md w-14"
  [ngClass]="chkHasTrue ? 'bg-blue-new-500' : 'bg-gray-100'">
  <button [ngClass]="
        !chkHasTrue
          ? 'text-blue-new-500 bg-white shadow rounded-full'
          : 'text-gray-300'
      " class="
        text-center
        px-2
        py-1
        text-white
        font-semibold
        w-full
        text-xs
        focus:ring-0 focus:outline-none
      ">
    {{ toggle1 }}
  </button>
  <!-- text-green-island-800 -->
  <button [ngClass]="
        chkHasTrue
          ? 'text-blue-new-500 bg-white shadow rounded-full '
          : 'text-gray-500 text-opacity-75'
      " class="
        text-center
        px-2
        py-2
        text-white
        w-full
        text-xs
        focus:ring-0 focus:outline-none
      ">
    {{ toggle2 }}
  </button>
</div>