import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import {LoggerService} from "./core/services/logger.service";
import {LogComponent} from "./shared/utils/log.decorator";

@LogComponent()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-gapp-business-center-new';

  constructor(logger: LoggerService) {
    console.warn(`@@@start in ${environment.name.toUpperCase()} environment@@@`);
    logger.log(`@@@===${this.constructor.name}===@@@`)
  }
}
