import { HttpClient } from '@angular/common/http'
import { Injectable, OnInit } from '@angular/core'
import { GappBusiness, GappConsole } from '@geeesy/type-interfaces'
import { TApproverRole } from '@geeesy/type-interfaces/lib/type-business'
import { API } from 'aws-amplify'
import { Storage } from 'aws-amplify'
import { BehaviorSubject, Observable, concat, defer, from, throwError } from 'rxjs'
import { catchError, map, skip, tap } from 'rxjs/operators'

import { logServiceL2 } from '../../shared/utils/log.function'
import { TokenService } from './token.service'

const API_NAME: string = 'gbzApiIam'
const API_PATH: string = '/bc'
const API_PATH_ADMIN: string = '/admin'
const API_BIZ: string = 'gbzApiBiz'
const API_BIZ_PATH: string = '/biz'
const APT_GappInventory: string = 'gbzExApiInventory'
const APT_GappInventory_PATH: string = '/product/category'
const APT_CATEGORY: string = 'gbzExApiInventory'
const API_PATH_CATEGORY: string = '/product/category/list'
const API_PATH_BIZ = '/company'
interface INewUser {
  username?: string
  identityId?: string
  phone?: string
  email?: string
}

export interface ICategory {
  categoryId: string
  categoryName: string
  categoryCaption: string
  categoryDescription: string
  categoryImageUrl: string
  categoryIconUrl: string
  categoryBannersUrl: string[]
  isRecommend: boolean
  isMainCat: boolean
  impFactor: number
  createdAt: string
  updatedAt: string
}

interface ICreateAdmin {
  email: string
  phone: string
  name: string
  firstName: string
  lastName: string
  nickname: string
  companyCode: string
  personalCode: string
  birthDate: string
  isDefaultReceiver: boolean
  approverRole: TApproverRole
  compId: string
  avatarImageUrl: string
  companyPosition: string
  companyDept: string
}

interface ISendCompanyInfo {
  compName: string
  compId: string
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements OnInit {
  private _url =
    'https://xy9etmajsj.execute-api.ap-southeast-1.amazonaws.com/dev/catalog/category/'
  private tenantId = ''
  private token = ''
  private ownerUsername = ''
  private bizName = ''

  public newAdminB$ = new BehaviorSubject<INewUser>({})

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  ngOnInit() {
    logServiceL2('CompanyService')
  }

  private level1 = new BehaviorSubject<Array<any>>([])
  categoryLevel1 = this.level1.asObservable()
  private level2 = new BehaviorSubject<any[]>([])
  categoryLevel2 = this.level2.asObservable()
  private level3 = new BehaviorSubject<any[]>([])
  categoryLevel3 = this.level3.asObservable()
  private level4 = new BehaviorSubject<any[]>([])
  categoryLevel4 = this.level4.asObservable()
  private level5 = new BehaviorSubject<any[]>([])
  categoryLevel5 = this.level5.asObservable()

  /**
   * NOTE COMPANY COUNT
   */
  private companyCount = new BehaviorSubject<any>(Number)
  companyCount$ = this.companyCount.asObservable()

  getMainCat(): Observable<GappConsole.Category> {
    return from(
      API.get(APT_CATEGORY, `${API_PATH_CATEGORY}/?marketCode=1&categoryId=`, {})
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET] Main Categories', res))
    )
  }

  /**
   * GET SUBCAT
   */
  getSupCat(categoryId: any, level: number): Observable<any[]> {
    return from(
      API.get(
        APT_CATEGORY,
        `${API_PATH_CATEGORY}/?marketCode=1&categoryId=${categoryId}`,
        {}
      )
    ).pipe(
      map((res) => res.data),
      tap((res) => {
        if (level === 1) {
          this.level2.next(res)
          this.level3.next([])
          this.level4.next([])
          this.level5.next([])
        }
        if (level === 2) {
          this.level3.next(res)
          this.level4.next([])
          this.level5.next([])
        }
        if (level === 3) {
          this.level4.next(res)
          this.level5.next([])
        }
        if (level === 4) {
          this.level5.next(res)
        }
      }),
      catchError((res) => this.handleError(res))
    )
  }

  /**
   * GET -> List all COMPANIES
   */
  getAllCompanies() {
    const listObs = defer(() =>
      from(API.get(API_NAME, `${API_PATH}/company/all/${this.tenantId}`, {}))
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listObs
    ).pipe(
      skip(1),
      map((res) => res.data)
      // tap((res) => console.log('[Get] Company List  =>', res))
    )
  }

  // NOTE Delete Company
  deleteCompany(businessId: any, companyCode: any) {
    return from(
      API.put(API_NAME, `${API_PATH}/company/delete/${businessId}/${companyCode}`, {})
    ).pipe(tap((res) => console.warn('[PUT] Delete Company =>', res)))
  }

  /**
   * GET -> Detail COMPANIES
   */
  getDetailCompanie(businessId: any, companyCode: any) {
    const listObs = defer(() =>
      from(API.get(API_NAME, `${API_PATH}/company/${businessId}/${companyCode}`, {}))
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listObs
    ).pipe(
      skip(1),
      map((res) => res.data)
      // tap((res) => console.log('[Get] Company Detail  =>', res))
    )
  }

  /**
   * NOTE POST -> Create COMPANY
   */
  createCompany({
    compId,
    companyCode,
    companyEntity,
  }: {
    compId: string
    companyCode: string
    companyEntity: GappBusiness.CreateCompany
  }): Observable<any> {
    console.log('--create groupname--', companyCode)
    const createGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/adminCreateGroup', {
          body: {
            groupname: `${this.tenantId}#${companyCode}`,
            description: companyEntity.companyName,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addUserToGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: `${this.tenantId}#${companyCode}`,
            username: this.ownerUsername,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    // * COMPANY -> DB
    const createCompanyObs = defer(() => {
      const item: GappBusiness.CreateCompany = {
        companyMarketDescription: '',
        businessId: this.tenantId,
        companyLogoThumbUrl: companyEntity.companyLogoThumbUrl,
        identityId: '',
        contact: {
          companyMarketLogoUrl: companyEntity.companyMarketLogoUrl,
          hasVatRegistration: companyEntity.contact.hasVatRegistration,
          companyName: companyEntity.contact.companyName,
          companyMarketName: companyEntity.contact.companyMarketName,
          companyPhones: companyEntity.contact.companyPhones,
          companyWebsite: companyEntity.contact.companyWebsite,
          companyEmails: companyEntity.contact.companyEmails,
          companySocial: {
            line: companyEntity.contact.companySocial.line,
            facebook: companyEntity.contact.companySocial.facebook,
          },
          contactAddress: {
            taxInvoiceName:'',
            addrName: companyEntity.contact.contactAddress.addrName,
            addrFull: companyEntity.contact.contactAddress.addrFull,
            addrNumber: companyEntity.contact.contactAddress.addrNumber,
            province: companyEntity.contact.contactAddress.province,
            amphoe: companyEntity.contact.contactAddress.amphoe,
            tambon: companyEntity.contact.contactAddress.tambon,
            postcode: companyEntity.contact.contactAddress.postcode,
            phones: [],
            mobiles: [],
            faxes: [],
            emails: [],
            country: '',
            lat: '',
            lng: '',
            officeHours: [
              {
                day: '',
                times: ['', ''],
              },
            ],
          },
          billingAddress: {
            taxInvoiceName:'',
            addrName: '',
            addrFull: '',
            addrNumber: '',
            province: '',
            amphoe: '',
            tambon: '',
            postcode: '',
            phones: [],
            mobiles: [],
            faxes: [],
            emails: [],
            country: '',
            lat: '',
            lng: '',
            officeHours: [
              {
                day: '',
                times: ['', ''],
              },
            ],
          },
          shippingAddress: [
            {
              taxInvoiceName:'',
              addrName: '',
              addrFull: '',
              addrNumber: '',
              province: '',
              amphoe: '',
              tambon: '',
              postcode: '',
              phones: [],
              mobiles: [],
              faxes: [],
              emails: [],
              country: '',
              lat: '',
              lng: '',
              officeHours: [
                {
                  day: '',
                  times: ['', ''],
                },
              ],
            },
          ],
          companyNote: '',
          companyRegisNo: '',
          companyTaxInfo: {
            taxNo: companyEntity.contact.companyTaxInfo!.taxNo,
            isHq: companyEntity.contact.companyTaxInfo!.isHq,
            branch: [
              {
                id: '',
                name: '',
                address: '',
                taxNo: '',
              },
            ],
          },
        },
        setting: {
          paymentMethods: [
            {
              paymentMethodId: '',
              paymentMethodType: '',
              paymentParties: [
                {
                  title: '',
                  iconUrl: '',
                },
              ],
            },
          ],
          shippingZones: [
            {
              shippingZoneId: '',
              zoneName: '',
              zoneStates: [],
              zonePostcodes: [],
              zoneMethods: [
                {
                  shippingMethodId: '',
                  shippingMethodName: '',
                  shippingMethodIconUrl: '',
                },
              ],
              zoneParties: [
                {
                  shippingPartyId: '',
                  title: '',
                  postcodes: [],
                  iconUrl: '',
                },
              ],
            },
          ],
          creditTerms: [],
        },
        compId: compId,
        companyCategoryId: companyEntity.companyCategoryId,
        companyCategoryCustomName: companyEntity.companyCategoryCustomName,
        companyCode: companyCode,
        companyName: companyEntity.companyName,
        companyMarketName: companyEntity.companyMarketName,
        companyTypes: companyEntity.companyTypes,
        useApprovalWorkflow: companyEntity.useApprovalWorkflow,
        companyStampUrl: companyEntity.companyStampUrl,
        companySize: companyEntity.companySize,
        companyInterests: companyEntity.companyInterests,
        companyLogoUrl: companyEntity.companyLogoUrl,
        companyMarketLogoUrl: companyEntity.companyMarketLogoUrl,
        identityFullName: '',
        shopeeToken: {
          accessToken: '',
          refreshToken: '',
          expireIn: 0,
        },
      }

      return from(
        API.post(API_NAME, `${API_PATH}/company`, {
          body: {
            ...item,
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.ownerUsername = obj.ownerUsername
          this.tenantId = obj.tenantId
          console.log('---concat----', obj)
        })
      ),
      createGroupObs,
      addUserToGroupObs,
      createCompanyObs
    ).pipe(tap(console.log))
  }

  /**
   * NOTE POST -> Create ADMIN in COMPANY
   * ICreateAdmin
   */
  createAdmin({
    email,
    phone,
    name,
    firstName,
    lastName,
    nickname,
    companyCode,
    personalCode,
    birthDate,
    isDefaultReceiver,
    approverRole,
    compId,
    avatarImageUrl,
    companyPosition,
    companyDept,
  }: ICreateAdmin): Observable<any> {
    const adminCreateUserObs = defer(() =>
      from(
        API.post('AdminQueries', '/adminCreateUser', {
          body: {
            email: email,
            username: email,
            name: this.bizName,
            given_name: name,
            userrole: 'admin',
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    ).pipe(
      tap((res) => {
        const newAdminUsername = res.data.User.Username
        const attributes = res.data.User.Attributes as []
        const newAtt: any = attributes.find((p: any) => p.Name === 'custom:identityId')
        const phoneAtt: any = attributes.find((p: any) => p.Name === 'phone_number')
        const emailAtt: any = attributes.find((p: any) => p.Name === 'email')
        this.newAdminB$.next({
          username: newAdminUsername,
          identityId: newAtt.Value,
          phone: emailAtt.Value,
          email: emailAtt.Value,
        })
        console.log('create admin in company', res)
      })
    )
    // phone: emailAtt.Value,
    const addUserToAdminGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: 'tenant0',
            username: this.newAdminB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addUserToBizGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: this.tenantId,
            username: this.newAdminB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addUserToCompGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: `${this.tenantId}#${companyCode}`,
            username: this.newAdminB$.getValue().username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const createCompanyUserAdminObs = defer(() => {
      const userCreateObj: GappBusiness.CreateCompanyUser = {
        roleId: '',
        hasArchived: true,
        businessId: this.tenantId,
        businessDept: '',
        avatarImageUrl: avatarImageUrl,
        nickname: nickname,
        gender: 'neutral',
        birthDate: birthDate,
        companyDept: companyDept,
        companyPosition: companyPosition,
        companyCode: companyCode,
        personalCode: personalCode,
        email: this.newAdminB$.getValue().email as string,
        fullName: name,
        firstName: firstName,
        lastName: lastName,
        identityId: this.newAdminB$.getValue().identityId as string, // FIXME: owner
        mobile: this.newAdminB$.getValue().phone as string,
        penName: name,
        userRole: 'admin',
        isKeyContact: false,
        username: this.newAdminB$.getValue().username as string,
        isDefaultReceiver: isDefaultReceiver,
        businessPosition: '',
        approverRole: approverRole,
        approvalFlowRules: {
          rfqTypeR: false,
          quotationTypeM: false,
          quotationTypeR: false,
          quotationTypeD: false,
          poTypeR: false,
          poTypeS: false,
          poTypeD: false,
          soTypeR: false,
          soTypeS: false,
          soTypeD: false,
        },
        adminUsername: this.newAdminB$.getValue().username as string,
        compId: compId, // FIXME: compId
        contact: {
          firstName: '',
          lastName: '',
          prefixName: '',
          tags: [''],
          personPic: '',
          fullName: '',
          emails: [this.newAdminB$.getValue().email as string],
          // mobiles: [this.newAdminB$.getValue().phone as string],
          mobiles: [phone],
          social: {
            line: '',
            facebook: '',
          },
          taxNo:'',
          address: {
            taxInvoiceName:'',
            addrName: '',
            addrFull: '',
            addrNumber: '',
            province: '',
            amphoe: '',
            tambon: '',
            postcode: '',
            phones: [''],
            mobiles: [''],
            faxes: [''],
            emails: [''],
            country: '',
            lat: '',
            lng: '',
            officeHours: [
              {
                day: '',
                times: ['', ''],
              },
            ],
          },
          shippingAddress: [],
          note: '',
        },
      }
      console.log('userCreateObj', userCreateObj)
      return from(
        API.post(API_NAME, `${API_PATH}/user/admin`, {
          body: {
            ...userCreateObj,
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.ownerUsername = obj.ownerUsername
          this.bizName = obj.bizName
          this.tenantId = obj.tenantId
        })
      ),
      adminCreateUserObs,
      addUserToAdminGroupObs,
      addUserToBizGroupObs,
      addUserToCompGroupObs,
      createCompanyUserAdminObs
    ).pipe(tap((res) => console.log('---concat---', res)))
  }

  /**
   * POST -> Add existing ADMIN into COMPANY
   */
  addAdminToCompany(
    username: string,
    compId: string,
    email: string,
    phone: string,
    penName: string,
    fullName: string,
    identityId: string,
    isExistAdmin: boolean,
    historyCountOnCompany: number,
    isDefaultReceiver: boolean = false,
    companies?: string[]
  ) {
    const addExistUserToCompGroupObs = defer(() =>
      from(
        API.post('AdminQueries', '/addUserToGroup', {
          body: {
            groupname: `${this.tenantId}#${compId}`,
            username: username,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.token,
          },
        })
      )
    )

    const addCompanyUserAdminObs = defer(() => {
      const userObj = {
        businessId: this.tenantId,
        companies: [],
        email: email,
        fullName: fullName,
        identityId: identityId,
        isActive: true,
        mobile: phone,
        penName: penName,
        userRole: 'admin',
        username: username,
        businessDept: '',
        // isDefaultReceiver: isDefaultReceiver
        createdAt: '',
        updatedAt: '',
        businessPosition: '',
        historyCountOnCompany: 0,
        historyCountOnAdmin: 0,
        // userIdentityId: this.newAdminB$.getValue().identityId as string,
      }
      return from(
        API.post(API_NAME, `${API_PATH}/company/admin/add`, {
          body: {
            tenantId: this.tenantId,
            username,
            compId,
            isExistAdmin,
            companies: companies,
            historyCountOnCompany,
            dataIdentity: userObj,

            /*            dataIdentity: {
              identityId,
              username,
              userRole: 'admin',
              phone,
              email,
              penName,
              fullName,
            },*/
          },
        })
      )
    })

    return concat(
      this.tokenService.tokenOwner().pipe(
        tap((obj) => {
          this.token = obj.token
          this.ownerUsername = obj.ownerUsername
          this.bizName = obj.bizName
          this.tenantId = obj.tenantId
        })
      ),
      addExistUserToCompGroupObs,
      addCompanyUserAdminObs
    )
  }

  /**
   * GET -> List all USERs in COMPANY
   */
  getAllUsers(companyCode: string) {
    const listCompanyUsersObs = defer(() =>
      from(
        API.get(API_NAME, `${API_PATH}/company/users/${this.tenantId}/${companyCode}`, {})
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listCompanyUsersObs
    ).pipe(tap((res) => console.log('AllUsers', res)))
  }

  /*
   * [GET] List Admin
   */
  getListAdmin(businessId: any, companyCode: any) {
    const listAdmin = defer(() =>
      from(
        API.get(
          API_NAME,
          `${API_PATH}/user/admin?businessId=${businessId}&companyCode=${companyCode}`,
          {}
        )
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listAdmin
    )
    // .pipe(tap((res) => console.log('[GET All Admin List In Company]', res)))
  }

  getListStaffs(companyCode: string) {
    const listUsersObs = defer(() =>
      from(
        API.get(API_BIZ, `${API_PATH_BIZ}/user/${this.tenantId}/${companyCode}/users`, {})
      )
    )
    return concat(
      this.tokenService.tenantId().pipe(tap((id) => (this.tenantId = id))),
      listUsersObs
    )
    // .pipe(tap((res) => console.log('[GET Staff List]', res)))
  }

  // TODO: geee-biz
  sendCompanyInfo({ compName, compId }: ISendCompanyInfo): Observable<any> {
    return from(
      API.post(API_BIZ, API_BIZ_PATH, {
        body: {
          company: compName,
          compCode: compId,
        },
      })
    )
  }

  getUserDetail(businessId: any, username: any) {
    return from(
      API.get(API_NAME, `${API_PATH_ADMIN}/identity/${businessId}/${username}`, {})
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET ADMIN DETAIL] Get Receiver', res)),
      catchError((res) => this.handleError(res))
    )
  }

  getCategoryNameById(categoryId: any) {
    return from(
      API.get(APT_GappInventory, `${APT_GappInventory_PATH}/${categoryId}`, {})
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET  CATERGORY NAME BY ID ', res)),
      catchError((res) => this.handleError(res))
    )
  }

  getReceiver(businessId: any, companyCode: any) {
    return from(
      API.get(
        API_NAME,
        `${API_PATH}/company/default/receiver?businessId=${businessId}&companyCode=${companyCode}`,
        {}
      )
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET PROMISE] Get Receiver', res)),
      catchError((res) => this.handleError(res))
    )
  }

  updateCompany(companyUpdate: GappBusiness.Company, businessId: any, companyCode: any) {
    return from(
      API.put(API_NAME, `${API_PATH}/company/${businessId}/${companyCode}`, {
        body: {
          ...companyUpdate,
        },
      })
    )
  }

  //NOTE [GET] Dashboard
  getCompanyDashboard(businessId: any, compId: any) {
    return from(
      API.get(API_BIZ, `${API_PATH_BIZ}/dashboard/${businessId}/${compId}`, {})
    ).pipe(
      map((res) => res.data),
      tap((res) => console.log('[GET] COMPANY DASHBOARD', res)),
      catchError((res) => this.handleError(res))
    )
  }

  getCompanyCount() {
    this.companyCount.next(JSON.stringify(localStorage.getItem('companyCount')))
    return this.companyCount$
  }

  postCompanyCount(data: any) {
    console.warn('--- set data ---', data)
    this.companyCount.next(data)
    localStorage.setItem('companyCount', JSON.stringify(this.companyCount.getValue()))
  }

  private adminCount = new BehaviorSubject<string | null>('')

  adminCount$ = this.adminCount.asObservable()

  getAdminCount() {
    this.adminCount.next(JSON.stringify(localStorage.getItem('adminCount')))
    return this.adminCount$
  }

  postAdminCount(data: string) {
    this.adminCount.next(data)
    localStorage.setItem('pageRow', JSON.stringify(this.adminCount.getValue()))
  }

  private adminofstaff = new BehaviorSubject<string | null>('')

  adminofstaff$ = this.adminofstaff.asObservable()

  getAdminofstaff() {
    this.adminofstaff.next(JSON.stringify(localStorage.getItem('adminofstaff')))
    return this.adminofstaff$
  }

  postAdminofstaff(data: string) {
    this.adminofstaff.next(data)
    localStorage.setItem('adminofstaff', JSON.stringify(this.adminofstaff.getValue()))
  }

  private handleError(err: any) {
    let errorMessage: string
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`
    } else {
      errorMessage = `Backend returned code ${err.status}: ${err.body?.error}`
    }
    console.warn(err)
    return throwError(errorMessage)
  }
}
