<div
  style="width: 18rem; padding: 0.5rem 1.25rem"
  class="bg-white h-full sticky top-0 left-0 lg:h-screen shadow-xl"
>
  <div class="flex flex-col h-full gap-2">
    <div class="flex flex-col gap-2" style="height: 92%">
      <div
        class="flex-shrink-0 flex items-center cursor-pointer pt-4 pb-6"
        routerLink="/app/business"
        (click)="setData()"
      >
        <!-- NOTE LOGO size -->
        <img
          src="../../../assets/image/gapp-img/logo_main.png"
          class="w-40 md:w-44 lg:w-52"
        />
      </div>

      <div
        #routeHome="routerLinkActive"
        routerLinkActive="activeSideBar"
        routerLink="/app/business"
        (click)="setData()"
        class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer"
      >
        <!-- <fa-icon class="" [icon]="faHome"></fa-icon> -->
        <img
          class="min-w-6 max-w-6 min-h-6 max-h-6"
          [src]="
            routeHome.isActive
              ? 'assets/image/icon_side_bar/home-white.svg'
              : 'assets/image/icon_side_bar/home.svg'
          "
          alt=""
        />
        <p (click)="setData()">หน้าหลัก</p>
      </div>

      <!-- <ng-container *ngIf="chkRoute"> -->
      <!-- routerLinkActive="activeSideBar" routerLink="/app/company/detail/{{ comp.businessId }}/{{ comp.companyCode }}/{{
          comp.compId
         }}/" -->
      <ng-container *ngIf="comp$ | async as comp">
        <ng-container *ngIf="!isEmpty(comp)">
          <div
            #routeCompData="routerLinkActive"
            routerLinkActive="activeSideBar"
            routerLink="/app/company/detail/{{ comp.businessId }}/{{
              comp.companyCode
            }}/{{ comp.compId }}"
            class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer"
          >
            <!-- <fa-icon class="" [icon]="faChartPie"></fa-icon> -->
            <img
              class="min-w-6 max-w-6 min-h-6 max-h-6"
              [src]="
                routeCompData.isActive
                  ? 'assets/image/icon_side_bar/setting-white.svg'
                  : 'assets/image/icon_side_bar/setting.svg'
              "
              alt=""
            />
            <p>ข้อมูลบริษัท</p>
          </div>

          <div
            #routeSettingComp="routerLinkActive"
            routerLinkActive="activeSideBar"
            routerLink="/app/company/detail/edit/{{ comp.businessId }}/{{
              comp.companyCode
            }}"
            class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer"
          >
            <!-- <fa-icon class="" [icon]="faChartPie"></fa-icon> -->
            <img
              class="min-w-6 max-w-6 min-h-6 max-h-6"
              [src]="
                routeSettingComp.isActive
                  ? 'assets/image/icon_side_bar/setting-white.svg'
                  : 'assets/image/icon_side_bar/setting.svg'
              "
              alt=""
            />
            <p>ตั้งค่าข้อมูลบริษัท</p>
          </div>

          <div
            #routeManageUser="routerLinkActive"
            routerLinkActive="activeSideBar"
            routerLink="/app/company/list-admin/{{ comp.businessId }}/{{ comp.compId }}/{{
              comp.companyCode
            }}/{{ comp.haveDefaultReceiver }}/{{ comp.companyName }}"
            class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer"
          >
            <img
              class="min-w-6 max-w-6 min-h-6 max-h-6"
              [src]="
                routeManageUser.isActive
                  ? 'assets/image/icon_side_bar/manage-user-white.svg'
                  : 'assets/image/icon_side_bar/manage-user.svg'
              "
              alt=""
            />
            <p>จัดการผู้ใช้งาน</p>
          </div>

          <div
            #routeManageRole="routerLinkActive"
            routerLinkActive="activeSideBar"
            routerLink="/app/company/role/list"
            class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer"
          >
            <img
              class="min-w-6 max-w-6 min-h-6 max-h-6"
              [src]="
                routeManageRole.isActive
                  ? 'assets/image/icon_side_bar/manage-role-white.svg'
                  : 'assets/image/icon_side_bar/manage-role.svg'
              "
              alt=""
            />
            <p>จัดการบทบาทผู้ใช้งาน</p>
          </div>
        </ng-container>
      </ng-container>
      <!-- </ng-container> -->

      <!-- <div #routePackage="routerLinkActive" routerLinkActive="activeSideBar" routerLink="/app/package/dashboard"
        class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-100 cursor-pointer">

        <img class="min-w-6 max-w-6 min-h-6 max-h-6"
          [src]="routePackage.isActive? 'assets/image/icon_side_bar/manage-package-white.svg':'assets/image/icon_side_bar/manage-package.svg'"
          alt="">
        <p>แพ็คเกจ</p>
      </div> -->
    </div>

    <div style="height: 8%">
      <div
        routerLinkActive="activeSideBar"
        routerLink="/logout"
        class="px-5 py-2 text-gray-400 text-lg rounded-md flex flex-row items-center gap-2 border border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white"
      >
        <fa-icon class="" [icon]="faSignOutAlt"></fa-icon>
        <p>ออกจากระบบ</p>
      </div>
    </div>
  </div>
</div>
