import { Component, OnInit } from '@angular/core'
import { last, tap } from 'rxjs/operators'

import { Router } from '@angular/router'
import { UserAdminService } from '../../../core/services/user-admin.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-checkmail',
  templateUrl: './checkmail.component.html',
  styles: [],
})
export class CheckmailComponent implements OnInit {
  constructor(private router: Router, private userAdminService: UserAdminService) {}

  ngOnInit(): void {
    this.getOwnerEmail()
    // this.testClick()
  }

  getOwnerEmail() {
    this.userAdminService.getOwnerEmail().subscribe((res) => {
      // this.dataRes = JSON.parse(res)
    })
  }
}
