<div
  class="bg-card-gray h-screen flex items-center justify-center relative px-4 xl:px-0"
>
  <!-- รูปปกหลัง -->
  <img
    style="object-fit: cover; object-position: top"
    class="h-screen  w-full absolute xl:w-vw50 right-0"
    src="assets/image/gapp-img/img-welcome-right.png"
  />

    <div class="bg-white shadow-lg rounded-xl p-6 z-10 " style="width: 1080px;height: 700px;" >
      <div class="flex flex-col gap-10">
        <p class="text-4xl sm:text-6xl text-green-island-500">
          Welcome to GAPP biz
        </p>
        <div class="flex gap-10">
          <!-- image -->
          <img
            class="rounded-xl max-h-140 hidden lg:block"
            src="assets/image/gapp-img/img-welcome-left.png"
          />

          <!-- Description -->
          <div
            class="flex flex-col justify-between text-xl sm:text-3xl gap-6 sm:gap-10"
          >
            <p class="">
              จัดการทุกออเดอร์ไม่มีหลุด รายงานผลแบบ Real Time ในทุกแพลตฟอร์ม
              Facebook, LINE OA, Instagram, และเว็บส่วนตัวของคุณ
              ให้การจัดการแสนยุ่งยาก กลายเป็นเรื่องง่ายเมื่อมี GAPP biz
            </p>

            <p class="text-black">
              GAPP biz ระบบจัดการร้านค้าออนไลน์สุดชาญฉลาด
            </p>
            <p class="text-black">
              เพราะการเพิ่มกำไรของธุรกิจของคุณ คือ KPI ของธุรกิจเรา
            </p>
            <p class="text-black">ทดลองใช้ฟรี สมัครเลย!</p>

            <div class="flex justify-end text-2xl">
              <button
                (click)="onClickNextpage()"
                class="btn_blue_small cursor-pointer relative w-full lg:w-auto"
                target="_blank"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <ng-container *ngIf="loading === true">
                    <svg
                      class="animate-spin h-5 w-5 fill-current"
                      style="color: #f5f9fc"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 458.186 458.186"
                      style="enable-background: new 0 0 458.186 458.186"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M445.651,201.95c-1.485-9.308-10.235-15.649-19.543-14.164c-9.308,1.485-15.649,10.235-14.164,19.543
                                         c0.016,0.102,0.033,0.203,0.051,0.304c17.38,102.311-51.47,199.339-153.781,216.719c-102.311,17.38-199.339-51.47-216.719-153.781
                                         S92.966,71.232,195.276,53.852c62.919-10.688,126.962,11.29,170.059,58.361l-75.605,25.19
                                         c-8.944,2.976-13.781,12.638-10.806,21.582c0.001,0.002,0.002,0.005,0.003,0.007c2.976,8.944,12.638,13.781,21.582,10.806
                                         c0.003-0.001,0.005-0.002,0.007-0.002l102.4-34.133c6.972-2.322,11.675-8.847,11.674-16.196v-102.4
                                         C414.59,7.641,406.949,0,397.523,0s-17.067,7.641-17.067,17.067v62.344C292.564-4.185,153.545-0.702,69.949,87.19
                                         s-80.114,226.911,7.779,310.508s226.911,80.114,310.508-7.779C435.905,339.799,457.179,270.152,445.651,201.95z"
                          />
                        </g>
                      </g>
                    </svg>
                  </ng-container>
                </span>
                <p class="px-4">เข้าสู่ระบบ</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
