import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  private dataComp = new BehaviorSubject(null)
  dataComp$ = this.dataComp.asObservable()

  private userStateB$ = new BehaviorSubject<any>(undefined)

  constructor() {}

  get compState() {
    const compStorage = localStorage.getItem('compState')
    if (compStorage != null) {
      return JSON.parse(compStorage)
    }
  }

  compStateSteam() {
    console.log('compStateSteam')
    const compStorage = localStorage.getItem('compState')
    if (compStorage != null) {
      this.dataComp.next(JSON.parse(compStorage))
    }
  }

  setCompState(item: any) {
    this.dataComp.next(item)
    localStorage.setItem('compState', JSON.stringify(item))
  }
}
