import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from './../../../core/services/auth.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styles: [],
})
export class LogoutComponent implements OnInit {
  constructor(private authenservice: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.logout()
  }

  logout() {
    this.authenservice.logout()
    this.router.navigateByUrl('/login')
  }
}
