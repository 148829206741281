import { Component, Input, OnInit } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'

import { OpenBusinessService } from '../../services/open-business.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-open-business',
  templateUrl: './open-business.component.html',
  styleUrls: ['./open-business.component.scss'],
})
export class OpenBusinessComponent implements OnInit {
  loading: boolean = false
  fullwebsite = ''
  interests: string[] = []
  tagblur: any

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private OpenBusinessService: OpenBusinessService
  ) {}
  @Input() control: FormControl = new FormControl('')
  openbusinessForm!: FormGroup

  get BusinessType() {
    return this.openbusinessForm.get('businessType') as unknown as FormArray
  }

  ngOnInit(): void {
    this.openbusinessForm = this.fb.group({
      businessWebsite: [''],
      businessSize: [''],
      businessType: [['']],
      // interest: [[''], [Validators.required]],
      interest: [['']],
      useAsCompany: [false],
    })
    console.log('interests', this.interests)
  }

  liststypebusiness = [
    {
      name: 'ผู้ผลิต',
      value: 'ผู้ผลิต (manufacturer)',
    },
    {
      name: 'ผู้ค้าส่ง',
      value: 'ผู้ค้าส่ง (wholesaler)',
    },
    {
      name: 'ผู้ค้าปลีก',
      value: 'ผู้ค้าปลีก (retailer)',
    },
  ]

  listssizebusiness = [
    {
      name: 'ขนาดย่อม',
      value: 'micro',
      note: '(พนักงาน < 20 คน ทุน < 5 ล้าน)',
      imagedark: '../../../../assets/image/busisize/Group 2696.svg',
      imagelight: '../../../../assets/image/busisize/Group 2695.svg',
    },
    {
      name: 'เล็ก',
      value: 'small',
      note: '(พนักงาน < 50 คน ทุน < 20 ล้าน)',
      imagedark: '../../../../assets/image/busisize/hotel.svg',
      imagelight: '../../../../assets/image/busisize/hotel copy.svg',
    },
    {
      name: 'กลาง',
      value: 'medium',
      note: '(พนักงาน 50 - 200 คน ทุน 20 - 200 ล้าน)',
      imagedark: '../../../../assets/image/busisize/appartment.svg',
      imagelight: '../../../../assets/image/busisize/appartment copy.svg',
    },
    {
      name: 'ใหญ่',
      value: 'large',
      note: '( พนักงาน > 200 คน ทุน > 200 ล้าน)',
      imagedark: '../../../../assets/image/busisize/skyline.svg',
      imagelight: '../../../../assets/image/busisize/skyline copy.svg',
    },
  ]

  listtypewab = [
    {
      name: 'https://',
    },
    {
      name: 'http://',
    },
  ]

  onBlurMethod() {
    var tag = document.getElementById('interest') as HTMLInputElement
    this.tagblur = tag.value
    // console.log('Good : ', this.tagblur)
    if (this.tagblur != '') {
      let myBlur = this.interests.push(this.tagblur)
      // console.log('myBlur', myBlur)
      if (myBlur) {
        tag.value = ''
      }
    }
  }

  pushInterests(event: { keyCode: number }) {
    if (event.keyCode == 13) {
      let textInterests = document.getElementById('interest') as HTMLInputElement
      let valueInterests = textInterests.value
      if (valueInterests != '') {
        let comp = this.interests.push(valueInterests)
        if (comp) {
          textInterests.value = ''
        }
      }
    }
  }
  removeInterests(index: number) {
    this.interests.splice(index, 1)
  }

  openbiz() {
    let http = document.getElementById('frontwww') as HTMLInputElement

    if (this.openbusinessForm.get('businessWebsite')!.value != '') {
      this.fullwebsite = http.value + this.openbusinessForm.get('businessWebsite')!.value
    } else {
      this.fullwebsite = this.openbusinessForm.get('businessWebsite')!.value
    }

    console.log('res', this.openbusinessForm.value)

    this.loading = true
    this.OpenBusinessService.createBusiness({
      businessCategoryId: '',
      businessSize: this.openbusinessForm.get('businessSize')!.value,
      businessType: this.openbusinessForm.get('businessType')!.value,
      businessWebsite: this.fullwebsite,
      interest: this.interests,
      // interest: this.openbusinessForm.get('interest')!.value,
    }).subscribe(
      (a) => console.log(a),
      (err) => {
        console.log(err)
        this.loading = false
      },
      () => {
        this.router.navigateByUrl('/welcome')
        this.loading = false
      }
    )
  }

  selectcolor: boolean = false

  border() {
    this.selectcolor = true
  }

  onCheckType(event: { target: { checked: any; value: any } }, index: number) {
    if (event.target.checked) {
      this.BusinessType.at(index).patchValue(event.target.value)
      console.log(this.BusinessType.value)
    }
  }
}
