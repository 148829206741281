import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Import components
import {LayoutComponent} from "./blocks/layout/layout.component";
import {PageNotFoundComponent} from "./blocks/page-not-found/page-not-found.component";
import {WelcomeComponent} from "./welcome/welcome.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/register', // ? => auth-routing
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      // },
      {
        path: 'business',
        loadChildren: () =>
            import('./business/business.module').then((m) => m.BusinessModule),
      },
      {
        path: 'company',
        loadChildren: () =>
            import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
            import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'package',
        loadChildren: () =>
            import('./package/package.module').then((m) => m.PackageModule),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
