import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ui-layout',
  templateUrl: './layout.component.html',
  styles: [],
})
export class LayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
