import {Component, Input, OnInit} from '@angular/core'
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms'
import {Router} from '@angular/router'

import {AuthService} from './../../../core/services/auth.service'
import { LogComponent } from 'src/app/shared/utils/log.decorator'

@LogComponent()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent implements OnInit {
  hide: boolean = true
  hide2: boolean = true
  loading: boolean = false
  showError: boolean = false


  @Input() control: FormControl = new FormControl('')
  loginForm!: FormGroup
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  //@Input() dark: boolean

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      // phone: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          // Validators.minLength(8),
          // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        ],
      ],
      code: ['', [Validators.required]],
    })
  }
  login(submittedForm: FormGroup) {
    this.loading = true
    console.log('submittedForm', submittedForm)
    this.authService
      .logIn({
        email: submittedForm.value.email,
        password: submittedForm.value.password,
        code: submittedForm.value.code,
      })
      .subscribe(
        (a) => {
          console.warn('log obj a ===>', { a })
        },
        (err) => {
          console.log(err)
          ;(this.showError = true), (this.loading = false)
        },
        () => {
          this.router.navigateByUrl('/app/business'), (this.loading = false)
        }
      )

    //  a.attributes

    //this.router.navigateByUrl('/open-business-account')
  }

  showpass() {
    let txtpwd: any = document.getElementById('inputpassword')
    if (txtpwd.type === 'password') {
      txtpwd.type = 'text'
      this.hide = false
    } else {
      txtpwd.type = 'password'
      this.hide = true
    }
  }

  showpass2() {
    let txtpwd: any = document.getElementById('inputpassword2')

    if (txtpwd.type === 'password') {
      txtpwd.type = 'text'
      this.hide2 = false
    } else {
      txtpwd.type = 'password'
      this.hide2 = true
    }
  }

  startZero() {
    let inputphone: any = document.getElementById('inputphone') as HTMLInputElement
    const reg = new RegExp(/^0{1}[\d]{9}$/g)
    // let ff = String.format('%08d', inputphone)

    if (reg.test(inputphone.value)) {
      alert('sss')
    }
  }

  // showErrorMobileNumber(control) {
  //   let valuePhoneMobile = this.loginForm.get(control).value
  //   if (valuePhoneMobile !== '' && valuePhoneMobile !== null) {
  //     return !reg.test(valuePhoneMobile)
  //   }
  // }

  ShowDialog() {
    const dialog = document.querySelector('#dialog')
    dialog?.classList.toggle('hidden')
    dialog?.classList.toggle('ease-out')
    dialog?.classList.toggle('duration-300')
  }

  HideDialog() {
    const dialog = document.querySelector('#dialog')
    dialog?.classList.toggle('hidden')
    dialog?.classList.toggle('ease-in')
    dialog?.classList.toggle('duration-200')
  }
}
