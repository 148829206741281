<div class="min-w-104 max-w-104 md:min-w-108 md:max-w-108 xl:min-w-140 xl:max-w-140">
  <div class="flex items-center gap-2 mb-2">
    <span class="title-main">ยืนยันตัวตนด้วยรหัส OTP</span>
  </div>

  <p class="text-gray-400 text-lg mb-4">ระบบได้ดำเนินการส่งรหัส OTP ให้ท่านผ่านทาง<span class="text-black text-lg">
      {{emailVal}} </span> แล้ว กรุณากรอกรหัส OTP ดังกล่าวเพื่อตั้งรหัสผ่านใหม่</p>

  <div class="flex justify-center">
    <ng-container *ngFor="let pin of convertNumberToArray(pinlength); index as i">
      <input [autofocus]="i == 0" [id]="'codefield_' + i" class="
          h-16
          w-12
          border
          mx-2
          rounded-lg
          flex
          items-center
          text-center
          font-thin
          text-3xl
        " value="" maxlength="1" max="9" min="0" type="decimal" (keyup)="stepForward(i)"
        (keydown.backspace)="stepBack(i)" (focus)="resetValue(i)" />
    </ng-container>
  </div>

  <div class="flex justify-end my-4">
    <button [disabled]="codeOtp === ''" [class]="
        codeOtp !== '' ? 'btn_blue_small ' : 'btn_cancel_small_disabled cursor-not-allowed'
      " (click)="btnSubmitClick()">
      ยืนยัน
    </button>
  </div>
</div>
