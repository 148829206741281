<div class="p-4" style="width: 695px; height: 631px">
  <div class="flex justify-end gap-2">
    <div class="w-10 h-10 bg-gray-100 rounded-full" (click)="btnCancel()">
      <p class="text-center mt-2">X</p>
    </div>
  </div>

  <div class="flex items-center">
    <div class="">
      <div class="h-10 bg-slate-200"></div>
    </div>
    <p class="text-xl sm:text-2xl text-gray-700">การลบบริษัท</p>
  </div>

  <div>
    <p class="text-base">
      ฟรังก์ฟีด บอยคอตสโลว์ ไทม์ ตังค์เย้วเอ๋อมหาอุปราชาสี่แยก มาร์เก็ตติ้งชนะเลิศ
      หมิงแยมโรลแครอทเซี้ยว แบล็ควานิลลามาร์จินโมเดิร์น
    </p>
  </div>

  <div class="flex flex-wrap gap-4 justify-center my-4">
    <div class="col-span-1">
      <div style="height: 200px; width: 384px;" class="border border-gray-200 rounded-2xl p-4">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row items-start gap-4">
            <ng-container *ngIf="companydata.companyLogoThumbUrl; else notImg">
              <img
                class="max-w-12 min-w-12 min-h-12 max-h-12 rounded-md bg-contain object-contain bg-center bg-no-repeat border"
                [src]="
                  'https://' +
                  BucketName +
                  '.s3.ap-southeast-1.amazonaws.com/public/img/' +
                  companydata.companyLogoThumbUrl
                " />
            </ng-container>
            <ng-template #notImg>
              <img class="h-12 w-12 rounded-md bg-contain object-contain bg-center bg-no-repeat border"
                src="https://gapp-media.s3.ap-southeast-1.amazonaws.com/public/icons/web/noLogoComp.png" />
            </ng-template>
            <p class="text-xl font-semibold">{{companydata.companyName}}</p>
          </div>
          <!-- NOTE MORE DATAIL -->
        </div>
        <div class="grid grid-cols-2 gap-1 mx-auto ml-4 mt-4">
          <div class="col-span-1">
            <div class="flex flex-row gap-1">
              <div class="flex justify-center items-center w-6 h-6 bg-blue-300 rounded">
                <fa-icon class="text-sm text-blue-600" [icon]="faUserTie"></fa-icon>
              </div>
              <div class="flex flex-col gap-0.5">
                <p class="text-sm text-blue-500">จำนวนออเดอร์</p>
                <p class="-mt-1.5">
                  {{ (companydata.adminCount | number) || 0 }}
                  <span class="text-sm">คน</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-span-1">
            <div class="flex flex-row gap-1">
              <div class="flex justify-center items-center w-6 h-6 bg-blue-300 rounded">
                <fa-icon class="text-sm text-blue-600" [icon]="faUsers"></fa-icon>
              </div>
              <div class="flex flex-col gap-0.5">
                <p class="text-sm text-blue-500">จำนวนพนักงาน</p>
                <p class="-mt-1.5">
                  {{ (companydata.staffCount | number) || 0 }}
                  <span class="text-sm">คน</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-span-1">
            <div class="flex flex-row gap-1">
              <div class="flex justify-center items-center w-6 h-6 bg-gray-200 rounded">
              </div>
              <div class="flex flex-col gap-0.5">
                <p class="text-sm text-blue-500">ยอดขายรวม (ปีปัจจุบัน)</p>
                <p class="-mt-1.5">0 <span class="text-sm">บาท</span></p>
              </div>
            </div>
          </div>

          <div class="col-span-1">
            <div class="flex flex-row gap-1">
              <div class="flex justify-center items-center w-6 h-6 bg-gray-200 rounded">
              </div>
              <div class="flex flex-col gap-0.5">
                <p class="text-sm text-blue-500">จำนวนออเดอร์</p>
                <p class="-mt-1.5">0 <span class="text-sm">รายการ</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="companyForm">
    <div class="mt-2">
      <div class="text-deep-blue-sea mb-0 text-left text-xl">ยืนยันการลบข้อมูลด้วย</div>
      <div class="flex flex-col gap-2">
        <div class="">
          <label class="text-txt-title text-lg">ชื่อบริษัท</label>
          <input autocomplete="off" type="text" placeholder="ชื่อบริษัท" id="companyName" formControlName="companyName"
            class="border border-input-border rounded-md h-10 text-xl text-gray-400 font-normal placeholder-text-placeholder inputBorderFocus w-52" />
        </div>

        <div class="">
          <label class="text-txt-title text-lg">PIN ID</label>
          <input (keyup)="companyNameValid()" autocomplete="off" mask="000000" maxlength="6" type="text"
            placeholder="PIN ID" formControlName="pinNumber"
            class="border border-input-border rounded-md h-10 text-xl text-gray-400 font-normal placeholder-text-placeholder inputBorderFocus w-52" />
        </div>
      </div>

      <div class="flex justify-center gap-4 mt-7">
        <button
          class="bg-blue-new-500 text-lg text-white px-5 py-1.5 rounded-md transition duration-500 ease-in hover:bg-opacity-90"
          (click)="btnCancel()">
          ยกเลิก
        </button>

        <button class="text-lg py-1 px-6 rounded ml-2 btnBorderFocus cursor-pointer" (click)="submit()" type="submit"
          [disabled]="companyForm.invalid || !companyForm.dirty" [ngClass]="
        companyForm.valid && companyForm.dirty ? 'btn_red_smail' : 'btnDisables'
        ">
          ลบรายชื่อ
        </button>
      </div>
    </div>
  </form>
</div>
