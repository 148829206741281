import { Component, OnInit } from '@angular/core';
import { LogComponent } from 'src/app/shared/utils/log.decorator';

@LogComponent()
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: [
  ]
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
