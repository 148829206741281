import ShortUniqueId from 'short-unique-id'

export function generateRandomId(): string {
  const shortUuid = new ShortUniqueId({length: 8})
  return shortUuid()
}

export function convertPhoneCode(phone: string): string {
  return `+66${phone.slice(1)}`
}
