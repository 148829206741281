import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {faBell, faBuilding, faUsers, faUserTie} from '@fortawesome/free-solid-svg-icons'
import {GappBusiness} from '@geeesy/type-interfaces'
import {IBusinessListCompany} from '@geeesy/type-interfaces/lib/type-business'
import {from, Observable} from 'rxjs'
import {concatMap, finalize, map, pluck, skip, tap, toArray} from 'rxjs/operators'
import {CompanyService} from 'src/app/core/services/company.service'
import {environment} from 'src/environments/environment'

import {AuthService} from '../../core/services/auth.service'
import {BusinessService} from '../../core/services/business.service'
import {TokenService} from '../../core/services/token.service'
import {UserAdminService} from '../../core/services/user-admin.service'

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styles: [],
})
export class HeaderComponent implements OnInit {
  showMenu: boolean = false
  showMenuMobile: boolean = true
  infoBusiness: any
  users: any
  tenantId: any
  identityId: any
  faBell = faBell
  faBuilding = faBuilding
  faUserTie = faUserTie
  faUsers = faUsers
  loadingpage: boolean = true
  isShowGappList: boolean = false
  companies$: any
  staffCounts: number[] = []
  adminCounts: number[] = []
  admin: any
  staff: any
  chkRoute: any

  comp$: Observable<any> = this._authUser.dataComp$

  get BucketName() {
    return environment.s3PublicBucketName
  }

  constructor(
    private businessService: BusinessService,
    private TokenService: TokenService,
    private UserAdminService: UserAdminService,
    private _CompSer: CompanyService,
    private router: Router,
    private _authUser: AuthService
  ) {
    this.User$.subscribe((data) => {
      ;(this.users = data),
        (this.tenantId = this.users.tenantId),
        (this.identityId = this.users.identityId)
    })
  }

  User$: Observable<any> = this.TokenService.tokenOwner()
  BusinessInfo$: Observable<GappBusiness.Business> = this.businessService
    .getBusinessInfo()
    .pipe(skip(1))
  Owner$: Observable<GappBusiness.Owner> | undefined

  ngOnInit(): void {
    // this._authUser.compStateSteam()
    this.getstaffAll()
    this.BusinessInfo$.subscribe((res) => {
      this.infoBusiness = res
    })

    this.companies$ = this._CompSer.getAllCompanies()
    this.Owner$ = this.UserAdminService.getOwnerDetail(
      this.users.tenantId,
      this.users.identityId
    ).pipe(
      tap((res) => {
        this._authUser.setOwnnerData(res)
      })
    )
    this.getAdminCount()
    // this.router.events.subscribe((route) => {
    //   if (route instanceof NavigationStart) {
    //     console.log('route =>>>>>>>>>>', route.url)
    //     if (route.url === '/app/business') {
    //       this.chkRoute = false
    //     } else if (route.url === '/app/company/create') {
    //       this.chkRoute = false
    //     } else if (route.url.startsWith('/app/profile')) {
    //       this.chkRoute = false
    //     } else if (route.url.startsWith('/app/package')) {
    //       this.chkRoute = false
    //     } else {
    //       this.chkRoute = true
    //     }
    //   }
    // })
  }

  isEmpty(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  getstaffAll() {
    this._CompSer
      .getAllCompanies()
      .pipe(
        finalize(() => (this.loadingpage = false)),
        concatMap((data: IBusinessListCompany[]) =>
          from(data).pipe(
            concatMap((comp) =>
              this._CompSer.getListStaffs(comp.companyCode).pipe(
                skip(1),
                pluck('data'),
                map((data) => {
                  let adminCount: number = 0
                  let staffCount: number = 0
                  data.map((val: { userRole: string }) => {
                    if (val.userRole === 'admin') {
                      adminCount += 1
                    } else if (val.userRole === 'staff') {
                      staffCount += 1
                    }
                  })
                  return { ...comp, adminCount, staffCount }
                })
              )
            ),
            toArray()
          )
        )
        // tap((newMapData) => console.log({ newMapData }))
      )
      .subscribe((res) => {
        res.forEach((res) => {
          this.staffCounts.push(res.staffCount)
          this.adminCounts.push(res.adminCount)
          if (this.staffCounts !== null || this.adminCounts !== null) {
            const reducer = (previousValue: any, currentValue: any) => previousValue + currentValue
            this.staff = this.staffCounts.reduce(reducer)
            this.admin = this.adminCounts.reduce(reducer)
          }
        })
      })
  }

  getAdminCount() {
    this._CompSer.getAdminCount().subscribe((res) => console.log('AdminCount =>', res))
  }

  showmenu() {
    this.showMenu = true
  }

  closemenu() {
    this.showMenu = false
  }

  onResize() {
    let screen = window.innerWidth
  }

  openmenumobile() {
    let menumo = document.getElementById('menumobile') as HTMLDivElement

    let status = menumo.style.display == 'none'

    if (menumo.style.display == 'none') {
      menumo.style.display = 'block'
    } else {
      menumo.style.display = 'none'
    }
  }

  getBusinessInfo() {
    this.businessService
      .getBusinessInfo()
      .pipe(map((res: any) => res.data))
      .subscribe((infoBusiness) => {
        this.infoBusiness = infoBusiness
      })
  }

  onClickGappList() {
    this.isShowGappList = !this.isShowGappList
    console.log('-- onClickGappList --', this.isShowGappList)
  }

  // getAdminCount() {
  //   this._CompSer.getAdminCount().subscribe((res) => console.log('myRes', res))
  // }
}
