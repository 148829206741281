import { Injectable, OnInit } from '@angular/core'
import { GappBusiness } from '@geeesy/type-interfaces'
import { API, Auth } from 'aws-amplify'
import { BehaviorSubject, Observable, from } from 'rxjs'
import { tap } from 'rxjs/operators'

import { logServiceL1 } from '../../shared/utils/log.function'
import { convertPhoneCode, generateRandomId } from '../../shared/utils/utilities.function'
import { ILoginParams, ISignUpParams } from '../models/auth.interface'

const API_NAME: string = 'gbzApiIam'
const API_PATH: string = '/public'
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  private dataComp = new BehaviorSubject(null)
  dataComp$ = this.dataComp.asObservable()

  private dataUser = new BehaviorSubject(null)
  dataUser$ = this.dataUser.asObservable()

  private ownnerData = new BehaviorSubject(null)
  ownnerData$ = this.ownnerData.asObservable()

  // * set email
  _setEmail$ = new BehaviorSubject<any>('')

  constructor() {}

  ngOnInit() {
    logServiceL1('AuthService')
  }

  /**
   * REGISTER
   */
  signUp({
    name,
    email,
    password,
    alias,
    phone_number,
    firstName,
    lastName,
  }: ISignUpParams): Observable<any> {
    return from(
      Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
          phone_number: convertPhoneCode(phone_number),
          nickname: alias,
          'custom:role': 'owner',
          'custom:userrole': 'new-owner', // ! update to 'owner' after complete create business
          'custom:identityId': generateRandomId(),
          given_name: firstName,
          family_name: lastName,
        },
      })
    )
  }

  /**
   * LOGIN
   */
  logIn({ email, password, code }: ILoginParams): Observable<any> {
    return from(
      Auth.signIn({
        username: email,
        password,
        validationData: {
          app: 'biz',
          alias: code,
        },
      })
    )
  }

  logout() {
    return from(Auth.signOut())
  }

  // * set => masterId detail
  setOwnnerData(item: any) {
    this.ownnerData.next(item)
  }

  //* set => CompState
  setCompState(item: any) {
    this.dataComp.next(item)
    localStorage.setItem('compState', JSON.stringify(item))
  }

  // * Override (Forgot Password -> Mail)
  forgotPasswordSendMail(email: string) {
    return from(Auth.forgotPassword(email))
  }

  // * Override (Mail -> New Password)
  forgotPasswordSubmit(phone: string, code: string, newPassword: string) {
    return from(Auth.forgotPasswordSubmit(phone, code, newPassword))
  }

  // Policy register
  confirmPolicy(email: string, isNewsletterSubscriber: boolean): Observable<GappBusiness.Newsletter> {
    return from(
      API.post(API_NAME, `${API_PATH}/newsletter`, {
        body: {
          id: '',
          email: email,
          isNewsletterSubscriber: isNewsletterSubscriber,
        },
      })
    ).pipe(tap((res) => console.warn('Confirm Policy :', res)))
  }
}
